import { Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AppBarTab({name,id,width="120px"}) {
    const theme=useTheme()
    const [hover,setHover]=useState(false)
    const location=useLocation()
    const nowUserId=location.state.id
const nowUserType=location.state.userType
    const navigate=useNavigate()
    const currentTab=location.pathname
    const onMouseEnter=()=>{
        setHover(true)
    }   
     const onMouseLeave=()=>{
        setHover(false)
    }
    const onClick=()=>{
        navigate(`/fileupload/${id}`, {
            state: { id: nowUserId, userType: nowUserType, row: null },})

    }
    return (
        <button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        style={{
            cursor:"pointer",
            width:width,backgroundColor:"transparent",border:"none"}}
        >
            <Typography variant='body2' sx={{color:theme.palette.text.primary}}>{name}</Typography>
            <div style={{
                height:"5px",
                borderRadius:"10px",
                width:hover||currentTab.split("/")[1]===id?"100%":"0%",
                backgroundColor:hover||currentTab.split("/")[1]===id?
                nowUserType==="admin"?
                theme.palette.secondary.main:
                theme.palette.primary.main:"transparent",
              
                transition: "width 0.6s ease-in-out", // Added transition for smoother effect
                transform: hover ||currentTab.split("/")[1]===id? "scaleX(1)" : "scaleX(0)", // Transform property for expansion effect
                transformOrigin: "left" // Ensuring expansion from left to right
            }}/>
            </button>
    );
}

export default AppBarTab;