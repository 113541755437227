import { Box, Button, Card, CardContent, CardHeader, Chip, Divider, TextField, TextareaAutosize, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Form, useLocation } from 'react-router-dom';
import VirtualizedList from './VirtualizedList';
import { basicTableHeight } from '../const/const';

function EmailSendForm({
    onClickSend,
    formRef,
    nowSender,selectedRecipients,title,onChangeTitle,content,onChangeContent}) {
    const theme=useTheme()
    return (
        <Card variant='outlined'
        sx={{
            width:"100%",
            height:basicTableHeight,
            boxSizing: "border-box",
            overflowY:"scroll",
            // position:"fixed"
        }}
        
        >
            <CardHeader
            sx={{
                position:"sticky"
            }}
            title={'Email Send Form 📨'}
            action={
            <Button 
            type='submit'
            onClick={onClickSend}
            variant='contained' 
            color='success'
            sx={{width:"200px"}}
            >SEND</Button>}
            />
          
            <CardContent
         sx={{
            height:"100%",
           
         }}
            >  
          <form
          ref={formRef}
          style={{
            display:"flex",
            width:"100%",
            height:"100%",
                flexDirection:"column",
                gap:"8px"
          }}
          onSubmit={onClickSend}
          > 
          
                <Typography variant='body1' sx={{
                    width:"120px"
                }}>Sender</Typography>
                <TextField 
                type='email'
                name='user_email'
                // disabled={true}
                value={nowSender}
                size='small' sx={{width:"100%"}}/>
          
                <Divider/>
          <Typography variant='body1' sx={{
                width:"120px"
            }}>Recipients</Typography>
      
      {/* 여기 recipients 텍스트 필드로 나열한다음 안보이게 하고 chip으로 대체 */}
      {/* 셀렉트 된것만  */}
    {
    selectedRecipients.map(r=><TextField 
               type="email" name="to_email"
                // disabled={true}
                value={r}
                size='small' sx={{width:"100%",display:'none'}}
            
                />)
    
    }
          <div
        
          >{ selectedRecipients.map(r=><Chip
                color={'info'}
                sx={{
                    width:"fit-content",
                    marginRight:"8px",marginBottom:"8px"
                }}
                
                label={<div
                style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"start"
                }}
                >{r}</div>} />)    }</div> 
          
            <Divider/>
           
                <Typography variant='body1' sx={{
                    width:"120px"
                }}>Title</Typography>
                <TextField 
                 type="text"
                 name="ask_title"
                value={title}
                onChange={onChangeTitle}
                size='small' sx={{width:"100%"}}/>
             
                <Divider/>
              
                <Typography variant='body1' sx={{
                    width:"120px"
                }}>Content</Typography>
                <TextareaAutosize
                name="ask_message"
               
                style={{
                    width:"100%",
                    height:"300px",
                    backgroundColor:theme.palette.background.paper
                    ,color:theme.palette.mode==='light'?"black":"white"
                    ,border:`1px solid ${theme.palette.grey[500]}`,
                    borderRadius:6
                }} 
                value={content}
                onChange={onChangeContent}
                 sx={{width:"100%"}}/>
               </form>
           
            </CardContent>

             
        </Card>
    );
}

export default EmailSendForm;