import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Mobile, PC } from './ReactiveLayout';
import MainCustomAppbar from '../components/MainHomePg/AppBar/MainCustomAppbar';
import './MainPage.css'
import MainCustomMbAppbar from '../components/MainHomePg/AppBar/MainCustomMbAppbar';
import { RiKakaoTalkFill } from 'react-icons/ri';
import BDQR from '../images/qr-code/bd-file-qr.png'
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
function MainLayout(props) {
    const navigate = useNavigate()
    const {t} = useTranslation();
    return (
        <div>
            <PC>
                <MainCustomAppbar />
                <Outlet />
                <div className="footer">
                    <div className="link"
                        onClick={() => {
                            navigate('/fileupload/login')
                        }}
                    >
                        <a>BD FILE MANAGEMENT</a>
                    </div>
                    <div className="link">
                        <a>PROPERTY FILE MANAGEMENT</a>
                    </div>
                    <div className="sns"
                        style={{
                            display: 'flex',
                            alignItems: "center"
                        }}
                    >
                        <RiKakaoTalkFill size={26} />
                        <span>{t('etc.kakaoId')}</span>
                        {/* <a      href='http://qr.kakao.com/talk/q4Fqv85q0K3zY1t6fHU4ZN8CCec-'
     className='info-content'
     target="_blank"               // 새 창에서 링크를 열도록 설정
     rel="noopener noreferrer"     // 보안 및 성능 문제를 방지
     >ocbrady</a> */}
 {/* <img 
style={{
    marginBottom:"50px"
}}

        width={"100px"}
        src={BDQR}/> */}
    <div style={{ height: "auto", margin: "0 auto", maxWidth: 80, width: "100%" }}>
  <QRCode
    style={{ height: "auto", maxWidth: "100px", width: "100%" }}
    value={'http://qr.kakao.com/talk/q4Fqv85q0K3zY1t6fHU4ZN8CCec-'}
  />
</div>
                    </div>
                </div>
            </PC>
            <Mobile>
                <MainCustomMbAppbar />
                <Outlet />
                <div className="footer-mb">
                    <div className="link"
                        onClick={() => {
                            navigate('/fileupload/login')
                        }}
                    >
                        <a>BD FILE MANAGEMENT</a>
                    </div>
                    <div className="link">
                        <a>PROPERTY FILE MANAGEMENT</a>
                    </div>
                    <div className="sns"
                    style={{
                        display:"flex",
                        alignItems:"center",
                        marginTop:"10px"
                    }}
                    >
                        <RiKakaoTalkFill size={26} />
                        <span>{t("etc.kakaoId")}</span>
                        <a      href='http://qr.kakao.com/talk/q4Fqv85q0K3zY1t6fHU4ZN8CCec-'
     className='info-content'
     target="_blank"               // 새 창에서 링크를 열도록 설정
     rel="noopener noreferrer"     // 보안 및 성능 문제를 방지
     >ocbrady</a>
                    </div>
                </div>
            </Mobile>
        </div>
    );
}

export default MainLayout;