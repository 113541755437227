import React, { useEffect, useRef, useState } from 'react';
import EmailSendForm from '../components/EmailSendForm';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import UserCheckList from '../components/UserCheckList';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../service/firebase';
import { SyncLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';
import { mainLoadingAtom } from '../atom/atom';
import { basicTableHeight } from '../const/const';
function EmailSendContent(props) {
    const theme=useTheme()
    const location=useLocation()
    const nowSender=location.state.id
    const [nowRecipients,setNowRecipients]=useState([])
    const [title,setTitle]=useState("")
    const [content,setContent]=useState("")
    const [selectedRecipients,setSelectedRecipients]=useState([])
    // const [loading,setLoading]=useState(false)
    const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
    const form = useRef();
    const height=window.innerHeight
    const getRecipients=async()=> {
        const userCollection = collection(db, "userInfos")
    
          const data = await getDocs(userCollection)
          const list=data.docs.map(d=>d.data())
          setNowRecipients(list)
        }
    
    const onChangeTitle=(e)=>{
        setTitle(e.target.value)
    }
    const onChangeContent=(e)=>{
        setContent(e.target.value)
    }
    const onClickSend=(e)=>{
      
        e.preventDefault();
 setMainLoading(true)
        emailjs.sendForm('service_u7qe21d', 'template_oo79e2c', form.current, 'eNiL8qzwPbKae-vcY')
          .then((result) => {
              console.log(result.text);
              if (result.text==='OK') {
                 setMainLoading(false)
              }
          }, (error) => {
              console.log(error.text);
              setMainLoading(false)
            window.confirm(error.text)
          }).finally(
           
          );
      };
      const handleSelectRecipients=(newIds)=>{

        setSelectedRecipients(newIds)
        
      }
useEffect(()=>{
//userList가지고 오기
getRecipients()

},[])
    return (
        <div style={{
            display:"flex",
            height:`calc( ${basicTableHeight} + 32px )`,
         width:"100%",
        //  height:"100%"
        }}>
          
            
            <div
            style={{
                width:"100%",height:"100%",
                display:"flex",gap:"32px"
            }}
            > <div style={{
                width:"50%",height:"100%",paddingLeft:"32px",display:"flex",alignItems:"center"
            }}>

                <UserCheckList nowRecipients={nowRecipients} handleSelectRecipients={handleSelectRecipients}/>
            </div>
            <div style={{
                width:"50%",
                height:`100%`,paddingRight:"32px",display:"flex",alignItems:"center"
            }}>
            <EmailSendForm 
            onClickSend={onClickSend}
            formRef={form}
            nowSender={nowSender}
            selectedRecipients={selectedRecipients}
            title={title}
            onChangeTitle={onChangeTitle}
            content={content}
            onChangeContent={onChangeContent}
            /></div></div>
           
            
        </div>
    );
}

export default EmailSendContent;