// import { Typography } from "@mui/material";
import CustomAppBar from "../components/CustomAppBar";
import "../App.css"
// import CustomPaginationActionsTable from "./components/CustomPaginationActionsTable";
import { Outlet, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {db} from "../service/firebase"
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { CssBaseline, Dialog, DialogContent, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useRecoilState } from "recoil";
import { loadingPercentAtom, mainLoadingAtom, themeAtom } from "../atom/atom";
import { getDesignTokens } from "../const/const";
import { MoonLoader, RingLoader, RotateLoader, SyncLoader } from "react-spinners";
export default function CustomLayout() {
  const location=useLocation()
 
  const [mode, setMode] = useRecoilState(themeAtom);
  const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
  const [loadingPercent,setLoadingPercent]=useRecoilState(loadingPercentAtom)
  const colorMode =useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])
  return (
    <div className="App">
      
      <Dialog
    PaperProps={{
      style:{
     backgroundColor:   theme.palette.background.paper
      }
    }}
  
      open={mainLoading}
      >
        <DialogContent
        sx={{
          padding:"32px",
          display:"flex",
          flexDirection:"column",
          gap:"8px",
          justifyContent:"center",
          alignItems:"center"
          
        }}
        ><MoonLoader  color={location.state.userType==="admin"?theme.palette.secondary.main:theme.palette.primary.main}/>
         {loadingPercent!=null&&<div
         style={{
          width:"600px",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          // fontSize:"1px"
         }}
         >{loadingPercent}</div>}
        </DialogContent>
        
       
        </Dialog>
           <ThemeProvider theme={theme}>
      <CssBaseline />
      <CustomAppBar toggleColorMode={colorMode.toggleColorMode}/>
       <Outlet />
       </ThemeProvider>
      
    </div>
  );
}

