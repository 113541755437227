import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { transactionTypeOptionsState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import ClosedDetailTableRow from '../components/ClosedDetailTableRow';
import { SyncLoader } from 'react-spinners';
import { allowDotFormatNumber, basicTableHeight } from '../const/const';
import AdminTableLoader from '../components/AdminTableLoader';


export default function AdminBrokerSplitTable({
  enoVal,
  setEnoVal,
  setTableDatas,
  isAllSave,
  classType,
  handleAllSave,
  tableDatas,getTableDatas}) {
  const [realTableDatas,setRealTableDatas]=useState([])
  const [transTypeOptions, setTransTypeOptions] = useRecoilState(transactionTypeOptionsState);

  const theme=useTheme()

 

  const handleSave = async (index, newClosedPrice0, newClosedPrice1, newBrokerSplit) => {
    const optionsCollection = collection(db, "options");
    const docRef = doc(optionsCollection, "closedDetail");
  
    try {
      const docSnapshot = await getDoc(docRef);
  
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        // 기존 데이터를 복사하여 새로운 데이터로 업데이트
        const updatedSelectList = [...existingData.selectList];
          updatedSelectList[index] = {
          closedPrice: `${newClosedPrice0}~${newClosedPrice1}`,
          brokerSplit: newBrokerSplit,
        };
        const updateObjectList = {
          [`selectList.${classType}`]: updatedSelectList,
      };
        // 전체 데이터를 업데이트
        await updateDoc(docRef, updateObjectList);
  
        getTableDatas();
      } else {
      }
    } catch (error) {
    }
  };


  const tableRowNum = Array.from({ length: 30 }, (v, i) => i);
useEffect(()=>{
  if (tableDatas===null)return

setRealTableDatas(tableDatas[classType])
},[tableDatas,classType])
useEffect(()=>{
if (isAllSave) {
  handleAllSave()
}
return;

},[isAllSave])
const handleENO = (e) => {
  const inputValue = e.target.value;

  // 숫자와 마이너스 부호만 허용하는 정규식
  const validInput = inputValue.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용

  // 원하는 형식으로 포맷팅
  const formattedValue = allowDotFormatNumber(validInput);
  setEnoVal(formattedValue)

}
  return (
   realTableDatas===null||realTableDatas===undefined?
   <TableContainer variant='outlined'
    
   component={Paper} sx={{
     height: basicTableHeight,
     width: "100%" }}>

Loading...
     </TableContainer>:
   <TableContainer variant='outlined'
    
    component={Paper} sx={{
      height: basicTableHeight,
      width: "100%" }}>
     
        <Table 
        stickyHeader
        size='small'
        
        sx={{ minWidth: 500,}} aria-label="custom pagination table">
          <TableHead>
            <TableCell style={{ width: 160 }} scope="row">
              Number
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row">
              Closed Price
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row">
              Broker Split
            </TableCell>
            {/* <TableCell style={{ width: 160 }} scope="row">
              Save
            </TableCell> */}
          </TableHead>
          <TableBody>
            {tableRowNum.map((index) => {
              const row = Object.values(realTableDatas)[index];
              return (
               <ClosedDetailTableRow 
               classType={classType}
               tableDatas={tableDatas}
               setTableDatas={setTableDatas}
               row={row} index={index} handleSave={handleSave}/>
              );
            })}
            {/* E&O Insurance TableRow */}
            <TableRow key={"E&O"} >
      <TableCell component="th" scope="row" >
 E&O Insurance
        
      </TableCell>
      <TableCell component="th" scope="row"  >
        <div
        
        style={{
          display:"flex"
        }}
        ><TextField

          size='small'
          sx={{

            width: "160px",
          }}
          onChange={handleENO}
          value={enoVal === "" ? enoVal : "$" + enoVal}
          inputProps={{
      style:{
        height:"10px"
      },
            inputMode: 'numeric',
            pattern: '[0-9]*',

          }}
        />
{/*         
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2px",
          }}
        >
          ~
        </div>
        <TextField
          size='small'
          sx={{
            width: "160px",
          }}
          onChange={(e)=>{}}
          inputProps={{
            style:{
              height:"10px"
            },
            inputMode: 'numeric',
            pattern: '[0-9]*',

          }}
          value={""}
        />
         */}
        </div>
     
      </TableCell>
      <TableCell component="th" scope="row">
        {/* <TextField
          size='small'
          sx={{
            width: "160px",
          }}
          inputProps={{
            style:{
              height:"10px"
            },
            inputMode: 'numeric',
            pattern: '[0-9]*',

          }}
          onChange={(e)=>{}}
          value={""}
        /> */}
      </TableCell>
    </TableRow>
          </TableBody>
        </Table>
      
    </TableContainer>
  );
}