import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

function FilterSearchBar({disableClearable=false,multiple=true,width="500px",label,options=[],
defaultValue=[],
limitTags=2,handleSearchList}) {
    return (
        <Autocomplete
       itemProp='ss'
        size='small'
        multiple={multiple}
        limitTags={limitTags}
        id="multiple-limit-tags"
        options={options}
        getOptionLabel={(option) => option}
        // value={defaultValue}
       disableClearable={disableClearable}
        defaultValue={defaultValue}
        onChange={handleSearchList}
        renderInput={(params) => (
          <TextField 
          size='small'
          {...params} label={label} 
       
          />
        )}
        sx={{ width: width }}
        
      />
    );
}

export default FilterSearchBar;