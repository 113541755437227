import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { transactionTypeOptionsState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import ClosedDetailTableRow from '../components/ClosedDetailTableRow';
import { SyncLoader } from 'react-spinners';
import { basicTableHeight } from '../const/const';
import AdminTableLoader from '../components/AdminTableLoader';


export default function AdminAmountTable({
  ownerPercent,
  agentPercent,
  leaderPercent,
  managerPercent,
  onChangePercent
 }) {
  const theme = useTheme()

  return (
   
      <TableContainer variant='outlined'

        component={Paper} sx={{
          height: basicTableHeight,
          width: "100%"
        }}>

        <Table
          stickyHeader
          size='small'

          sx={{ minWidth: 500, }} aria-label="custom pagination table">
          <TableHead>
            <TableCell style={{ width: 160 }} scope="row">
              User type
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row">
              Percent
            </TableCell>

          </TableHead>
          <TableBody>
            {/* O */}
            <TableRow key={"O"} >
              <TableCell component="th" scope="row" >
                O

              </TableCell>
              <TableCell component="th" scope="row"  >
                <div

                  style={{
                    display: "flex",
                    alignItems:"center",
                    gap:"8px"
                  }}
                ><TextField
                value={ownerPercent}
                onChange={onChangePercent}
                    id='O'
                    size='small'
                    sx={{

                      width: "160px",
                    }} />
                    <span>%</span>
                    </div></TableCell>
            </TableRow>
            {/* M */}
            <TableRow key={"M"} >
              <TableCell component="th" scope="row" >
                M

              </TableCell>
              <TableCell component="th" scope="row"  >
                <div

                  style={{
                    display: "flex",
                    alignItems:"center",
                    gap:"8px"
                  }}
                ><TextField
                value={managerPercent}
                onChange={onChangePercent}
                    id='M'
                    size='small'
                    sx={{

                      width: "160px",
                    }} />
                    <span>%</span>
                    </div></TableCell>
                    
            </TableRow>
            {/* L */}
            <TableRow key={"L"} >
              <TableCell component="th" scope="row" >
                L

              </TableCell>
              <TableCell component="th" scope="row"  >
                <div

                  style={{
                    display: "flex",
                    alignItems:"center",
                    gap:"8px"
                  }}
                ><TextField
                value={leaderPercent}
                onChange={onChangePercent}
                    id='L'
                    size='small'
                    sx={{

                      width: "160px",
                    }} />
                    <span>%</span>
                    </div></TableCell>
                    
            </TableRow>
             {/* A */}
             <TableRow key={"A"} >
              <TableCell component="th" scope="row" >
                A

              </TableCell>
              <TableCell component="th" scope="row"  >
                <div

                  style={{
                    display: "flex",
                    alignItems:"center",
                    gap:"8px"
                  }}
                ><TextField
                value={agentPercent}
                onChange={onChangePercent}
                    id='A'
                    size='small'
                    sx={{

                      width: "160px",
                    }} />
                    <span>%</span>
                    </div></TableCell>
                    
            </TableRow>
          </TableBody>
        </Table>

      </TableContainer>
  );
}