import React, { useEffect, useState } from 'react';
import TxtfieldRow from '../components/TxtfieldRow';
import "../App.css"
import { Card, CardContent, TextField } from '@mui/material';
import { useRecoilState } from 'recoil';
import { filesState, mainLoadingAtom, transactionClientNameState, transactionNmlsNumState, transactionPropertyAddressState, transactionTypeState } from '../atom/atom';
import SelectRow from '../components/SelectRow';
import { db } from '../service/firebase';
import { collection, doc, getDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import Clock from '../components/Clock';
import uuid from 'react-uuid';
import axios from 'axios';
function NewTransactionDetailContent({
  onChangeEditTable=null,
  onChangeTransType=null,
 contentType='write',
  disabled,
  transCity,
transAddress,
setTransCity,
setTransAddress,
  todayFullDate,transType,transTypeOptions,clientName,propertyAddress,nmlsNum
,setTransType,setClientName,setPropertyAddress,setNmlsNum,setTodayFullDate
}) {
  
  const dataCollection = collection(db, "pjDatas")
  const location=useLocation()
    // const [transactionType,setTransactionType]=useRecoilState(transactionTypeState)
    // const [transactionClientName,setTransactionClientName]=useRecoilState(transactionClientNameState)
    // const [transactionPropertyAddress,setTransactionPropertyAddress]=useRecoilState(transactionPropertyAddressState)
    // const [transactionNmslNum,setTransactionNmslNum]=useRecoilState(transactionNmlsNumState)
    const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
    const [filesAtom, setFilesAtom] = useRecoilState(filesState);
    const tableRowsCollection = collection(db, 'documentsForTransaction');
  const [zipCode,setZipCode]=useState("")
  const [address1,setAddress1]=useState("")
    const getFilesInit=async()=> {
      //transactionType당 업로드 파일 5개씩 할 수 있는데, 
      //트랜잭션 타입 당 업로드 파일 5개 로우 매핑시키는 것
      const originRowKey=location.state.rowKey;
      const newRowKey=uuid();
      const userId = location.state.id;
      const objKey = originRowKey===undefined?newRowKey:originRowKey;
      const docRef = doc(dataCollection, userId);
    
      try {
        const editList = await getDoc(docRef);
    
        if (editList.exists()) {
          //edit하는경우와 write하는 경우를 분기
          //아래가 edit / write로 분기한거
          //로우 키가 안들어왔으니까, write 페이지
          if (originRowKey===undefined) {
            //transactionOption 얻는 과정
            const ttCollection = collection(db, "options");
            const ttRef=doc(ttCollection,"transactionType")
            const ttOptions=await getDoc(ttRef)
            if (ttOptions.exists()) {
            
              const ttOptionsData=ttOptions.data()
                const selTtOption=ttOptionsData["selectList"][transType]
               const docsCollection = collection(db, "documentsForTransaction");
            const docRef = doc(docsCollection, selTtOption);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              //도큐멘트 정보들 있음
            const docData=docSnapshot.data()["documents"]
            const modifiedDocData=docData.map((item,idx)=>
            {
              const newFilesArray=Array.from({length:5},(_,i)=>(
                {
                  key:i,
                  name:"",
                  source:"",
                  fileInfo:""
                }
              ))
              return {...item,files:newFilesArray}
            }
            )
            setFilesAtom(modifiedDocData);
          } else {
              //도큐멘트 정보들 없음->도큐멘트 정보들 애초에 다 60줄씩 초기화해놓자
             
             
            }
            } else {
            }
           
           
          
          } //네비게이트로 전달받은 키가 있는 경우니까 edit
          else {    
            const data = editList.data();
            const res = data[objKey]["tablePerTransaction"];
            setFilesAtom(res);
            
          }
         
        } else {
          //아예 유저별 데이터가 없는 경우
          return false;
        }
      } catch (error) {
        // Handle any potential errors during the asynchronous operations
        return false;
      }
    }
    async function getFiles(type) {
      if (!transTypeOptions || type === null) return;
  
      const docName = transTypeOptions[type];
      const docRef = doc(tableRowsCollection, docName);
      const docData = await getDoc(docRef);
      // setFilesAtom(
      //   docData.data()['documents'].map((tr) => ({
      //     name: tr['name'],
      //     files: Array.from({ length: 5 }, (_, index) => ({ key: index, name: '' })),
      //   }))
      //);
      if (docData.exists()) {
        setFilesAtom(
          docData.data()['documents'].map((tr) => ({
            name: tr['name'],
            files: Array.from({ length: 5 }, (_, index) => ({ key: index, name: '',source:"",fileInfo:"" })),
          }))
        );
      } else {
        // Handle the case where docData does not exist
      }
    }

    const onChangeType=async(e)=>{
  setMainLoading(true)
      //임시
      // if (onChangeTransType===null) {
        const transType=e.target.value 
       
  await setTransType(transType)  
  onChangeEditTable(transType)
      // } else {
      //   onChangeTransType(e.target.value)
      // }
      setMainLoading(false)
//  await  getFiles(transType)
}
const onChangeClientName=(e)=>{
  setClientName(e.target.value)
}
const onChangePropertyAddress=(e)=>{
  setPropertyAddress(e.target.value)
}
const onChangeNmls=(e)=>{
  setNmlsNum(e.target.value)
}
useEffect(()=>{

  getFilesInit()
},[])
// const addrSearch=(e,zipCode)=>{
//   const options = {
//     method: 'GET',
//     url: 'https://global-address.p.rapidapi.com/V3/WEB/GlobalAddress/doGlobalAddress',
//     params: {
//       ctry: 'US',
//       format: 'json',
//       // a1: "irvine ca 92618",
//       DeliveryLines: 'On',
//       // a2: 'Frankfurt am Main',
//       postal: zipCode,

//     },
//     headers: {
//       'X-RapidAPI-Key': '2c755c8f01msh0f10aa97c9c97aap179135jsna67a4b267bc4',
//       'X-RapidAPI-Host': 'global-address.p.rapidapi.com'
//     }
//   };
// axios(options).then(res=>{

//   // 검색 결과가 없을때
//   if(!res?.data || res.data.Records[0].AddressLine1 == ''){
//     alert("No results were found for your search.");
//     return;
// }
// console.log("axios 결과>>>",res.data.Records[0])
// const addressRes1=res.data.Records[0].AddressLine1
// setAddress1(addressRes1)
// // 검색 완료 후 처리
// // ~~~
// }
   
//   ).catch(function (err) {
//     console.log("axios 실패>>>",err)
//   })

// }
const handleZipCode=(e)=>{
  setZipCode(e.target.value)
}
const onChangeAddress=(e)=>{
  setTransAddress(e.target.value)
}
const onChangeCity=(e)=>{
  setTransCity(e.target.value)
}
    return (
      <Card sx={{width:"100%"}} variant='outlined'>
        <div className='ContentBox'>
            <div>New Transaction Detail</div>
      {/* <TxtfieldRow title={"Transaction Type"} value={transType} onChange={onChangeType}/> */}
 
      <SelectRow 
      titleWidth='20vw'
      disabled={disabled}
      title={"Transaction Type"} options={transTypeOptions}value={transType} onChange={onChangeType} width={"50vw"} containerWidth='50vw'/>
      <TxtfieldRow 
      titleWidth='20vw'
      readOnly={disabled}
      title={"Client Name"} value={clientName} onChange={onChangeClientName} width={"50vw"} containerWidth='50vw'/>
      {/* <TxtfieldRow 
      readOnly={disabled}
      title={"Property Address"} value={propertyAddress} onChange={onChangePropertyAddress} width={"50vw"} containerWidth='50vw'/> */}
      
      {/* <div>주소 테스트:{address1}<input type="number" value={zipCode} onChange={handleZipCode} /><button  onClick={(e)=>addrSearch(e,zipCode)}>검색</button></div> */}
    
      <TxtfieldRow
titleWidth='20vw'

    readOnly={disabled}
    title={"City"}
    placeholder={"City"}
    value={transCity}
    onChange={onChangeCity}
    width={"50vw"} containerWidth='50vw'
    />
    <TxtfieldRow
titleWidth='20vw'

    readOnly={disabled}
    placeholder={"Address"}
    title={"Address"}
    value={transAddress}
    onChange={onChangeAddress}
    width={"50vw"} containerWidth='50vw'
    />
  
      <div
        style={{
          display:"flex",
          alignItems:"center",gap:"8px",
        }}
      >
        <div
          style={{ fontSize: "16px", display: "flex", width: "20vw",justifyContent:"flex-end" }}
        >Date : </div>
    {contentType==='write'? <Clock time={todayFullDate} setTime={setTodayFullDate}/>
      
     :<div style={{ width: "50vw" }}>
            {todayFullDate}
        </div>}
      </div>
      <TxtfieldRow
      titleWidth='20vw'
      readOnly={disabled} title={"NMLS Number"} value={nmlsNum} onChange={onChangeNmls} width={"50vw"} containerWidth='50vw'/>
        </div>
    </Card>
    );
}

export default NewTransactionDetailContent;