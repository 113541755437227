import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import React, { useState } from 'react';
import TxtfieldRow from '../components/TxtfieldRow';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import { validateSignupForm } from '../const/const';
import { useRecoilState } from 'recoil';
import { mainLoadingAtom } from '../atom/atom';

function PwChangeContent(props) {
    const location=useLocation()
    const nowId=location.state.id
    const userType=location.state.userType
    const navigate=useNavigate()
    const [currentPw,setCurrentPw]=useState('')
    const [newPw,setNewPw]=useState('')
    const [confirmPw,setConfirmPw]=useState('')
    const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
    const handleChangeCurrPw=(e)=>{

        setCurrentPw(e.target.value)
    } 
    const handleChangeNewPw=(e)=>{

        setNewPw(e.target.value)
    } 
    const handleChangeConfirmPw=(e)=>{

        setConfirmPw(e.target.value)
    }
    const changePwInDb=async()=>{
        setMainLoading(true)
        const userCollection = collection(db, "userInfos")
const updateObject={[`pw`]:newPw}
await updateDoc(doc(userCollection,nowId), updateObject);
setMainLoading(false)
navigate("/fileupload/table",{
    state:{id:nowId,userType:userType,row:null}
})


    }
    const handleChangePw=()=>{
        // const pwValidation='';
        const errors = validateSignupForm(nowId, newPw);
      
  
        //   window.confirm("회원가입 성공!");
         const isSame=localStorage.getItem("nowUserPwInLocal")===currentPw
        if (isSame) {
          const res=  window.confirm("Would you like to change your password?")
        if (res) {
            if (Object.values(errors)[0]===""&&Object.values(errors)[1]==="") {
                // 유효성 검사 통과: 회원가입 요청 등의 로직 수행
           if (newPw===confirmPw) {
                //pw변경
                localStorage.setItem("nowUserPwInLocal",newPw)
                changePwInDb()
           } else {
            window.confirm("The New Password and Confirm Password do not match.")
           }
           
       }else{
                  //비번 형식 맞지않는다 error창
                  window.confirm(errors.email+"\n"+errors.password);
              }
      
            
        }
        
        } else {
            //new pw랑 confirm pw랑 다름
            const different=window.confirm("The current password is incorrect.")
        }
       
    }
    return (
        <div className='ContentBox'
        
        style={{
            height:"calc(100vh - 158px - 63px)",
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        }}>
            <Card variant='outlined'
        
            >

<CardContent
sx={{
    display:"flex",
    flexDirection:"column",
    gap:"16px"
}}
>
    <div
    style={{
fontSize:"larger",
        width:"100%",
        display:"flex",
        alignItems:"center",
    justifyContent:"center",
   } }
    >Password Change</div>
<TxtfieldRow width='260px' containerWidth='260px' titleWidth='200px'
    title={"ID"}
    value={nowId}
    readOnly={true}/>
<TxtfieldRow width='260px' containerWidth='260px' titleWidth='200px'
    title={"Current Password"}
    value={currentPw}
    onChange={handleChangeCurrPw}
    /><TxtfieldRow width='260px' containerWidth='260px' titleWidth='200px'
    title={"New Password"}
    value={newPw}
    onChange={handleChangeNewPw}
    />
    <TxtfieldRow width='260px' containerWidth='260px' titleWidth='200px'
    title={"Confirm New Password"}
    value={confirmPw}
    onChange={handleChangeConfirmPw}
    />
</CardContent>
<CardActions>
<Button variant='contained' fullWidth
onClick={handleChangePw}
>Change your password</Button>
</CardActions>
            </Card>
        </div>
    );
}

export default PwChangeContent;