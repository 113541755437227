import React, { useState } from 'react';
import { AppBar, Box, IconButton, Toolbar, Popover, Typography, ButtonBase } from '@mui/material';
import AppbarLogo from './mainpg-logo.svg';
import { useNavigate } from 'react-router-dom';
import { CloseSharp, Menu } from '@mui/icons-material';
import TranslateToggle from './TranslateToggle';
import { useTranslation } from 'react-i18next';

function MainCustomMbAppbar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const {t}=useTranslation()
    const navigate = useNavigate();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleItemClick = (path) => {
        navigate(path);
        handleClose(); // 메뉴를 클릭하면 팝오버가 닫히도록 함
    };

    return (
        <AppBar
            variant="outlined"
            position="sticky"
            sx={{ backgroundColor: "white", borderBottom: "1px solid #d9d9d9" }}
        >
            <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "100%", display: 'flex', alignItems: 'center' }}>
                    <IconButton color="inherit" onClick={() => { navigate("/") }}>
                        <img src={AppbarLogo} width={"76px"} alt="Main Logo" />
                    </IconButton>
                    <div style={{ flex: 1 }} />
                    <TranslateToggle/>
                    <div
                    style={{
                        width:"10px"
                    }}
                    />
                    <IconButton onClick={handleMenuClick}>
                        {open ? <CloseSharp /> : <Menu />}
                    </IconButton>
                </Box>
            </Toolbar>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        width: '100vw', // 화면 전체 너비로 설정
                        maxWidth: '100vw', // 최대 너비 설정
                        marginTop: '10px', // 앱바 바로 밑에 위치하도록 설정
                        marginLeft: '14px', // 앱바 바로 밑에 위치하도록 설정
                    }
                }}
            >
                <Box sx={{ padding: 2, paddingBottom: 1 }}>
                    <ButtonBase  sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d9d9d9",
                                color: "#6D6C6C",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                            {t("appbar.sale")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/purchase')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                color: "black",
                                textIndent: "20px",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                           {t("appbar.sale1")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/sale')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                color: "black",
                                textIndent: "20px",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                             {t("appbar.sale2")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/commercial')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d9d9d9",
                                color: "black",
                                textIndent: "20px",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                             {t("appbar.sale3")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/리모델링')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d9d9d9",
                                color: "#6D6C6C",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                             {t("appbar.remodeling")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/remodel-res')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                color: "black",
                                textIndent: "20px",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                               {t("appbar.remodeling1")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/remodel-purchase')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d9d9d9",
                                color: "black",
                                textIndent: "20px",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                               {t("appbar.remodeling2")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/homestaging')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d9d9d9",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                              {t("appbar.homestaging")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/flip')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d9d9d9",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                               {t("appbar.flip")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/manage')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d9d9d9",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                             {t("appbar.property")}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={() => handleItemClick('/contact')} sx={{ width: '100%' }}>
                        <Typography
                            sx={{
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                width: '100%',
                            }}
                            variant="h6"
                        >
                             {t("appbar.contact")}
                        </Typography>
                    </ButtonBase>
                
                </Box>
            </Popover>
        </AppBar>
    );
}

export default MainCustomMbAppbar;
