import { TextField, TextareaAutosize, useTheme } from '@mui/material';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
function DtPickerRow({
    
    onDtChange,
    readOnly=false,title,value,onChange,onBlur,titleWidth="500px",width="200px",containerWidth="800px"}) {
    const theme=useTheme()
        return (
        <div
        style={{
            display:"flex",
alignItems:"center",gap:"8px"
        }}
        >
            <div style={{fontSize:"16px",display:"flex",width:titleWidth,justifyContent:"flex-end"}}>{title+" : "}</div>  
           <div style={{width:containerWidth}}>
            
            <input 
           disabled={readOnly}
            type={readOnly?'text':'date'}

          value={value}
onChange={(e)=>{
    if (!readOnly) {
        onDtChange(e)
    } else {
        window.confirm("You cannot change what has already been reviewed.")
        return
    }
    }}
        style={{
            height:"40px",
            width:width,
            borderRadius:"6px",
            border:`1px solid ${theme.palette.grey[500]}`
        }}
            /></div> 
        </div>
    );
}

export default DtPickerRow;