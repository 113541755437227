// 개발자 계정 앱 연결
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from 'firebase/firestore'
// import { getStorage } from "firebase/storage";
// const firebaseConfig = {
//   apiKey: "AIzaSyDU2FrgrsMpJ0hZ18XYHy-8R6NqPUPtnvQ",
//   authDomain: "filecheckpj.firebaseapp.com",
//   projectId: "filecheckpj",
//   storageBucket: "filecheckpj.appspot.com",
//   messagingSenderId: "1063329773988",
//   appId: "1:1063329773988:web:3b0ce210767738fe4ce931",
//   measurementId: "G-72X9WF91G7"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
// export const db=getFirestore(app)
// export const storage = getStorage(app)
//고객 계정 앱 연결
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCHAyhEpq5kunNXDITgduQmm0QDzH37ei4",
  authDomain: "filecheckpj-8c6b4.firebaseapp.com",
  projectId: "filecheckpj-8c6b4",
  storageBucket: "filecheckpj-8c6b4.appspot.com",
  messagingSenderId: "187244641164",
  appId: "1:187244641164:web:6a378d01bc77c1289ab4a9",
  measurementId: "G-BH4KND994Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db=getFirestore(app)
export const storage = getStorage(app)
// {
//   "hosting": {
//     "public": "build",
//    "headers": [ {
//   "source" : "**/*.@(eot|otf|ttf|ttc|woff|font.css)",
//   "headers" : [ {
//     "key" : "Access-Control-Allow-Origin",
//     "value" : "*"
//   } ]
// }, {
//   "source" : "**/*.@(js|css)",
//   "headers" : [ {
//     "key" : "Cache-Control",
//     "value" : "max-age=604800"
//   } ]
// }, {
//   "source" : "**/*.@(jpg|jpeg|gif|png)",
//   "headers" : [ {
//     "key" : "Cache-Control",
//     "value" : "max-age=604800"
//   } ]
// }, {
//   "source" : "404.html",
//   "headers" : [ {
//     "key" : "Cache-Control",
//     "value" : "max-age=300"
//   } ]
// } ],
//     "ignore": [
//       "firebase.json",
//       "**/.*",
//       "**/node_modules/**"
//     ],
//     "rewrites": [
//       {
//         "source": "**",
//         "destination": "/index.html"
//       }
//     ]
//   }
// }
