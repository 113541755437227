import React, { useEffect, useState } from 'react';
import TxtfieldRow from '../components/TxtfieldRow';
import "../App.css"
import { Button, Card, CardContent, Chip, Radio, useTheme } from '@mui/material';
import OtherTxtFieldRow from '../components/OtherTxtFieldRow';
import { allowDotFormatNumber, getBrokerSplit, getCalcedBrokerSplit, removeCommaToFloat } from '../const/const';
import { collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import TxtFieldWithBtn from '../components/TxtFieldWithBtn';
import DtPickerRow from '../components/DtPickerRow';
import RadiosRow from './RadiosRow';
import ChipRow from '../components/ChipRow';
import { useLocation } from 'react-router-dom';

export default function ClosingDetail({

  isLease,
  disabled,
  closingDate,
  setClosingDate,
  closingENOinsurance,
setClosingENOinsurance,
  firstOfficerPrice, offerAcceptedPrice, closedPrice,
  comAmount, comChk, brokerSplit, other1, other2, other3, totalLess,
  netCom,
  setFirstOfficerPrice, setOfferAcceptedPrice, setClosedPrice,
  setComAmount, setComChk, setBrokerSplit, setOther1, setOther2, setOther3, setTotalLess,
  setNetCom,transType,transTypeOptions
}) {
  const theme=useTheme()
  const [closedDetail,setClosedDetail]=useState(null)
  const [percentClosedDetail,setPercentClosedDetail]=useState(null)
  const [mul,setMul]=useState(null)
  const [nowAmountType,setNowAmountType]=useState("")
  const [eno,setEno]=useState(null)
  const closedCollection = collection(db, "options")
  const nowUserType=localStorage.getItem("nowUserTypeInLocal")
  const userSplitClass=localStorage.getItem('nowSplitClassInLocal')
  const [nowSplitClass,setNowSplitClass]=useState("")
const location=useLocation()
const fetchUsers = async () => {
  try {
    const usersCol = collection(db, "userInfos")
    const querySnapshot = await getDocs(usersCol);
    const users = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      users.push(doc.data());

    });
    return users;
  } catch (error) {
    console.error("Error fetching posts: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchEvery=async()=>{
  const rowDatas=location.state.row
 
  const res=await fetchUsers()  
  const filteredClass=res.filter(r=>r.id===rowDatas.userId)[0].splitClass
  setNowSplitClass(filteredClass)

}
  useEffect(()=>{  
  
    if (nowUserType==="admin") {
      fetchEvery()
         
    } else {
      setNowSplitClass(userSplitClass)
    }

 getAllData()
  },[])
  const getAllData=async()=>{

    const res=await  getAmountType()
    getBrokerSplit(res)
    getMul()
    getEno()
  }
  //포커스 아웃 하면 여기 탐
  const setCalcedBrokerSplit=()=>{
    const noComma=closedPrice.replace(/,/g, '')
    const numPrice=parseFloat(noComma)

    const findedVal = Object.values(closedDetail[nowSplitClass]).find(d => {
      const closedPrice = d["closedPrice"];
     
      if (closedPrice && closedPrice.includes("~")) {
          const [minValue, maxValue] = closedPrice.split("~");
          if (maxValue==="") {  
          
            const min = parseFloat(minValue.replace(/,/g, ''));
             return numPrice>=min
          }else{
              const min = parseFloat(minValue.replace(/,/g, ''));
          const max = parseFloat(maxValue.replace(/,/g, ''));
         
          return min <= numPrice && numPrice <= max;
          }
        
        
      }
      
      return false;
  }); 
    const newBrokerSplit=findedVal===undefined?"-":findedVal["brokerSplit"]
const res=getCalcedBrokerSplit(transTypeOptions[transType],comChk,newBrokerSplit,mul)
if (isLease) {
  //
  const percent=parseFloat(percentClosedDetail[nowSplitClass])/100
  const divided=(res*percent).toFixed(2)
  // console.log("lease 적용????>>>",res,typeof percent)
  if (percent===0) {
    setBrokerSplit(0.00)
  } else {
     setBrokerSplit(divided)
  }
 
} else {
  // if (nowAmountType==="percent") {
    
    
  //   const removedComma=comChk.replace(/,/g, '')
  //   const percent=parseFloat(closedDetail[nowSplitClass])/100
  //   const percentVal=(removedComma*percent).toFixed(2)
  //   setBrokerSplit(percentVal)
  //   // console.log("pppppp",res)

  //   // setBrokerSplit(res)
  // } else {
    setBrokerSplit(res)
  // }
    
}
  }
  const getAmountType=async()=>{
    const docRef = doc(closedCollection, "amountType")
    const data = await getDoc(docRef)
   
    if (data.exists()) {
     
      const res=data.data().type
     setNowAmountType(res)
     return res
    } else {
      return ""
    }
  }

const getBrokerSplit=async(type)=>{
    const docRef = doc(closedCollection, "closedDetail")
    const data = await getDoc(docRef)
    if (data.exists()) {
     
      const res=data.data()["selectList"]
      const percentRes=data.data()["selectPercentList"]
      setClosedDetail(res)
  setPercentClosedDetail(percentRes)
    } else {
    }
  }
  const getMul=async()=>{
    const docRef = doc(closedCollection, "brokerSplitMul")
    const data = await getDoc(docRef)
    
    if (data.exists()) {
     
      const res=data.data()["value"]
     setMul(res)
    } else {
    }
  }
  const getEno=async()=>{
    const docRef = doc(closedCollection, "closedDetail")
    const data = await getDoc(docRef)
    if (data.exists()) {
     
      const enoRes=data.data()["eno"]

      setEno(enoRes)
    } else {
    }
  }
  
  

  
  const priceChangeHandler = (onlyNum) => {
    const price = Number(onlyNum.replaceAll(',', ''));
    if (isNaN(price)) {
      return 0;
    } else {
      return price.toLocaleString('ko-KR');
    }
  }
  const onChangeFirstOfficerPrice = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
    setFirstOfficerPrice(formattedNumber)
  }
  const onChangeOfferAcceptedPrice = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
    setOfferAcceptedPrice(formattedNumber)
  }
  const onChangeClosedPrice = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
    setClosedPrice(formattedNumber)
  }
  // const onBlurClosedPrice=(e)=>{
  //   setCalcedBrokerSplit()
 
  // }
  const onChangeComAmount = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
    setComAmount(formattedNumber)
  }
  const onChangeComChk = (e) => {
    
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
        setComChk(formattedNumber)
        
    
  }
  const onChangeBrokerSplit = (e) => {
    const originBrokerSplit=e.target.value
    const calced=getBrokerSplit(transTypeOptions[transType],comChk,originBrokerSplit)
    setBrokerSplit(calced)
  }
  const onChangeOther1 = (e) => {
    setOther1(p => [e.target.value, p[1]])
  }

 
  const onChangeOther1Money = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
    setOther1(p => [p[0], formattedNumber]);
  }

  const onChangeOther2 = (e) => {
    setOther2(p => [e.target.value, p[1]])
  }
  const onChangeOther2Money = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
    setOther2(p => [p[0], formattedNumber])
  }
  const onChangeOther3 = (e) => {
    setOther3(p => [e.target.value, p[1]])
  }
  const onChangeOther3Money = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
    setOther3(p => [p[0], formattedNumber])
  }
  const onChangeTotalLess = (e) => {
    setTotalLess(e.target.value)
  }
  const onChangeNetCom = (e) => {
    setNetCom(e.target.value)
  }
const onBlurComChk=((e)=>{
setCalcedBrokerSplit()
})
const onChangeEnOIns=(e)=>{
  const { value } = e.target;
    const onlyNumber = value.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용
    const formattedNumber = allowDotFormatNumber(onlyNumber);
  setClosingENOinsurance(formattedNumber)
}
// useEffect(()=>{

// const res=(removeCommaToFloat(brokerSplit)+removeCommaToFloat(other1[1])+removeCommaToFloat(other2[1])+removeCommaToFloat(other3[1])).toFixed(2)
//   const addComma=allowDotFormatNumber(res)
// setTotalLess(addComma)
// },[brokerSplit,other1,other2,other3])
// useEffect(()=>{


//   const res=(removeCommaToFloat(comChk)-removeCommaToFloat(totalLess)).toFixed(2)
//   const addComma=allowDotFormatNumber(res)
//   setNetCom(addComma)

// },
// [comChk,totalLess])
// useEffect(()=>{
//   if (isLease) {
//     const divided=removeCommaToFloat(comChk)*0.1
//       setBrokerSplit(divided)
//   }

// },[isLease])
const onDtChange=(e)=>{
  setClosingDate(e.target.value)
}
const getClassColor=(classType)=>{

  if (classType==="O") {
    return theme.palette.error.main
  } 
  else if (classType==="M"){
return theme.palette.success.main
  }
  else if (classType==="L"){
    return theme.palette.primary.main
      }
      else if (classType==="A"){
        return theme.palette.secondary.main
          }
  else {
    return null
  }
}
const onCalcTotalLess=()=>{
  const res=(removeCommaToFloat(brokerSplit)+removeCommaToFloat(closingENOinsurance)+removeCommaToFloat(other1[1])+removeCommaToFloat(other2[1])+removeCommaToFloat(other3[1])).toFixed(2)
  const addComma=allowDotFormatNumber(res)
setTotalLess(addComma)
}
const onCalcNetCom=(nowComChk,nowTotalLess)=>{
  
  const res=(removeCommaToFloat(nowComChk)-removeCommaToFloat(nowTotalLess)).toFixed(2)
  const addComma=allowDotFormatNumber(res)
  setNetCom(addComma)
}
const onCalcBrokerSplit=()=>{
  setCalcedBrokerSplit()
}
const onCalcEno=(nowSplitClass,eno)=>{
  setClosingENOinsurance(eno[nowSplitClass])
}
return (
    <Card sx={{ width: "100%" }} variant='outlined'>
      <CardContent >
        <div className='ContentBox'>
          <div>Closing Detail</div>
          <DtPickerRow 
          titleWidth='20vw'
          containerWidth='50vw'readOnly={disabled} title={"Closing Date"} value={closingDate}
          onDtChange={onDtChange}/>
          <TxtfieldRow 
          titleWidth='20vw'
          width={"50vw"} containerWidth='50vw'
          readOnly={disabled} title={"First Offer Price"} value={firstOfficerPrice} onChange={onChangeFirstOfficerPrice} />
          <TxtfieldRow 
          titleWidth='20vw'
          width={"50vw"} containerWidth='50vw'
          readOnly={disabled} title={"Offer Accepted Price"} value={offerAcceptedPrice} onChange={onChangeOfferAcceptedPrice} />
          <TxtfieldRow 
          titleWidth='20vw'
          width={"50vw"} containerWidth='50vw'
          readOnly={disabled} title={"Closed Price"} value={closedPrice} onChange={onChangeClosedPrice} />
          <TxtfieldRow 
          titleWidth='20vw'
          width={"50vw"} containerWidth='50vw'
          readOnly={disabled} title={`${isLease? `"Commision amount on "MLS or Listing Agreement"`:`Commision amount on "MLS or Escrow Instruction"`}`} value={comAmount} onChange={onChangeComAmount} />
          <TxtfieldRow 
          titleWidth='20vw'
          width={"50vw"} containerWidth='50vw'
          readOnly={disabled} title={"Commission amount on commission check"} value={comChk} onChange={onChangeComChk} onBlur={onBlurComChk} />
       {/* <RadiosRow
       title={"Class Type"}
       handleRadios={handleRadios}
       selectedValue={selectedValue}
        width={"1600px"} containerWidth='1600px'
        readOnly={disabled}
       /> */}
       {/* <div>Class Type : 
        <Chip label={nowSplitClass}
        sx={{
          backgroundColor:getClassColor(nowSplitClass),
          color:"white"
        }}
        />
        </div> */}
        <ChipRow
        titleWidth='20vw'
         width={"50vw"} containerWidth='50vw'
         chipBackgroundColor={getClassColor(nowSplitClass)}
         title={"Split Class Type"}
          label={nowSplitClass}
        />
       <TxtFieldWithBtn
       onClick={onCalcBrokerSplit}
       titleWidth='20vw'
       width={"calc(50vw - 100px)"} containerWidth='50vw'
      //  onClick={setCalcedBrokerSplit}
          readOnly={true}
          
          title={"Broker split"} value={brokerSplit==="-"?brokerSplit:brokerSplit} onChange={onChangeBrokerSplit} />
        {!isLease&&  <TxtFieldWithBtn 
        titleWidth='20vw'
        width={"calc(50vw - 100px)"} containerWidth='50vw'
        readOnly={disabled} title={`E & O Insurance`} value={closingENOinsurance} 

      onClick={()=>{onCalcEno(nowSplitClass,eno)}}
          />}
          <OtherTxtFieldRow 
          titleWidth='20vw'
          width='300px'
           containerWidth='50vw'
          disabled={disabled}
          title={"Other 1"} value={other1[0]} onChange={onChangeOther1} money={other1[1]} onChangeMoney={onChangeOther1Money} />
          <OtherTxtFieldRow 
          titleWidth='20vw'
           width='300px'
           containerWidth='50vw'
          disabled={disabled}
          title={"Other 2"} value={other2[0]} onChange={onChangeOther2} money={other2[1]} onChangeMoney={onChangeOther2Money} />
          <OtherTxtFieldRow 
          titleWidth='20vw'
           width='300px'
           containerWidth='50vw'
          disabled={disabled}
          title={"Other 3"} value={other3[0]} onChange={onChangeOther3} money={other3[1]} onChangeMoney={onChangeOther3Money} />
        <TxtFieldWithBtn 
        titleWidth='20vw'
        width={"calc(300px - 100px)"} containerWidth='50vw'
          readOnly={true}
          title={"Total Less"} value={totalLess==="NaN"?"":totalLess}  
          onClick={onCalcTotalLess}
          />
         
          
          <TxtFieldWithBtn
          titleWidth='20vw'
          width={"calc(300px - 100px)"} containerWidth='50vw'
          readOnly={true}
          
          title={"Net Commission"} value={netCom==="NaN"?"":netCom} 
          onClick={(e)=>{onCalcNetCom(comChk,totalLess)}}
          />
        </div>
      </CardContent>
    </Card>
  );
}
