import { MenuItem, Select, TextField } from '@mui/material';
import React from 'react';

export default function SelectRow({
  disabled=false,
  title,value,onChange,options,width="200px",titleWidth="500px",containerWidth="800px"}) {
    const noOption=options===null||options===undefined||options.length===0
    return (
        <div
        style={{
            display:"flex",
alignItems:"center",gap:"8px"
        }}
        >
            <div style={{fontSize:"16px",display:"flex",width:titleWidth,justifyContent:"flex-end"}}>{title+" : "}</div>  
            {/* <TextField sx={{ width: "300px" }}
      size='small'
        value={value}
onChange={onChange}
/> */}
<div style={{width:containerWidth}}> <Select
// disabled={true}
readOnly={disabled}
  sx={{ width: width }}
  size='small'
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={noOption?0:value}
    // label="Age"
    onChange={onChange}
  >
    {noOption?<MenuItem value={0}>-</MenuItem>:options.map((opt,index)=> <MenuItem value={index}>{opt}</MenuItem>)}
   
    {/* <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> */}
  </Select></div>
 
        </div>
    );
}
