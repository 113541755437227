import { Box, Button, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserAppBarTabView from './UserAppBarTabView';

function UserMenus(props) {
    const navigate=useNavigate()
    const location=useLocation()
    const [tabName,setTabName]=useState("table")
    const nowUserId = location.state.id
    const nowUserType = location.state.userType
    const handleChange=(e)=>{
setTabName(e.target.id)
navigate(`/${e.target.id}`, {
  state: { id: nowUserId, userType: nowUserType, row: null },
})
    }
    return (
        <div
        style={{
          display:"flex",
    width:"fit-content",
    gap:"8px"
       }}
        >
<UserAppBarTabView/>           
       </div>
    );
}

export default UserMenus;