import { Button, Card, CardContent, Fab, TextField, TextareaAutosize } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import "../App.css"

import NewTransactionDetailContent from './NewTransactionDetailContent';
import SellerAgentInfo from './SellerAgentInfo';
import BuyerAgentInfo from './BuyerAgentInfo';
import EscrowInfo from './EscrowInfo';
import LenderInfo from './LenderInfo';
import CustomTable from '../components/CustomTable';
import TxtfieldRow from '../components/TxtfieldRow';
import NoRequiredAlertDialog from '../components/NoRequiredAlertDialog'
import ClosingDetail from './ClosingDetail';
import { buyerBrokerNameState, filesState, loadingPercentAtom, mainLoadingAtom, temp5FileState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { db, storage } from '../service/firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, list, listAll } from 'firebase/storage';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import uuid from 'react-uuid'
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { allowScroll, bdFormat, getCalcedBrokerSplit, preventScroll } from '../const/const';
import moment from 'moment';
import UserTransacUploadTable from '../components/UserTransacUploadTable';
import UploadTablePerTrans from '../components/UploadTablePerTrans';
import { IoMdSave } from "react-icons/io";
import { MdUploadFile, MdDelete } from "react-icons/md";
import FileUploadTable from '../components/FileUploadTable';
//여기 들어올 때, row에 맞는 정보 가지고 와서 useEffect로 넣어주장.
function WriteContent(props) {
  const initialData = Array.from({ length: 60 }, () => ({ name: "", source: "" }));
  const navigationType = useNavigationType();

  const navigate = useNavigate()
  const location = useLocation()
  //New Transaction Detail
  const [transAddress, setTransAddress] = useState("")
  const [transCity, setTransCity] = useState("")
  const [transType, setTransType] = useState(0)
  const [transClientName, setTransClientName] = useState("")
  const [transPropertyAddress, setTransPropertyAddress] = useState("")
  const [transNmlsNum, setTransNmlsNum] = useState("")
  //Seller Agent Information
  const [sellerBrokerName, setSellerBrokerName] = useState("")
  const [sellerAgentName, setSellerAgentName] = useState("")
  const [sellerPhone, setSellerPhone] = useState("")
  const [sellerEmail, setSellerEmail] = useState("")
  const [sellerName, setSellerName] = useState("")
  //Buyer Agent Information
  const [buyerBrokerName, setBuyerBrokerName] = useState("")
  const [buyerAgentName, setBuyerAgentName] = useState("")
  const [buyerPhone, setBuyerPhone] = useState("")
  const [buyerEmail, setBuyerEmail] = useState("")
  const [buyerName, setBuyerName] = useState("")
  //Escrow Information
  const m = moment().format('YYYY-MM-DD');
  const [escrowOpenDate, setEscrowOpenDate] = useState(m)
  const [escrowCName, setEscrowCName] = useState("")
  const [escrowNum, setEscrowNum] = useState("")
  const [escrowOfficerName, setEscrowOfficerName] = useState("")
  const [escrowPhoneNum, setEscrowPhoneNum] = useState("")
  const [escrowEmail, setEscrowEmail] = useState("")
  //Lender Information
  const [lenderName, setLenderName] = useState("")
  const [lenderLoanOriginatorName, setLenderLoanOriginatorName] = useState("")
  const [lenderPhoneNum, setLenderPhoneNum] = useState("")
  const [lenderEmail, setLenderEmail] = useState("")
  //Closing Detail

  const [closingDate, setClosingDate] = useState(m)
  const [closingFirstOfferPrice, setClosingFirstOfferPrice] = useState("")
  const [closingOfferAcceptedPrice, setClosingOfferAcceptedPrice] = useState("")
  const [closingClosedPrice, setClosingClosedPrice] = useState("")
  const [closingComAmount, setClosingComAmount] = useState("")
  const [closingComChk, setClosingComChk] = useState("0")
  const [closingBrokerSplit, setClosingBrokerSplit] = useState("")
  const [closingENOinsurance, setClosingENOinsurance] = useState("")
  const [closingOther1, setClosingOther1] = useState(["", "0"])
  const [closingOther2, setClosingOther2] = useState(["", "0"])
  const [closingOther3, setClosingOther3] = useState(["", "0"])
  const [closingTotalLess, setClosingTotalLess] = useState("")
  const [closingNetCom, setClosingNetCom] = useState("")
  const [selectedValue, setSelectedValue] = useState("A")

  //유저가 doc 추가로 row추가하는거(doc.name , doc.source)
  const [customDocs, setCustomDocs] = useState([])
  //transTypeOptions
  const [transTypeOptions, setTransTypeOptions] = useState(null)
  const [noSubmitAlert, setNoSubmitAlert] = useState(false)
  const today = new Date()
  // const todayFullDate = moment().format('YYYY-MM-DD HH:mm:ss')//today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const [todayFullDate, setTodayFullDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'))

  const tableRowsCollection = collection(db, "documentsForTransaction")
  //테이블 rows
  const [tableDatas, setTableDatas] = useState(null)
  //업로드테이블에 쓸 거
  const [uploadTable, setUploadTable] = useState([])//useState(null)
  // const [filesAtom,setFilesAtom]=useRecoilState(filesState)
  const [temp5Files, setTemp5Files] = useRecoilState(temp5FileState)
  const [newUploadTable, setNewUploadTable] = useState([])
  const [isLease, setIsLease] = useState(false)
  // const [saveLoading,setSaveLoading]=useState(false)
  const [mainLoading, setMainLoading] = useRecoilState(mainLoadingAtom)
  //퍼센테이지 저장
  const [loadingPercent,setLoadingPercent]=useRecoilState(loadingPercentAtom)
  const dataCollection = collection(db, "pjDatas")
  //addRow변수, 새로 추가되는 테이블 로우
  const [newRows,setNewRows]=useState([])
  const writeRowKey=location.state.rowKey
  //transType options 파베에서 가지고 오기
  async function getTransTypeOptions() {
    const transTypeCollection = collection(db, "options")
    const docRef = doc(transTypeCollection, "transactionType");

    try {
      const transData = (await getDoc(docRef)).data();

      if (transData && "selectList" in transData) { // 수정된 부분
        setTransTypeOptions(transData["selectList"]);
      } else {
      }
    } catch (error) {
      setTransTypeOptions([]);
    }
  }
  const getFilesInit = async () => {
    //transactionType당 업로드 파일 5개씩 할 수 있는데, 
    //트랜잭션 타입 당 업로드 파일 5개 로우 매핑시키는 것
    const originRowKey = location.state.rowKey;
    const newRowKey = uuid();
    const userId = location.state.id;
    const objKey = originRowKey === undefined ? newRowKey : originRowKey;
    const docRef = doc(dataCollection, userId);

    try {
      const editList = await getDoc(docRef);

      if (editList.exists()) {
        //edit하는경우와 write하는 경우를 분기
        //아래가 edit / write로 분기한거
        //로우 키가 안들어왔으니까, write 페이지
        if (originRowKey === undefined) {
          //transactionOption 얻는 과정
          const ttCollection = collection(db, "options");
          const ttRef = doc(ttCollection, "transactionType")
          const ttOptions = await getDoc(ttRef)
          if (ttOptions.exists()) {

            const ttOptionsData = ttOptions.data()
            const selTtOption = ttOptionsData["selectList"][transType]
            const docsCollection = collection(db, "documentsForTransaction");
            const docRef = doc(docsCollection, selTtOption);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              //도큐멘트 정보들 있음

            } else {
              //도큐멘트 정보들 없음->도큐멘트 정보들 애초에 다 60줄씩 초기화해놓자


              // setFielsAtom(modifiedDocData);
            }


          } else {
          }



        }

        //네비게이트로 전달받은 키가 있는 경우니까 edit
        else {
          const nowUserType = location.state.userType;
          if (nowUserType === "admin") {
            //  const nowRow=location.state.row
            //  setFilesAtom(nowRow["tablePerTransaction"]);
          } else {
            //    const data = editList.data();
            //  const res = data[objKey]["tablePerTransaction"];
            //   setFilesAtom(res);
          }


        }

      } else {
        //아예 유저별 데이터가 없는 경우
        return false;
      }
    } catch (error) {
      // Handle any potential errors during the asynchronous operations
      return false;
    }
  }
  const getTableDatas = async () => {
    const selTransc = transTypeOptions[transType]
    const docsCollection = collection(db, "documentsForTransaction");
    const docRef = doc(docsCollection, selTransc);
    const data = await getDoc(docRef);

    if (data.exists()) {
      const res = data.data()["documents"];
      setTableDatas(res);
      return res
    } else {
      // 문서가 존재하지 않으면 새로운 문서 생성
      const newDocRef = doc(docsCollection, selTransc);
      // 여기에서 초기화할 데이터를 설정해주세요.
      await setDoc(newDocRef, { documents: initialData });

      // 생성한 문서의 데이터로 tableDatas 설정
      setTableDatas(initialData);
      return initialData
    }
  };
  useEffect(() => {

    getTransTypeOptions()
  }, [])
  // 1.파일 업로드 함수->원래거(속도개선 전)
  // const uploadFileToDB = async (nowRowKey) => {
  //   const storagePath = (docName, docSrc, originIdx, fileName) => `${nowRowKey}/${docName}/${docSrc}/${fileName}`;
  //   const files = uploadTable.filter(ut => ut.name != "" && ut.source != "").map(a => {
  //     {
  //       const res = { ...a, uploadFiles: a.uploadFiles }
  //       return res
  //     }
  //   }).flat()
  //   const filesForUpload = [...files]

  //   for (let i = 0; i < files.length; i++) {
  //     const uploadFiles = files[i].uploadFiles
  //     const docName = files[i].name
  //     const docSrc = files[i].source
  //     const originIdx = files[i].originIdx
  //     const filenameList = []
  //     const thumbnails=[]

  //     for (let j = 0; j < uploadFiles.length; j++) {
  //       const uploadFile = uploadFiles[j];
  //       const fileName = uploadFile.name === undefined ? "" : uploadFile.name
  //       // const fileURL = fileName === "" ? "" : await uploadFileToStorage(uploadFile, storagePath(docName, docSrc, originIdx, fileName));
  //       const fileURL = fileName === "" ? "" : await uploadFileToStorage(uploadFile, storagePath(docName, docSrc, originIdx, fileName));
  //     // const aaa=await uploadMultipleFilesToStorage(uploadFiles, storagePath(docName, docSrc, originIdx, fileName))  
  //     // console.log("urls>>>",aaa)  
  //     // const fileUrls=await downloadMultipleFiles()
  //       filenameList.push(fileName)
  //        thumbnails.push(fileURL)

  //     }

  //     filesForUpload[i] = { name: docName, source: docSrc, filenameList: filenameList,

  //       thumbnails:thumbnails
  //     }

  //   }

  //   setNewUploadTable(filesForUpload)
  //   return filesForUpload

  // };
  // 2.파일 업로드 함수->속도개선 1
  const uploadFileToDB = async (nowRowKey) => {
    const storagePath = (docName, docSrc, originIdx, fileName) => `${nowRowKey}/${docName}/${docSrc}/${fileName}`;

    const files = uploadTable
      .filter(ut => ut.name != "" && ut.source != "")
      .map(a => ({ ...a, uploadFiles: a.uploadFiles }))
      .flat();
    const filesForUpload = await Promise.all(files.map(async (file) => {


      const { uploadFiles, name: docName, source: docSrc, originIdx } = file;
      const filenameList = Array(5).fill("");
      const thumbnails = Array(5).fill("");
      const onlyHaveFiles = uploadFiles.filter(v => v != "")
      const uploadPromises = onlyHaveFiles.map(async (uploadFile, index) => {
        const fileName = uploadFile.name || "";
        const fileURL = await uploadFileToStorage(uploadFile, storagePath(docName, docSrc, originIdx, fileName));
        filenameList[index] = fileName;
        thumbnails[index] = fileName != "" ? fileURL : ""
      });

      await Promise.all(uploadPromises);

      return {
        name: docName,
        source: docSrc,
        custom: file.custom,
        applicable: file.applicable,
        uploadFiles:filenameList,
        thumbnails
      };
    }));
    // setNewUploadTable(filesForUpload);
    return filesForUpload;
  };
  //스토리지에 저장한 다음, url을 가지고 오는 방식이다.
  //1.파일 업로드 : 기존 방식(너무 느림)
  const uploadFileToStorage = async (file, path) => {
    
    // console.log("upload to storage>>>", file)
    const storageRef = ref(storage, path);

    const uploadTask = uploadBytesResumable(storageRef, file);

    // 업로드 진행률 이벤트 리스너 추가
    uploadTask.on('state_changed',
      (snapshot) => {
        // 업로드 진행 중일 때 호출되는 콜백
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + Math.ceil(progress) + '% done');
        const nowPercent=file.name+' is ' + Math.ceil(progress) + '% done';
        setLoadingPercent(nowPercent)
      },
      (error) => {
        // 업로드 중 오류가 발생했을 때 호출되는 콜백
        console.error('Error uploading file: ', error);
      },
      () => {
        // 업로드가 완료되었을 때 호출되는 콜백
        console.log('Upload complete');
       
      }
    );
    await uploadTask;
     setLoadingPercent(null)
    // 파일 업로드 후 다운로드 URL 가져오기
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;

  };
  //분할로 저장하는거
  //   const uploadFileToStorage = async (file, path) => {
  //     const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB
  //     const storageRef = ref(storage, path);
  //     const fileSize = file.size;
  //     console.log("파일 사이즈", fileSize);
  //     let bytesUploaded = 0;
  //     let chunks = [];

  //     while (bytesUploaded < fileSize) {
  //         const start = bytesUploaded;
  //         const end = Math.min(fileSize, start + CHUNK_SIZE);
  //         const chunk = file.slice(start, end);
  //         chunks.push(chunk);
  //         bytesUploaded += CHUNK_SIZE;
  //     }

  //     // 파일 조각을 순서대로 업로드
  //     for (let i = 0; i < chunks.length; i++) {
  //         const chunk = chunks[i];
  //         const uploadTask = uploadBytesResumable(storageRef, chunk);
  //         await uploadTask;
  //     }

  //     // 파일 업로드 후 다운로드 URL 가져오기
  //     const downloadURL = await getDownloadURL(storageRef);
  //     return downloadURL;
  // };
  //2. 파일 업로드 : 속도 개선 방식->파일을 나눠서->10초 이상 걸림
  //   const uploadFileToStorage = async (file, path) => {
  //     console.time("이미지 업로드 개선")
  // const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB
  // const storageRef = ref(storage, path);
  // const fileSize = file.size;
  // let bytesUploaded = 0;

  // while (bytesUploaded < fileSize) {
  //     const start = bytesUploaded;
  //     const end = Math.min(fileSize, start + CHUNK_SIZE);
  //     const chunk = file.slice(start, end);
  //     const uploadTask = uploadBytesResumable(storageRef, chunk);

  //     await uploadTask;

  //     bytesUploaded += CHUNK_SIZE;
  //     // Progress indication can be added here if needed
  // }

  //     const downloadURL = await getDownloadURL(storageRef);
  //     return downloadURL;
  // };
  //3. 파일 업로드 : 속도 개선 방식->Promise-start->옛날코드여서 작동 안됨
  // const uploadFileToStorage = async (file, path) => {
  //   console.time("img upload 3>>>")
  //   const storageRef = ref(storage, path);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   const snapshot = await uploadTask;

  //   // 파일 업로드 후 다운로드 URL 가져오기
  //   const downloadURL = await snapshot.ref.getDownloadURL();
  //   return downloadURL;
  // };

  // 다중 파일 업로드 함수
  const uploadMultipleFilesToStorage = async (files, path) => {
    const promises = files.map(file => uploadFileToStorage(file, path));
    return Promise.all(promises);
  };

  // 다중 파일 다운로드 함수
  const downloadMultipleFiles = async (fileRefs) => {
    const promises = fileRefs.map(fileRef => fileRef.getDownloadURL());
    return Promise.all(promises);
  };
  //3. 파일 업로드 : 속도 개선 방식->Promise-end
  const dbSave = async (formState) => {
    try {
      setMainLoading(true)
      const docNewKey = location.state.rowKey//uuid()
      const nowUserId = location.state.id
      const docRef = doc(dataCollection, nowUserId)
      const docPerId = await getDoc(docRef)
      //bdnum 전체 트랜잭션 따지기
      const bdDatas = await getDocs(dataCollection)
      // const allBdDatas = bdDatas.docs.map(d => Object.values(d.data())).flat()
      const filtered = bdDatas.docs.filter(v => Object.entries(v.data()).filter(([key]) => key != 'ins' && key != 'ann')).map(d => Object.values(d.data())).flat().flat().filter(v => v.hasOwnProperty("bdNum"))
      const allBdDatas = bdDatas.docs.map(d => Object.values(d.data())).flat()
      if (docPerId.data() === null || docPerId.data() === undefined) return;

      
      // const aanewTable = []//await uploadFileToDB(docNewKey);
      const formattedUploadTable=uploadTable.map(up=>{
        const emptyThumbnails=["","","","",""]
        const fileNameList=up.uploadFiles.map(file=>file.name===undefined?"":file.name)
        const newObj={...up,thumbnails:emptyThumbnails, uploadFiles:fileNameList}
        return newObj
      })
      const docLength = filtered.length + 1

      const updateObject = {};
      const newTransJson = {
        transType: transType,
        transAddress: transAddress,
        transCity: transCity,
        clientName: transClientName,
        date: todayFullDate,
        nmlsNum: transNmlsNum,
      }
      const newSellerJson = {
        brokerName: sellerBrokerName,
        agentName: sellerAgentName,
        phone: sellerPhone,
        email: sellerEmail,
        name: sellerName
      }
      const newBuyerJson = {
        brokerName: buyerBrokerName,
        agentName: buyerAgentName,
        phone: buyerPhone,
        email: buyerEmail,
        name: buyerName
      }
      const newEscrowJson = {
        escrowOpenDate: escrowOpenDate,
        cName: escrowCName,
        num: escrowNum,
        officerName: escrowOfficerName,
        phone: escrowPhoneNum,
        email: escrowEmail,
      }
      const newLenderJson = {
        name: lenderName,
        loanOriginatorName: lenderLoanOriginatorName,
        phone: lenderPhoneNum,
        email: lenderEmail
      }
      const newClosingJson = {
        closingDate: closingDate,
        firstOfferPrice: closingFirstOfferPrice,
        offerAcceptedPrice: closingOfferAcceptedPrice,
        closedPrice: closingClosedPrice,
        comAmount: closingComAmount,
        comChk: closingComChk,
        brokerSplit: closingBrokerSplit,
        enoInsurance:closingENOinsurance,
        other1: closingOther1,
        other2: closingOther2,
        other3: closingOther3,
        totalLess: closingTotalLess,
        netCom: closingNetCom
      }

      updateObject[`${docNewKey}`] = {
        userId: nowUserId,
        id: docNewKey,
        writeDate: todayFullDate,
        dateTime: new Date().toString(),
        bdNum: bdFormat(parseInt(docLength)),
        newTransactionDetail: newTransJson,
        tablePerTransaction: formattedUploadTable,
        customDocs: customDocs,
        sellerAgentInfo: newSellerJson,
        buyerAgentInfo: newBuyerJson,
        escrowInfo: newEscrowJson,
        lenderInfo: newLenderJson,
        closingDetail: newClosingJson,
        formState: formState
      };

      updateDoc(doc(dataCollection, nowUserId), updateObject);
      // navigate("/fileupload/table", {
      //   state: { id: userId, userType: userType, row: null }
      // })

    } catch (error) {
      window.confirm("error :", error)
      // console.log("db save error", error)
    } finally {
      // console.log("finally")
      const userId = location.state.id
      const userType = location.state.userType
      navigate("/fileupload/table", {
        state: { id: userId, userType: userType, row: null }
      })
      setMainLoading(false)
    }

  }
  const onSave = async () => {
    //db에 저장하는 onSave



    await dbSave("save")
  }
  const onSubmit = async () => {
    const leaseSubmitCondition =
      transAddress != "" &&
      transCity != "" &&
      transClientName != "" &&
      // transPropertyAddress != "" &&
      todayFullDate != "" &&
      transNmlsNum != "" &&
      sellerBrokerName != "" &&
      sellerAgentName != "" &&
      sellerPhone != "" &&
      sellerEmail != "" &&
      sellerName != "" &&
      buyerBrokerName != "" &&
      buyerAgentName != "" &&
      buyerPhone != "" &&
      buyerEmail != "" &&
      buyerName != "" &&
      closingDate != "" &&
      closingFirstOfferPrice != "" &&
      closingOfferAcceptedPrice != "" &&
      closingClosedPrice != "" &&
      closingComAmount != "" &&
      closingComChk != "" &&
      closingBrokerSplit != "" &&
      closingTotalLess != "" &&
      closingNetCom != ""
    const submitCondition =
      transAddress != "" &&
      transCity != "" &&
      transClientName != "" &&
      // transPropertyAddress != "" &&
      todayFullDate != "" &&
      transNmlsNum != "" &&
      sellerBrokerName != "" &&
      sellerAgentName != "" &&
      sellerPhone != "" &&
      sellerEmail != "" &&
      sellerName != "" &&
      buyerBrokerName != "" &&
      buyerAgentName != "" &&
      buyerPhone != "" &&
      buyerEmail != "" &&
      buyerName != "" &&
      escrowOpenDate != "" &&
      escrowCName != "" &&
      escrowNum != "" &&
      escrowOfficerName != "" &&
      escrowPhoneNum != "" &&
      escrowEmail != "" &&
      // lenderName != "" &&
      // lenderLoanOriginatorName != "" &&
      // lenderPhoneNum != "" &&
      // lenderEmail != "" &&
      closingDate != "" &&
      closingFirstOfferPrice != "" &&
      closingOfferAcceptedPrice != "" &&
      closingClosedPrice != "" &&
      closingComAmount != "" &&
      closingComChk != "" &&
      closingBrokerSplit != "" &&

      closingTotalLess != "" &&
      closingNetCom != ""
    //submit조건이 모든 텍스트필드가 채워졌을 때 인데, 조건을 충족하지못했을 시 알람창, 충족했을 시 submit
    if (isLease ? leaseSubmitCondition : submitCondition) {
      //테이블에 로우 추가
      // await updateDatas(uploadTable);
      const isNotUploaded = uploadTable.filter(v => v.source != "" && v.name != "").filter(up => up.applicable && up.uploadFiles.filter(fn => fn != "").length === 0).length != 0

      if (isNotUploaded) {
        window.confirm("Please upload all the required files before submitting.")
        return;
      } else {
        await dbSave("submit")

      }


    } else {
      setNoSubmitAlert(true)
    }
  }
  const onClickDelete = () => {
    //텍스트 필드들 클리어
    //trans
    setTransType("")
    setTransClientName("")
    setTransPropertyAddress("")
    setTransNmlsNum("")
    //seller
    setSellerBrokerName("")
    setSellerPhone("")
    setSellerEmail("")
    setSellerName("")
    //buyer
    setBuyerBrokerName("")
    setBuyerAgentName("")
    setBuyerPhone("")
    setBuyerEmail("")
    setBuyerName("")
    //escrow
    setEscrowCName("")
    setEscrowNum("")
    setEscrowOfficerName("")
    setEscrowPhoneNum("")
    setEscrowEmail("")
    //lender
    setLenderName("")
    setLenderLoanOriginatorName("")
    setLenderPhoneNum("")
    setLenderEmail("")
    //closing
    setClosingFirstOfferPrice("")
    setClosingOfferAcceptedPrice("")
    setClosingClosedPrice("")
    setClosingComAmount("")
    setClosingComChk("")
    setClosingBrokerSplit("")
    setClosingOther1(["", ""])
    setClosingOther2(["", ""])
    setClosingOther3(["", ""])
    setClosingTotalLess("")
    setClosingNetCom("")

  }
  const handleAlertClose = () => {


    setNoSubmitAlert(false)
  }
  //트랜잭션 타입 바뀔 때마다 표 바뀜

  useEffect(() => {
    if (transTypeOptions === null) return;
    getTableDatas().then(result => {
      if (result === null) return;
      const copyArr = [...result.map(td => { return { ...td, applicable: true, custom: false, uploadFiles: Array(5).fill(new File([],"")), thumbnails: Array(5).fill("") } })]
      setUploadTable(copyArr)
    }
    )

    const isLease = transTypeOptions[transType].includes("Lease")
    setIsLease(isLease)
   
    
    // if (isLease) {
    //   const mul=2;//현재 2로 고정
    //   const res=getCalcedBrokerSplit(transTypeOptions[transType],closingComChk,closingBrokerSplit,mul)
    //   const divided=parseFloat(res*0.1).toFixed(2)
    //   // console.log("in write content>>>",divided)
    //   setClosingBrokerSplit(divided)
    // }
    // else{
    //   const mul=2;//현재 2로 고정
    //   const res=getCalcedBrokerSplit(transTypeOptions[transType],closingComChk,closingBrokerSplit,mul)
    //   setClosingBrokerSplit(res)
    // }
  }, [transType, transTypeOptions])
  // useEffect(() => {

  // }, [uploadTable])

  const handleRadios = (e) => {

    setSelectedValue(e.target.value)
  }
//아래부터 파일올리는 테이블 에러때문에 새로 짠 코드
const onAddRow=()=>{
  const newRow={uploadFiles:["","","","",""],source:"",name:""};//임시
  setNewRows(p=>[...p,newRow])
}

const deleteFilesExcept = async (nowRowKey) => {
  const storageRef = ref(storage, `${nowRowKey}/`);

  try {
    const fileList = await list(storageRef);
    fileList.items.forEach(async (item) => {
      // const fullPath = item.fullPath;
      // if (!existingFiles.includes(item.name)) {
        await deleteObject(item);
      // }
    });
  } catch (error) {
  }
};


useEffect(() => {
//   const handleBack = async() => {
//     // 뒤로가기 버튼이 클릭되었을 때 실행할 코드 작성
//     console.log('뒤로가기 버튼이 클릭되었습니다.',location.state.rowKey);
//     window.confirm("뒤로가시겠습니까?")
//  await   deleteFilesExcept(location.state.rowKey)
//   };

//   window.addEventListener('popstate', handleBack);

//   return () => {
//     window.removeEventListener('popstate', handleBack); // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
//   };
if (location.state.rowKey===undefined)return;
deleteFilesExcept(location.state.rowKey)
}, []);
  return (
    <div className="ContentBox" style={{ display: "flex", flexDirection: "column" }}>


      <NoRequiredAlertDialog open={noSubmitAlert} msg={"Please fill in all information"} handleClose={handleAlertClose} />
      <NewTransactionDetailContent
      // disabled={true}
      onChangeEditTable={(e)=>{}}
      onChangeTransType={(e)=>{}}
        transCity={transCity}
        transAddress={transAddress}
        transType={transType}
        transTypeOptions={transTypeOptions}
        clientName={transClientName}
        propertyAddress={transPropertyAddress}
        nmlsNum={transNmlsNum}
        todayFullDate={todayFullDate}
        setTransCity={setTransCity}
        setTransAddress={setTransAddress}
        setTransType={setTransType}
        setClientName={setTransClientName}
        setPropertyAddress={setTransPropertyAddress}
        setNmlsNum={setTransNmlsNum}
        setTodayFullDate={setTodayFullDate}
      />

      <SellerAgentInfo
        isLease={isLease}
        brokerName={sellerBrokerName}
        email={sellerEmail}
        phoneNum={sellerPhone}
        sellerAgentName={sellerAgentName}
        sellerName={sellerName}
        setBrokerName={setSellerBrokerName}
        setEmail={setSellerEmail}
        setPhone={setSellerPhone}
        setAgentName={setSellerAgentName}
        setName={setSellerName}

      />
      <BuyerAgentInfo
        isLease={isLease}
        brokerName={buyerBrokerName}
        buyerAgentName={buyerAgentName}
        buyerName={buyerName}
        cellPhone={buyerPhone}
        email={buyerEmail}
        setBrokerName={setBuyerBrokerName}
        setBuyerAgentName={setBuyerAgentName}
        setBuyerName={setBuyerName}
        setPhone={setBuyerPhone}
        setEmail={setBuyerEmail}
      />

      {
        !isLease ?
          <EscrowInfo
            escrowOpenDate={escrowOpenDate}
            setEscrowOpenDate={setEscrowOpenDate}
            companyName={escrowCName}
            email={escrowEmail}
            number={escrowNum}
            officerName={escrowOfficerName}
            phoneNum={escrowPhoneNum}
            setCname={setEscrowCName}
            setEmail={setEscrowEmail}
            setNum={setEscrowNum}
            setOfficerName={setEscrowOfficerName}
            setPhone={setEscrowPhoneNum}

          /> : <></>}
      {!isLease ? <LenderInfo
        email={lenderEmail}
        name={lenderName}
        originatorName={lenderLoanOriginatorName}
        phoneNum={lenderPhoneNum}
        setEmail={setLenderEmail}
        setName={setLenderName}
        setOriginatorName={setLenderLoanOriginatorName}
        setPhone={setLenderPhoneNum}
      /> : <></>}

      <ClosingDetail
        // selectedValue={selectedValue}
        // handleRadios={handleRadios}
        closingENOinsurance={closingENOinsurance}
        setClosingENOinsurance={setClosingENOinsurance}
        isLease={isLease}
        closingDate={closingDate}
        setClosingDate={setClosingDate}
        transType={transType}
        transTypeOptions={transTypeOptions}
        brokerSplit={closingBrokerSplit}
        closedPrice={closingClosedPrice}
        comAmount={closingComAmount}
        comChk={closingComChk}
        firstOfficerPrice={closingFirstOfferPrice}
        netCom={closingNetCom}
        offerAcceptedPrice={closingOfferAcceptedPrice}
        other1={closingOther1}
        other2={closingOther2}
        other3={closingOther3}
        totalLess={closingTotalLess}
        setBrokerSplit={setClosingBrokerSplit}
        setClosedPrice={setClosingClosedPrice}
        setComAmount={setClosingComAmount}
        setComChk={setClosingComChk}
        setFirstOfficerPrice={setClosingFirstOfferPrice}
        setNetCom={setClosingNetCom}
        setOfferAcceptedPrice={setClosingOfferAcceptedPrice}
        setOther1={setClosingOther1}
        setOther2={setClosingOther2}
        setOther3={setClosingOther3}
        setTotalLess={setClosingTotalLess}
      />
      <UploadTablePerTrans
        // type='write'
        rowKey={writeRowKey}
        setCustomDocs={setCustomDocs}
        customDocs={customDocs}
        setUploadTable={setUploadTable}
        uploadTable={uploadTable} />
        {/* <FileUploadTable
        newRows={newRows}
        onAddRow={onAddRow}
        tableRows={uploadTable}
        /> */}
      <div style={{ position: 'fixed', bottom: 16, right: 16, display: "flex", gap: "16px" }}>
        <Fab color='info' variant='extended'
          onClick={onSave}
          sx={{
            width: "120px",
            gap: "8px"
          }}>
          <IoMdSave size={20} />
          SAVE
        </Fab> <Fab onClick={() => {

          const isSubmit = window.confirm("Do you want to submit?.")
          if (isSubmit) {
            onSubmit()
          } else {
            return
          }
        }} color='primary' variant='extended' sx={{
          width: "120px",
          gap: "8px"
        }}>
          <MdUploadFile size={20}

          />
          SUBMIT
        </Fab>
        {/* <Fab color='error' variant='extended' sx={{
            width:"120px",
            gap:"8px"}}>
            <MdDelete size={20} />
            DELETE
          </Fab> */}
      </div>

      <div style={{ height: "64px" }} >

        {/* <Button variant='contained' onClick={onSave}>save</Button>
        <Button variant='contained' onClick={
          () => {

            const isSubmit = window.confirm("Do you want to submit?.")
            if (isSubmit) {
              onSubmit()
            } else {
              return
            }
          }
        }>submit</Button>
        <Button variant='contained'>delete</Button> */}

      </div>

    </div>
  );
}

export default WriteContent;