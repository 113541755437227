import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, TableHead, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { transactionTypeOptionsState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { collection, doc, getDoc, orderBy } from 'firebase/firestore';
import { db } from '../service/firebase';
import UserAccountRow from './UserAccountRow';
import AdminTableLoader from './AdminTableLoader';
import { basicTableHeight, wideTableCellWidth,sxTableCellWidth,mdTableCellWidth } from '../const/const';



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function createData(bdNum, address, transType,submit,finish) {
  return { bdNum, address, transType,submit,finish };
}


export default function CustomPaginationActionsTable({tableDatas, searchClick,closingYear, bdNumList,citySearchList, addressList,
  transTypeList}) {
 
  // state 취득
  // const location = useLocation();  
const theme=useTheme()
  const location=useLocation()
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [dbtableLength,setDbTableLength]=React.useState(0)
  const [sortTable,setSortTable]=React.useState([])
  const [filteredRow, setFilteredRow] = React.useState([])
  const [transTypeOptions, setTransTypeOptions] = useRecoilState(transactionTypeOptionsState)
  const nowUserId= location.state.id
  const sortListByBdNum = (list) => {
    // return [...list].sort((a, b) => {
    //   const numA = parseInt(a.bdNum.replace(/BD0*/, ''), 10);
    //   const numB = parseInt(b.bdNum.replace(/BD0*/, ''), 10);
    //   return numB- numA ;
    // });
    return list
  };
 
  const filterRows = (originRows) => {
    //     if (!isFilter) {
    //       return originRows;
    //     }
    // else{
    return originRows.filter((row) => {
      const bdNumMatch =bdNumList.length===0?true: bdNumList.includes(row.bdNum);
      const equalClosingYear=closingYear.length===0?true:row.closingDetail.closingDate.includes(closingYear)
      const addressMatch =addressList.length===0?true: addressList.includes(row.newTransactionDetail.transAddress);
      const cityMatch =citySearchList.length===0?true: citySearchList.includes(row.newTransactionDetail.transCity);
      const transTypeMatch = transTypeList.length===0?true:transTypeList.includes(transTypeOptions[row.newTransactionDetail.transType]);
      const res=bdNumMatch && addressMatch &&cityMatch&& transTypeMatch&&equalClosingYear;
      
      return res;
    });

  };
  useEffect(()=>{
    setFilteredRow(filterRows(sortListByBdNum(tableDatas)))
  },[tableDatas])
  useEffect(() => {
    if (tableDatas === undefined || tableDatas.length === 0 || tableDatas === null) return
      setFilteredRow(filterRows(sortListByBdNum(tableDatas)))


  }, [searchClick])

 
 
  return (
    filteredRow===null||filteredRow===undefined
    // ||Object.values(tableDatas).length===0?
    ?
    <TableContainer 
    variant='outlined'
    
    component={Paper} sx={{
      height:basicTableHeight,
      width:"100%",
    }}>
      
  Loading...
    </TableContainer>:<TableContainer 
    variant='outlined'
    
    component={Paper} sx={{
    
      height:basicTableHeight,
      width:"100%"
    }}>
      
 
     <Table 
     size='small'
     stickyHeader
     
     sx={{ 
     
      minWidth: 500 ,}} aria-label="custom pagination table">
       <TableHead >
       <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Number
              </TableCell>
              <TableCell style={{ width: "130px" }} scope="row" >
                BD Number
              </TableCell>
              <TableCell style={{ width: "180px" }} scope="row" >
                Closing Date
              </TableCell>
              <TableCell style={{ width: "1100px" }} scope="row" >
                Address
              </TableCell><TableCell style={{ width: "300px" }} scope="row" >
                City
              </TableCell>
              <TableCell style={{ width: "425px" }} scope="row" >
                Transaction Type
              </TableCell>
              <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Submit
              </TableCell>
              <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Finish
              </TableCell>
              {/* <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Content
              </TableCell>
              <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Documents
              </TableCell> */}
       </TableHead>
       
    
        <TableBody>
      {
          filteredRow
          .map((row,index) =>{
            return  (
           <UserAccountRow row={row} index={index} transTypeOptions={transTypeOptions} length={filteredRow.length}/>
          )})
    }   
        </TableBody>
        <TableFooter >
          <TableRow
          >

          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
