import { TableCell, TableRow, TextField, colors, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularWithValueLabel from './CircularProgressWithLabel';
import UploadDialog from './UploadDialog';
import UserDocDialog from './UserDocDialog';
import IOSSwitch from './IOSSwitch';
import { MdCheckCircle } from "react-icons/md";
function DocRowForWrite({
  
  up,upIdx,rowId,setUploadTable,uploadTable,fileKey}) {
    const theme=useTheme()
    const [fileUrls, setFileUrls] =useState([]);
    const [applicable,setApplicable]=useState(false)
    useEffect(()=>{
        const updatedUploadTable = [...uploadTable];
setApplicable(updatedUploadTable[upIdx].applicable)

    },[])
    const handleNameChange = (upIdx, e) => {
        const newName=e.target.value
        // 해당 인덱스의 업로드 테이블 항목 복사
        const updatedUploadTable = [...uploadTable];
        // 새로운 이름 할당
        updatedUploadTable[upIdx].name = newName===""?" ":newName;
        // 업데이트된 업로드 테이블로 상태 설정
        setUploadTable(updatedUploadTable);
      };
      const handleSrcChange = (upIdx, e) => {
        const newSrc=e.target.value
        // 해당 인덱스의 업로드 테이블 항목 복사
        const updatedUploadTable = [...uploadTable];
        // 새로운 이름 할당
        updatedUploadTable[upIdx].source = newSrc===""?" ":newSrc;
        // 업데이트된 업로드 테이블로 상태 설정
        setUploadTable(updatedUploadTable);
      };
      const handleApplicable=()=>{

        setApplicable(p=>!p)
   
      }
      useEffect(()=>{
        const updatedUploadTable = [...uploadTable];
        // 새로운 이름 할당
        updatedUploadTable[upIdx].applicable = applicable;
        // 업데이트된 업로드 테이블로 상태 설정
        setUploadTable(updatedUploadTable);
        if (!applicable) {
            
        }
      },[applicable])
      // const obtainFileKey=Object.keys(up).includes("uploadFiles")
      // console.log("obtainFileKey>>>",obtainFileKey,up)
    return (
        <TableRow
        sx={{
          width:"100%"
        }}
        >
          {/* <TableCell>{upIdx+1}</TableCell> */}
          <TableCell sx={{width:"800px"}}>
            <TextField
            sx={{
              width:"100%"
            }}
            size='small'
            disabled={up.custom===false||up.custom===undefined}
            value={up.name}
            onChange={(e)=>handleNameChange(upIdx,e)}
            /></TableCell>
          <TableCell sx={{width:"300px"}}>
          <TextField
          sx={{
            width:"300px"
          }}
            size='small'
            disabled={up.custom===false||up.custom===undefined}
            value={up.source}
            onChange={(e)=>handleSrcChange(upIdx,e)}
            /></TableCell>
          <TableCell >
          {/* up.uploadFiles 에서 filenameList로 수정함,, write페이지에서도 수정 할 것 */}
           <MdCheckCircle size={30} color={
            up["uploadFiles"].filter(file=>file.name!="").length!=0?theme.palette.success.main:theme.palette.grey[500]}/>
            {/* <CircularWithValueLabel  value={up.uploadFiles.filter(file=>file!="").length} /> */}
            </TableCell>
             {/* 파일 안올려도 되게하는 스위치 */}
       <TableCell>
         <IOSSwitch 
         defaultChecked
         id={upIdx} 
         checked={applicable}
         onChange={handleApplicable}/>
        </TableCell>
          <TableCell >
            <UploadDialog 
            setFileUrls={setFileUrls}
            applicable={applicable}
            rowKey={rowId}
          setUploadTable={setUploadTable}
          uploadFiveFiles={up["uploadFiles"]} upIdx={upIdx} docName={up.name} docSrc={up.source}/></TableCell>
 <TableCell>
<UserDocDialog 
fileUrls={fileUrls}
setFileUrls={setFileUrls}
uploadFiles={up.uploadFiles}
rowId={rowId} docName={up.name} docSrc={up.source} 
thumbnails={up.thumbnails}
 filenameList={up.filenameList} upIdx={upIdx}/>
          </TableCell>
        </TableRow>
    );
}

export default DocRowForWrite;