import { Button, Card, CardContent, CssBaseline, Divider, IconButton, InputAdornment, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../App.css"
import TxtfieldRow from '../components/TxtfieldRow';
import { useRecoilState } from 'recoil';
import { themeAtom, userIdState } from '../atom/atom';
import { db } from '../service/firebase';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import NoRequiredAlertDialog from '../components/NoRequiredAlertDialog';
import { IoIosArrowForward } from "react-icons/io";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ThemeToggleBtn from '../components/ThemeToggleBtn';
import { getDesignTokens, wrongPwMsg } from '../const/const';
function LoginPage(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [id, setId] = useState("")
  const [pw, setPw] = useState("")
  const [nowUserId, setNowUserId] = useState("")
  const [openAlert, setOpenAlert] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [mode, setMode] = useRecoilState(themeAtom);

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])
  //id textfield
  const onChangeId = (e) => {
    setId(e.target.value)
  }
  //pw textfield
  const onChangePw = (e) => {
    setPw(e.target.value)
  }

  const onLogin = async () => {
    //파베 유저디비에서 유저아이디랑 비번 맞춰보고 맞으면 
    // userIdState 에 넣기
    if (id.trim() === "" || pw.trim() === "") {
      const res = window.confirm("The username or password field is blank.")
    } else {
      const userCollection = collection(db, "userInfos")

      const docRef = doc(userCollection, id === "" ? "없음" : id)
      const data = await getDoc(docRef)

      if (data.exists()) {
        const userData = data.data()
        if (userData["userType"] !== "admin" && userData["approve"] === false) {
          setOpenAlert(true)
        } else {

          if (userData["pw"] === pw) {
            // setNowUserId(data["id"])
            //로그인 성공 후 세션에 사용자 정보 저장
            sessionStorage.setItem('userLoggedIn', 'true')
            localStorage.setItem('nowUserIdInLocal', data["id"])
            localStorage.setItem('nowUserPwInLocal', userData["pw"])
            localStorage.setItem('nowUserTypeInLocal', userData["userType"])
            localStorage.setItem('nowSplitClassInLocal', userData["splitClass"])
            // 로그인 성공 후 로그인 페이지를 히스토리에서 제거
            window.history.replaceState(null, '', '/')
            //로그인 성공 후 원하는 페이지로 리다이렉션
            const path = userData["userType"] === "user" ? "/fileupload/table" : "/fileupload/review"
            navigate(path, {
              state: { id: data["id"], userType: userData["userType"], row: null }
            })
          } else {
            const res = window.confirm(wrongPwMsg)
          }
        }


        //   return;
      } else {
        const res = window.confirm(wrongPwMsg)
      }
    }


  }


  const navigateSignUpPage = () => {
    navigate("/fileupload/signup")
  }
  const alertClose = () => {
    setOpenAlert(false)
  }
  const handleClickShowPassword = () => {
    setShowPassword(true)
  }
  const handleMouseDownPassword = () => {
    setShowPassword(false)
  }

  useEffect(() => {
    // 이미 로그인한 사용자가 로그인 페이지에 접근하면 바로 대시보드로 이동
    if (sessionStorage.getItem('userLoggedIn') === 'true') {
      if (localStorage.getItem("nowUserTypeInLocal") === "user") {
        navigate('/fileupload/table', {
          state: { id: localStorage.getItem("nowUserIdInLocal"), userType: localStorage.getItem("nowUserTypeInLocal"), row: null }
        });
      } else {
        navigate('/fileupload/user-setting', {
          state: { id: localStorage.getItem("nowUserIdInLocal"), userType: localStorage.getItem("nowUserTypeInLocal"), row: null }
        });
      }

    }
  }, [navigate]);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NoRequiredAlertDialog
          msg={"This account has not been approved by the admin."}
          open={openAlert} handleClose={alertClose} />
        <Card
          variant='outlined'
          sx={{
            width: "330px",
            height: "320px",
            display: 'flex',
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >

          <CardContent

            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px"
            }}
          > <Typography variant="h6" component="div" sx={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  BD file management
                </Typography>
            <div
              style={{
                display: "flex",
                flexDirection:"row",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <ThemeToggleBtn disable={true} />
             
                <Typography variant="h5" component="div" sx={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  LOGIN
                </Typography>
              <ThemeToggleBtn toggleColorMode={colorMode.toggleColorMode} /></div>


            <TextField
              size='small'
              placeholder='ID'
              sx={{
                width: "100%"
              }}
              value={id}
              onChange={onChangeId}
            />
            {/* pw */}

            <TextField
              size='small'
              placeholder='PW'
              sx={{ width: "100%" }}
              value={pw}
              onChange={onChangePw}
              type={showPassword ? "text" : "password"}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button variant='contained' sx={{ width: "100%" }}
              onClick={onLogin}>login</Button>
            <div style={{
              display: "flex"
            }}><Button
              color='secondary'
              sx={{ width: "100%" }}
              onClick={navigateSignUpPage}
            >go sign up<IoIosArrowForward size={20} /></Button>

              {/* <Button 
                color='error'
                sx={{width:"100%"}}
                onClick={navigateSignUpPage}
                >FORGET PW<IoIosArrowForward size={20}/></Button> */}
            </div>


            {/* </div> */}</CardContent>


        </Card></ThemeProvider></div>



  );
}

export default LoginPage;