import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NewTransactionDetailContent from './NewTransactionDetailContent';
import SellerAgentInfo from './SellerAgentInfo';
import BuyerAgentInfo from './BuyerAgentInfo';
import EscrowInfo from './EscrowInfo';
import LenderInfo from './LenderInfo';
import CustomTable from '../components/CustomTable';
import ClosingDetail from './ClosingDetail';
import { Button, Fab } from '@mui/material';
import { collection, deleteField, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db, storage } from '../service/firebase';
import uuid from 'react-uuid';
import { bdFormat, getCalcedBrokerSplit } from '../const/const';
import { useRecoilState } from 'recoil';
import { filesState, loadingPercentAtom, mainLoadingAtom, temp5FileState, transactionTypeOptionsState } from '../atom/atom';
import NoRequiredAlertDialog from '../components/NoRequiredAlertDialog';
import { deleteObject, getDownloadURL, getStorage, list, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment';
import UploadTableForEdit from '../components/UploadTableForEdit';
import LoadingPage from '../components/LoadingPage';
import { MdUploadFile,MdDelete } from "react-icons/md";
import { IoMdSave } from 'react-icons/io';
import UploadTable from '../components/UploadTable';
import UploadTablePerTrans from '../components/UploadTablePerTrans';
import TestComp from './TestComp';
function EditContent() {
    const {index}=useParams()
    const location=useLocation()
    // const [isLease, setIsLease] = useState(false)
    //New Transaction Detail
    const [transAddress,setTransAddress]=useState("")
    const [transCity,setTransCity]=useState("")
  const [transType,setTransType]=useState(0)
  const [transClientName,setTransClientName]=useState("")
  const [transPropertyAddress,setTransPropertyAddress]=useState("")
  const [transDate,setTransDate]=useState("")
  const [transNmlsNum,setTransNmlsNum]=useState("")
//Seller Agent Information
const [sellerBrokerName,setSellerBrokerName]=useState("")
const [sellerAgentName,setSellerAgentName]=useState("")
const [sellerPhone,setSellerPhone]=useState("")
const [sellerEmail,setSellerEmail]=useState("")
const [sellerName,setSellerName]=useState("")
//Buyer Agent Information
const [buyerBrokerName,setBuyerBrokerName]=useState("")
const [buyerAgentName,setBuyerAgentName]=useState("")
const [buyerPhone,setBuyerPhone]=useState("")
const [buyerEmail,setBuyerEmail]=useState("")
const [buyerName,setBuyerName]=useState("")
//Escrow Information
// const m = moment().format('YYYY-MM-DD');
const [escrowOpenDate,setEscrowOpenDate]=useState("")
const [escrowCName,setEscrowCName]=useState("")
const [escrowNum,setEscrowNum]=useState("")
const [escrowOfficerName,setEscrowOfficerName]=useState("")
const [escrowPhoneNum,setEscrowPhoneNum]=useState("")
const [escrowEmail,setEscrowEmail]=useState("")
//Lender Information
const [lenderName,setLenderName]=useState("")
const [lenderLoanOriginatorName,setLenderLoanOriginatorName]=useState("")
const [lenderPhoneNum,setLenderPhoneNum]=useState("")
const [lenderEmail,setLenderEmail]=useState("")
//Closing Detail
const [closingDate,setClosingDate]=useState("")
const [closingFirstOfferPrice,setClosingFirstOfferPrice]=useState("")
const [closingOfferAcceptedPrice,setClosingOfferAcceptedPrice]=useState("")
const [closingClosedPrice,setClosingClosedPrice]=useState("")
const [closingComAmount,setClosingComAmount]=useState("")
const [closingComChk,setClosingComChk]=useState("0")
const [closingBrokerSplit,setClosingBrokerSplit]=useState("")
const [closingOther1,setClosingOther1]=useState("")
const [closingOther2,setClosingOther2]=useState("")
const [closingOther3,setClosingOther3]=useState("")
const [closingTotalLess,setClosingTotalLess]=useState("")
const [closingNetCom,setClosingNetCom]=useState("")
const [closingENOinsurance, setClosingENOinsurance] = useState("")
//transaction type 선택지 옵션
const [transTypeOptions,setTransTypeOptions]=useRecoilState(transactionTypeOptionsState)
//업로드테이블에 쓸 거
const [newUploadTable,setNewUploadTable]=useState(null)
const [uploadTable,setUploadTable]=useState(null)
const [uploadTable2,setUploadTable2]=useState(null)
// const [files,setFiles]=React.useState(Array.from({ length: 5 }, (_, index) => ({ key: index, name: '' })))
const today = new Date()
const [todayFullDate,setTodayFullDate]=useState('')
const [isLease, setIsLease] = useState(false)
const transTypeCollection = collection(db, "options")
const tableRowsCollection = collection(db, "documentsForTransaction")
const dataCollection = collection(db, "pjDatas")

//테이블 rows
const [tableRows,setTableRows]=useState(null)
const [filesAtom,setFilesAtom]=useRecoilState(filesState)
const [noSubmitAlert,setNoSubmitAlert]=useState(false)
const [temp5Files,setTemp5Files]=useRecoilState(temp5FileState)
// const [typeChangeNum,setTypeChangeNum]=useState(0)
const [firstType,setFirstType]=useState(0)
const  [newUploadFileList,setNewUploadFileList]=useState([])
// const [saveLoading,setSaveLoading]=useState(false)
const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
//퍼센테이지 저장
const [loadingPercent,setLoadingPercent]=useRecoilState(loadingPercentAtom)
const disabled=location.state.row["finish"]===true//&&location.state.userType==="user"?true:false
const [customDocs,setCustomDocs]=useState([])
const navigate=useNavigate()

// const getFilesInit=async()=> {
//   //transactionType당 업로드 파일 5개씩 할 수 있는데, 
//   //트랜잭션 타입 당 업로드 파일 5개 로우 매핑시키는 것
//   const originRowKey=location.state.rowKey;
//   const newRowKey=uuid();
//   const userId = location.state.id;
//   const objKey = originRowKey===undefined?newRowKey:originRowKey;
//   const docRef = doc(dataCollection, userId);

//   try {
//     const editList = await getDoc(docRef);

//     if (editList.exists()) {
//       //edit하는경우와 write하는 경우를 분기
//       //아래가 edit / write로 분기한거
//       //로우 키가 안들어왔으니까, write 페이지
//       if (originRowKey===undefined) {
//         //transactionOption 얻는 과정
//         const ttCollection = collection(db, "options");
//         const ttRef=doc(ttCollection,"transactionType")
//         const ttOptions=await getDoc(ttRef)
//         if (ttOptions.exists()) {
        
//           const ttOptionsData=ttOptions.data()
//             const selTtOption=ttOptionsData["selectList"][transType]
//            const docsCollection = collection(db, "documentsForTransaction");
//         const docRef = doc(docsCollection, selTtOption);
//         const docSnapshot = await getDoc(docRef);
//         if (docSnapshot.exists()) {
//           //도큐멘트 정보들 있음
//         const docData=docSnapshot.data()["documents"]
//         const modifiedDocData=docData.map((item,idx)=>
//         {
//           const newFilesArray=Array.from({length:5},(_,i)=>(
//             {
//               key:i,
//               name:"",
//               source:"",
//               fileInfo:""
//             }
//           ))
//           return {...item,files:newFilesArray}
//         }
//         )
//         setFilesAtom(modifiedDocData);
//       } else {
//         }

    
//         } else {
//         }
       
       
      
//       } 
      
//       //네비게이트로 전달받은 키가 있는 경우니까 edit
//       else {    
//        const nowUserType=location.state.userType;
//        if (nowUserType==="admin") {
//          const nowRow=location.state.row
//          setFilesAtom(nowRow["tablePerTransaction"]);
//        } else {
//          const data = editList.data();
//        const res = data[objKey]["tablePerTransaction"];
//         setFilesAtom(res);
//        }
       
        
//       }
     
//     } else {
//       //아예 유저별 데이터가 없는 경우
//       return false;
//     }
//   } catch (error) {
//     // Handle any potential errors during the asynchronous operations
//     return false;
//   }
// }
//transType options 파베에서 가지고 오기
async function getTransTypeOptions() {
  const docRef = doc(transTypeCollection, "transactionType")
    const transData = await getDoc(docRef)
    
    if (transData.exists()) {
      setTransTypeOptions(transData.data()["selectList"])
     

    } else {
    }
  }
const getTableRows=async ()=> {
  const selTransc=transTypeOptions[transType]
  const docsCollection = collection(db, "documentsForTransaction");
  const docRef = doc(docsCollection, selTransc);
  const docData = await getDoc(docRef)
    if (docData.exists()) {
      const res=docData.data()["documents"]
     setTableRows(res)
    //  setFilesAtom(docData.data()["documents"].map(tr=>{
     
      
    //   return {
    //   name:tr["name"],
    //   // source:tr["source"],
    //   files:Array.from({ length: 5 }, (_, index) => ({ key: index, name: '' }))
    // }}))
  //  setTypeChangeNum(p=>p++)
    return res
    } else {
      setTableRows(null)
      return null
    }
  }
  const getEditTableRows=async (newType)=> {
   
    const selTransc=transTypeOptions[newType]
     const docsCollection = collection(db, "documentsForTransaction");

    // if (!selTransc) {
      const docRef = doc(docsCollection, selTransc);
    const docData = await getDoc(docRef)
      if (docData.exists()) {
        const res=docData.data()["documents"]
       setTableRows(res)
      //  setFilesAtom(docData.data()["documents"].map(tr=>{
       
        
      //   return {
      //   name:tr["name"],
      //   // source:tr["source"],
      //   files:Array.from({ length: 5 }, (_, index) => ({ key: index, name: '' }))
      // }}))
    //  setTypeChangeNum(p=>p++)
      return res
      } else {
        setTableRows([])
        return []
      }
    // }
    
    }
// 파일 업로드 함수
const uploadFileToDB = async (nowRowKey, fileList
  // , uploadTableForUi
) => {

  const storagePath = (docName, docSrc, originIdx, fileName) =>
    `${nowRowKey}/${docName}/${docSrc}/${fileName}`;


  const copyArr = [...fileList];
  let uiFileList = [];
  let thumbnails=[]
  for (let i = 0; i < copyArr.length; i++) {
    // uiFileList = uploadTableForUi[i].filenameList;//이걸로 파일이름체크?
    // thumbnails=uploadTableForUi[i].thumbnails
    const uploadFiles = copyArr[i].uploadFiles;
    const docName = copyArr[i].name;
    const docSrc = copyArr[i].source;
   
    // const custom=uploadTableForUi[i].custom;
    // const applicable=uploadTableForUi[i].applicable;
    const originIdx = copyArr[i].originIdx;
    for (let j = 0; j < uploadFiles.length; j++) {
      const uploadFile = uploadFiles[j];
      const fileName = uploadFile.name === undefined ? "" : uploadFile.name;
      const fileURL =
        fileName === ""
          ? ""
          : await uploadFileToStorage(
              uploadFile,
              storagePath(docName, docSrc, originIdx, fileName)
            );
    }
    copyArr[i] = { name: docName, source: docSrc, uploadFiles: uploadFiles.map(up=>up.name) ,thumbnails:copyArr[i].thumbnails,custom:copyArr[i].custom,applicable:copyArr[i].applicable};
  }
  // const newArr = uploadTableForUi.map((prev) => {
  //   const newObj = { name: prev.name, source: prev.source,custom:false,applicable:true, uploadFiles: Array(5).fill("") ,thumbnails:Array(5).fill("")};
  //   return newObj;
  // });
  // setNewUploadFileList(newArr);
  return copyArr;
};

const deleteNonExistingFiles = async (nowRowKey, fileList, uploadTableForUi) => {
  for (let i = 0; i < uploadTableForUi.length; i++) {
    const docName = uploadTableForUi[i].name;
    const docSrc = uploadTableForUi[i].source;
    // const originIdx=uploadTableForUi[i].originIdx
    const existingFiles = uploadTableForUi[i].filenameList
    
    // 기존 파일 중에서 fileList에 있는 파일은 유지하고 나머지 파일은 삭제
    await deleteFilesExcept(nowRowKey, docName, docSrc, existingFiles, fileList[i].uploadFiles);
  }
};



// 나머지 
//스토리지에 저장한 다음, url을 가지고 오는 방식이다.
const uploadFileToStorage = async (file, path) => {
  const storageRef = ref(storage, path);
  const uploadTask = uploadBytesResumable(storageRef, file);

  // 업로드 진행률 이벤트 리스너 추가
  uploadTask.on('state_changed',
  (snapshot) => {
    // 업로드 진행 중일 때 호출되는 콜백
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + Math.ceil(progress) + '% done');
    const nowPercent=file.name+' is ' + Math.ceil(progress) + '% done';
    setLoadingPercent(nowPercent)
  },
  (error) => {
    // 업로드 중 오류가 발생했을 때 호출되는 콜백
    console.error('Error uploading file: ', error);
  },
  () => {
    // 업로드가 완료되었을 때 호출되는 콜백
    console.log('Upload complete');
   
  }
);
await uploadTask;
 setLoadingPercent(null)

  // 파일 업로드 후 다운로드 URL 가져오기
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

//write페이지에 있던 dbSave
const dbSave = async (formState) => {
  try {
    setMainLoading(true)
    const docNewKey =location.state.rowKey// uuid()
    const nowUserId = location.state.id
    const docRef = doc(dataCollection, nowUserId)
    const docPerId = await getDoc(docRef)
    //bdnum 전체 트랜잭션 따지기
    const bdDatas = await getDocs(dataCollection)
    // const allBdDatas = bdDatas.docs.map(d => Object.values(d.data())).flat()
    const filtered = bdDatas.docs.filter(v => Object.entries(v.data()).filter(([key]) => key != 'ins' && key != 'ann')).map(d => Object.values(d.data())).flat().flat().filter(v => v.hasOwnProperty("bdNum"))
    const allBdDatas = bdDatas.docs.map(d => Object.values(d.data())).flat()
    if (docPerId.data() === null || docPerId.data() === undefined) return;

    // const aanewTable = await uploadFileToDB(docNewKey,uploadTable);
    const formattedUploadTable=uploadTable.map(up=>{
      const emptyThumbnails=["","","","",""]
      const fileNameList=up.uploadFiles.map(file=>file.name===undefined?"":file.name)
      const newObj={...up,thumbnails:emptyThumbnails, uploadFiles:fileNameList}
      return newObj
    })
    const docLength = filtered.length + 1

    const updateObject = {};
    const newTransJson = {
      transType: transType,
      transAddress: transAddress,
      transCity: transCity,
      clientName: transClientName,
      date: todayFullDate,
      nmlsNum: transNmlsNum,
    }
    const newSellerJson = {
      brokerName: sellerBrokerName,
      agentName: sellerAgentName,
      phone: sellerPhone,
      email: sellerEmail,
      name: sellerName
    }
    const newBuyerJson = {
      brokerName: buyerBrokerName,
      agentName: buyerAgentName,
      phone: buyerPhone,
      email: buyerEmail,
      name: buyerName
    }
    const newEscrowJson = {
      escrowOpenDate: escrowOpenDate,
      cName: escrowCName,
      num: escrowNum,
      officerName: escrowOfficerName,
      phone: escrowPhoneNum,
      email: escrowEmail,
    }
    const newLenderJson = {
      name: lenderName,
      loanOriginatorName: lenderLoanOriginatorName,
      phone: lenderPhoneNum,
      email: lenderEmail
    }
    const newClosingJson = {
      closingDate: closingDate,
      firstOfferPrice: closingFirstOfferPrice,
      offerAcceptedPrice: closingOfferAcceptedPrice,
      closedPrice: closingClosedPrice,
      comAmount: closingComAmount,
      comChk: closingComChk,
      brokerSplit: closingBrokerSplit,
      enoInsurance:closingENOinsurance,
      other1: closingOther1,
      other2: closingOther2,
      other3: closingOther3,
      totalLess: closingTotalLess,
      netCom: closingNetCom
    }
const editBdNum=sessionStorage.getItem('editBdNum');
    updateObject[`${docNewKey}`] = {
      userId: nowUserId,
      id: docNewKey,
      writeDate: todayFullDate,
      dateTime: new Date().toString(),
      bdNum: editBdNum,
      newTransactionDetail: newTransJson,
      tablePerTransaction:formattedUploadTable,// aanewTable,
      customDocs: [],
      sellerAgentInfo: newSellerJson,
      buyerAgentInfo: newBuyerJson,
      escrowInfo: newEscrowJson,
      lenderInfo: newLenderJson,
      closingDetail: newClosingJson,
      formState: formState
    };
localStorage.setItem("isSaved","true")
    updateDoc(doc(dataCollection, nowUserId), updateObject);
    // navigate("/fileupload/table", {
    //   state: { id: userId, userType: userType, row: null }
    // })

  } catch (error) {
    window.confirm("error :", error)
    // console.log("db save error", error)
  } finally {
    // console.log("finally")
    const userId = location.state.id
    const userType = location.state.userType
    if (userType==="admin") {
      navigate("/fileupload/review", {
        state: { id: userId, userType: userType, row: null }
      })
    } else {
       navigate("/fileupload/table", {
      state: { id: userId, userType: userType, row: null }
    })
    }
   
    setMainLoading(false)
  }

}
const  onDelete=async()=> {
  const updateObject = {};
  const rowKey=location.state.rowKey
  const nowUserId=location.state.id
  const nowUserType=location.state.userType
  updateObject[rowKey] = deleteField();

  await updateDoc(doc(dataCollection, nowUserId), updateObject);
if (nowUserType==="admin") {
  navigate("/fileupload/review",{
    state:{id:nowUserId,userType:nowUserType,row:null}
  })
} else {
  
  navigate("/fileupload/table",{
    state:{id:nowUserId,userType:nowUserType,row:null}
  })
}



}
const onSave=async()=>{
//save 누르면 디비에 저장되고, 
//submit:false라는것을 하나 넣기
//onSubmit은  submit:true 하나 넣기
await dbSave("save")
}
const onSubmit=async(uploadTable)=>{

  const submitCondition=
  //trans~~
  transClientName!==""&&
  transCity!=""&&
  transAddress!=""&&
  transNmlsNum!=""&&
  //seller~~
  sellerBrokerName!=""&&
sellerAgentName!=""&&
sellerPhone!=""&&
sellerEmail!=""&&
sellerName!=""&&
//buyer~~
buyerBrokerName!=""&&
buyerAgentName!=""&&
buyerPhone!=""&&
buyerEmail!=""&&
buyerName!=""&&
//escrow~~
escrowCName!=""&&
escrowNum!=""&&
escrowOfficerName!=""&&
escrowPhoneNum!=""&&
escrowEmail!=""&&
//lender 제외
//closing~~
closingFirstOfferPrice.toString()!=""&&
closingOfferAcceptedPrice.toString()!=""&&
closingClosedPrice.toString()!=""&&
closingComAmount.toString()!=""&&
closingComChk.toString()!=""&&
closingBrokerSplit.toString()!=""&&
closingTotalLess.toString()!=""&&
closingNetCom.toString()!=""
if (submitCondition) {
    //전역으로 files 변수 써서, submit하고 나서 비워주기
//테이블에 로우 추가
const isNotUploaded=uploadTable.filter(up=>up.applicable&&up.uploadFiles.filter(fn=>fn.name!="").length===0).length!=0
// console.log("is not upload>>>",uploadTable)
// if (isNotUploaded) {
//       window.confirm("Please upload all the required files before submitting.")
//     return;
// } else {
  await dbSave("submit")
// }
// for (let i = 0; i < uploadTable2.length; i++) {
//   const up = uploadTable2[i];

//   const applicable=up.applicable
//   const filenameLength=up.filenameList.filter(fn=>fn!="").length
//   const isNotApplicable=filenameLength===0||applicable===false
//   if (isNotApplicable) {
//     console.log("상태>>>",i,applicable,filenameLength)
//     window.confirm("Please upload all the required files before submitting.")
//     return;
//   } else {
//     await dbSave("submit")
  
//   }
// }


} else {
  setNoSubmitAlert(true)
}


}
////onSave/뒤로가기 할 때, 미리 디비에 올려놓은 파일중에 save안된것들 검토해서 삭제하기
const deleteFilesExcept = async (nowRowKey, docName, docSrc, existingFiles, filesToKeep) => {
  const storageRef = ref(storage, `${nowRowKey}/${docName}/${docSrc}`);

  try {
    const fileList = await list(storageRef);
    fileList.items.forEach(async (item) => {
      // const fullPath = item.fullPath;
      if (!existingFiles.includes(item.name)) {
        await deleteObject(item);
      }
    });
  } catch (error) {
  }
};


useEffect(() => {
//   const handleBack = async() => {
//     // 뒤로가기 버튼이 클릭되었을 때 실행할 코드 작성
//     // console.log('뒤로가기 버튼이 클릭되었습니다.',location.state.rowKey);
//     // window.confirm("뒤로가시겠습니까?")
//     navigate("/fileupload/login")
// //  await   deleteFilesExcept(location.state.rowKey)
//   };

//   window.addEventListener('popstate', handleBack);

//   return () => {
//     window.removeEventListener('popstate', handleBack); // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
//   };
if (location.state.rowKey===undefined)return;

// deleteFilesExcept(location.state.rowKey)
}, []);
// console.log("edit 화면에서 테이블 in useEffect>>>",location.state.row)
useEffect(()=>{
   getTransTypeOptions()
  //  getFilesInit()
//페이지 시작 할 때, 
//로컬에 rowKey에 해당하는 데이터가 있으면, 가져와서 넣기
//rowKey에 해당하는 데이터가 없으면
//페이지 시작 할 때 row bdnum에 맞춰서 데이터 가져오기
const rowDatas=location.state.row
// setUploadTable(rowDatas["tablePerTransaction"])
const formattedTableRows=rowDatas["tablePerTransaction"].map(d=>{
  
  const newUploadFiles=d.uploadFiles.map(up=>new File([],up))
  return {...d,uploadFiles:newUploadFiles}})
  // console.log("format rows>>>",formattedTableRows)

setUploadTable(formattedTableRows)
// setNewUploadTable(rowDatas["tablePerTransaction"])
// setUploadTable2(rowDatas["tablePerTransaction"])
//trans
setTransAddress(rowDatas["newTransactionDetail"]["transAddress"])
setTransCity(rowDatas["newTransactionDetail"]["transCity"])
setTransType(rowDatas["newTransactionDetail"]["transType"].toString())
setFirstType(parseInt(rowDatas["newTransactionDetail"]["transType"]))
setTransClientName(rowDatas["newTransactionDetail"]["clientName"])
setTransPropertyAddress(rowDatas["newTransactionDetail"]["propertyAddress"])
setTransNmlsNum(rowDatas["newTransactionDetail"]["nmlsNum"])
setTodayFullDate(rowDatas["newTransactionDetail"]["date"])
//seller
setSellerBrokerName(rowDatas["sellerAgentInfo"]["brokerName"])
setSellerAgentName(rowDatas["sellerAgentInfo"]["agentName"])
setSellerPhone(rowDatas["sellerAgentInfo"]["phone"])
setSellerEmail(rowDatas["sellerAgentInfo"]["email"])
setSellerName(rowDatas["sellerAgentInfo"]["name"])
//buyer
setBuyerBrokerName(rowDatas["buyerAgentInfo"]["brokerName"])
setBuyerAgentName(rowDatas["buyerAgentInfo"]["agentName"])
setBuyerPhone(rowDatas["buyerAgentInfo"]["phone"])
setBuyerEmail(rowDatas["buyerAgentInfo"]["email"])
setBuyerName(rowDatas["buyerAgentInfo"]["name"])
//escrow
setEscrowOpenDate(rowDatas["escrowInfo"]["escrowOpenDate"])
setEscrowCName(rowDatas["escrowInfo"]["cName"])
setEscrowNum(rowDatas["escrowInfo"]["num"])
setEscrowOfficerName(rowDatas["escrowInfo"]["officerName"])
setEscrowPhoneNum(rowDatas["escrowInfo"]["phone"])
setEscrowEmail(rowDatas["escrowInfo"]["email"])
//lender
setLenderName(rowDatas["lenderInfo"]["name"])
setLenderLoanOriginatorName(rowDatas["lenderInfo"]["loanOriginatorName"])
setLenderPhoneNum(rowDatas["lenderInfo"]["phone"])
setLenderEmail(rowDatas["lenderInfo"]["email"])
//closing

setClosingENOinsurance(rowDatas["closingDetail"]["enoInsurance"])
setClosingDate(rowDatas["closingDetail"]["closingDate"])
setClosingFirstOfferPrice(rowDatas["closingDetail"]["firstOfferPrice"])
setClosingOfferAcceptedPrice(rowDatas["closingDetail"]["offerAcceptedPrice"])
setClosingClosedPrice(rowDatas["closingDetail"]["closedPrice"])
setClosingComAmount(rowDatas["closingDetail"]["comAmount"])
setClosingComChk(rowDatas["closingDetail"]["comChk"])
setClosingBrokerSplit(rowDatas["closingDetail"]["brokerSplit"])
setClosingOther1(rowDatas["closingDetail"]["other1"])
setClosingOther2(rowDatas["closingDetail"]["other2"])
setClosingOther3(rowDatas["closingDetail"]["other3"])
setClosingTotalLess(rowDatas["closingDetail"]["totalLess"])
setClosingNetCom(rowDatas["closingDetail"]["netCom"])


},[location.state.row])
useEffect(()=>{
  if (uploadTable===null)return
const checkList=uploadTable.filter(up=>
    up.uploadFiles.filter(file=>file.name!="").length!=0
  )
  localStorage.setItem("checkList",checkList)
},[uploadTable])
//트랜잭션 타입 바뀔 때마다 표 바뀜
useEffect(()=>{
  if (transTypeOptions===null)return;
  //처음 트랜잭션 타입 선택 안했을때는 uploadTable사용,
  //트랜잭션 타입 바꿨을 때는 uploadTable2사용 
  // if (transType!=firstType) {
     getTableRows().then(res=>{
   
      if (res===null)return
    const copyArr=[...res].filter(v=>v.source!=""&&v.name!="")
    // setUploadTable2(copyArr.map((c,cIdx)=>{
    //   const obj=
    //   {...c,
    //     custom:uploadTable[cIdx].custom,
    //     applicable:uploadTable[cIdx].applicable,
    //     filenameList:Array(5).fill(""),
      
    //   urlList:Array(5).fill(""),
    //   thumbnails:Array(5).fill("")
    // }
    //   return obj }
      
      
    //   ))
    // onChange로 옮기자
      // setUploadTable(copyArr.map((c,cIdx)=>{
      //   const obj=
      //   {
      //     ...c,
      //     // custom:uploadTable[cIdx].custom,
      //     // applicable:uploadTable[cIdx].applicable,
      //     filenameList:Array(5).fill(""),
        
      //   uploadFiles:c.uploadFiles.map(v=>new File([],v)),//Array(5).fill(new File([],"")),
      //   thumbnails:Array(5).fill("")
      // }
      //   return obj }
        
        
      //   ))
    
    })
  // } else {


  //   setUploadTable2(location.state.row["tablePerTransaction"])
  //   setUploadTable(location.state.row["tablePerTransaction"])
  // }
  //lease 주석
  // const isLease = transTypeOptions[transType].includes("Lease")
  // setIsLease(isLease)
  // if (isLease) {
  //   const mul=2;//현재 2로 고정
  //   const res=getCalcedBrokerSplit(transTypeOptions[transType],closingComChk,closingBrokerSplit,mul)
  //   const divided=parseFloat(res*0.1).toFixed(2)
  //   setClosingBrokerSplit(divided)
  // }
  // else{
  //   const mul=2;//현재 2로 고정
  //   const res=getCalcedBrokerSplit(transTypeOptions[transType],closingComChk,closingBrokerSplit,mul)
  //   setClosingBrokerSplit(res)
  // }
 
  const isLease = transTypeOptions[transType].includes("Lease")
  setIsLease(isLease)
},[transType,transTypeOptions])
const handleAlertClose=()=>{
  setNoSubmitAlert(false)
}
const onChangeEditTable=async(newType)=>{

 await getEditTableRows(newType).then(res=>{
   
    // if (res===null)return
    // if (res===undefined)return
  const copyArr=res.length===0?[]:[...res].filter(v=>v.source!=""&&v.name!="")
  
  // if (copyArr.length===0)return;
  // console.log("copyArr가 뭐지",copyArr)
  setUploadTable(copyArr.map((c,cIdx)=>{
    const obj=
    {
      ...c,
      // custom:uploadTable[cIdx].custom,
      // applicable:uploadTable[cIdx].applicable,
      filenameList:Array(5).fill(""),
    
    uploadFiles:Array(5).fill(new File([],"")),
    thumbnails:Array(5).fill("")
  }
    return obj }
    
    
    ))})
    const isLease = transTypeOptions[newType].includes("Lease")
    setIsLease(isLease)
    setClosingFirstOfferPrice("")
    setClosingOfferAcceptedPrice("")
    setClosingClosedPrice("")
    setClosingComAmount("")
    setClosingComChk("")
    setClosingBrokerSplit("")
    setClosingENOinsurance("")
    setClosingOther1("")
    setClosingOther2("")
    setClosingOther3("")
    setClosingTotalLess("")
    setClosingNetCom("")
}
console.log("edit page에서 is Lease",isLease)
return (
        <div className="ContentBox" style={{ display: "flex", flexDirection: "column" }}>
    
       <NoRequiredAlertDialog  open={noSubmitAlert}  msg={"Please fill in all information"} handleClose={handleAlertClose}/>
       
        <NewTransactionDetailContent 
        onChangeEditTable={onChangeEditTable}
        contentType='edit'
        disabled={disabled}
        transAddress={transAddress}
        transCity={transCity}
        transType={transType}
        transTypeOptions={transTypeOptions}
        clientName={transClientName}
        propertyAddress={transPropertyAddress}
        nmlsNum={transNmlsNum}
        todayFullDate={todayFullDate}
        setTransAddress={setTransAddress}
        setTransCity={setTransCity}
        setTransType={setTransType}
        setClientName={setTransClientName}
        setPropertyAddress={setTransPropertyAddress}
        setNmlsNum={setTransNmlsNum}
        setTodayFullDate={setTodayFullDate}
        />

       
        <SellerAgentInfo

        isLease={isLease}
         disabled={disabled}
        brokerName={sellerBrokerName}
        email={sellerEmail}
        phoneNum={sellerPhone}
        sellerAgentName={sellerAgentName}
        sellerName={sellerName}      
        setBrokerName={setSellerBrokerName}
        setEmail={setSellerEmail}
        setPhone={setSellerPhone}
        setAgentName={setSellerAgentName}
        setName={setSellerName}
  
        />
        <BuyerAgentInfo
        isLease={isLease}
         disabled={disabled}
        brokerName={buyerBrokerName}
        buyerAgentName={buyerAgentName}
        buyerName={buyerName}
        cellPhone={buyerPhone}
        email={buyerEmail}
        setBrokerName={setBuyerBrokerName}
        setBuyerAgentName={setBuyerAgentName}
        setBuyerName={setBuyerName}
        setPhone={setBuyerPhone}
        setEmail={setBuyerEmail}
        />
      
        <EscrowInfo

        escrowOpenDate={escrowOpenDate}
        setEscrowOpenDate={setEscrowOpenDate}
         disabled={disabled}
        companyName={escrowCName}
        email={escrowEmail}
        number={escrowNum}
        officerName={escrowOfficerName}
        phoneNum={escrowPhoneNum}
        setCname={setEscrowCName}
        setEmail={setEscrowEmail}
        setNum={setEscrowNum}
        setOfficerName={setEscrowOfficerName}
        setPhone={setEscrowPhoneNum}
        
        />
        <LenderInfo
         
         disabled={disabled}
        email={lenderEmail}
        name={lenderName}
        originatorName={lenderLoanOriginatorName}
        phoneNum={lenderPhoneNum}
        setEmail={setLenderEmail}
        setName={setLenderName}
        setOriginatorName={setLenderLoanOriginatorName}
        setPhone={setLenderPhoneNum}
        />
       
        <ClosingDetail
       
        isLease={isLease}
        closingENOinsurance={closingENOinsurance}
        setClosingENOinsurance={setClosingENOinsurance}
        closingDate={closingDate}
        setClosingDate={setClosingDate}
         disabled={disabled}
        transType={transType}
        transTypeOptions={transTypeOptions}
        brokerSplit={closingBrokerSplit}
        closedPrice={closingClosedPrice}
        comAmount={closingComAmount}
        comChk={closingComChk}
        firstOfficerPrice={closingFirstOfferPrice}
        netCom={closingNetCom}
        offerAcceptedPrice={closingOfferAcceptedPrice}
        other1={closingOther1}
        other2={closingOther2}
        other3={closingOther3}
        totalLess={closingTotalLess}
        setBrokerSplit={setClosingBrokerSplit}
        setClosedPrice={setClosingClosedPrice}
        setComAmount={setClosingComAmount}
        setComChk={setClosingComChk}
        setFirstOfficerPrice={setClosingFirstOfferPrice}
        setNetCom={setClosingNetCom}
        setOfferAcceptedPrice={setClosingOfferAcceptedPrice}
        setOther1={setClosingOther1}
        setOther2={setClosingOther2}
        setOther3={setClosingOther3}
        setTotalLess={setClosingTotalLess}
        />
                {/* <UploadTableForEdit
                 disabled={disabled}
        uploadTable2={uploadTable2}
        setUploadTable2={setUploadTable2}
        newUploadFileList={newUploadFileList}
      setNewFileList={setNewUploadFileList}
        /> */}
        {/* <TestComp uploadTable={uploadTable}/> */}
        <UploadTablePerTrans
disabled={disabled}
        // type='write'
        rowKey={location.state.rowKey}
        setCustomDocs={setCustomDocs}
        customDocs={customDocs}
        setUploadTable={setUploadTable}
        uploadTable={uploadTable} />
          <div style={{ position: 'fixed', bottom: 16, right: 16,display:"flex",gap:"16px" }}>
        <Fab 
        disabled={disabled}
        color='info' variant='extended' 
        onClick={onSave}
        sx={{
            width:"120px",
            gap:"8px"}}>
            <IoMdSave size={20} />
            SAVE
          </Fab> <Fab     onClick={
              ()=>{
                const isSubmit=window.confirm("Do you want to submit?.")
                if (isSubmit) {
                  onSubmit(uploadTable)
                } else {
                  return;
                }
              }}
          disabled={disabled}
          color='primary' variant='extended' sx={{
            width:"120px",
            gap:"8px"}}>
            <MdUploadFile size={20} 
        
            />
            SUBMIT
          </Fab>
           <Fab 
           disabled={disabled}
           color='error' variant='extended'
           
           onClick={ ()=>{
            const isDelete=window.confirm("Do you want to delete?.")
            if (isDelete) {
               onDelete()
            } else {
              return 
            }
            
                  }}
           sx={{
            width:"120px",
            gap:"8px"}}>
            <MdDelete size={20} />
            DELETE
          </Fab>
          </div>

      <div style={{ height:"64px"}} ></div>
     <div style={{display:"flex" , alignItems:"center",justifyContent:"center",gap:"32px"}} >

     {/* <Button 
      disabled={disabled}
     variant='contained' onClick={onSave}>save</Button>
     <Button 
      disabled={disabled}
     variant='contained'  onClick={
      ()=>{
        const isSubmit=window.confirm("Do you want to submit?.")
        if (isSubmit) {
          onSubmit()
        } else {
          return;
        }
      }
      
      }>submit</Button>
     <Button 
      disabled={disabled}
     variant='contained'   onClick={
      ()=>{
const isDelete=window.confirm("Do you want to delete?.")
if (isDelete) {
   onDelete()
} else {
  return 
}

      }
      
     }>delete</Button> */}

     </div>

    </div>
    );
}

export default EditContent;