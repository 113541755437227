import React, { useEffect, useState } from 'react';
import AdminBrokerSplitTable from './AdminBrokerSplitTable';
import { Button, Radio, Slider, TextField, Typography } from '@mui/material';
import CustomAirbnbSlider from '../components/CustomAirbnbSlider'
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import AdminAmountTable from './AdminAmountTable';
import { allowDotFormatNumber } from '../const/const';
function AdminBrokerSplitContent(props) {
    const [searchClick, setSearchClick] = useState(false)
    const [isAllSave,setAll]=useState(false)
    const [tableDatas, setTableDatas] = useState(null);
    const [priceRange,setPriceRange]=useState([0,100])
    const defaultPriceRange=[0,100]
    const [splitRange,setSplitRange]=useState([0,100])
    const defaultSplitRange=[0,100]
    const [selectedValue,setSelectedValue]=useState("O")
    const [selectedAmount,setSelectedAmount]=useState("percent")
    const [ownerPercent,setOwnerPercent]=useState("0")
    const [agentPercent,setAgentPercent]=useState("10")
    const [leaderPercent,setLeaderPercent]=useState("10")
    const [managerPercent,setManagerPercent]=useState("10")
    const [enoVal,setEnoVal]=useState("0")
    const getTableDatas = async () => {
    const optionsCollection = collection(db, "options");
    const docRef = doc(optionsCollection, "closedDetail");
    
    const data = await getDoc(docRef);

    if (data.exists()) {
      const res = data.data()["selectList"];
      const percentRes=data.data()["selectPercentList"]
const enos=data.data()["eno"]
      setAgentPercent(percentRes["A"])
      setManagerPercent(percentRes["M"])
      setLeaderPercent(percentRes["L"])
      setOwnerPercent(percentRes["O"])
      setEnoVal(enos[selectedValue])
      setTableDatas(res);
    } else {
    }
  };
   const handleSearchClick = () => { }
  const handlePriceRange=(e,v)=>{

    setPriceRange(v)
  }
const handleSplitRange=(e,v)=>{

    setSplitRange(v)
}  
const handleSetDefault=async(e)=>{
const type=e.target.id
  const res=window.confirm(`Would you like to set ${type} value to the default?.`)
  const optionsCollection = collection(db, "options");
  const docRef = doc(optionsCollection, "amountType");
  await updateDoc(docRef, {type:selectedAmount});
  if (type==="absolute") {
  if (res) {
  handleDefaultSave()
 } else {
  return
 } 
 } else {
  const optionsCollection = collection(db, "options");
  const docRef = doc(optionsCollection, "closedDetail");
  await updateDoc(docRef, {selectPercentList:{
    O:"0",
    M:"10",
    L:"10",
    A:"10"
  }});
  setOwnerPercent("0")
  setManagerPercent("10")
  setLeaderPercent("10")
  setAgentPercent("10")
  
 }
 
  

}
const handleSetAllSave=()=>{

  const res=window.confirm("Would you like to save all?.")
  setAll(res)

}
   useEffect(() => {
    getTableDatas();
  }, [selectedValue]);
  const handleAllSave = async (allData) => {
    const optionsCollection = collection(db, "options");
    const docRef = doc(optionsCollection, "closedDetail");
    try {
      const docSnapshot = await getDoc(docRef);
  
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        // 기존 데이터를 복사하여 새로운 데이터로 업데이트
        const updatedSelectList = [...existingData.selectList];
        //   updatedSelectList[index] = {
        //   closedPrice: `${newClosedPrice0}~${newClosedPrice1}`,
        //   brokerSplit: newBrokerSplit,
        // };
        const updateObjectList = {
          [`selectList`]: updatedSelectList,  
          
          // [`eno.${selectedValue}`]:enoVal,
      };   
    
       
        // 전체 데이터를 업데이트
        await updateDoc(docRef, updateObjectList);
   

        getTableDatas();
      } else {
      }
    } catch (error) {
    }
  };
  const handleDefaultSave = async (classType) => {
    const optionsCollection = collection(db, "options");
    const docRef = doc(optionsCollection, "closedDetail");
    try {
      const docSnapshot = await getDoc(docRef);
  
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        // 기존 데이터를 복사하여 새로운 데이터로 업데이트
        const updatedSelectList = [
///1~5
{closedPrice:'1~299,999.99',
brokerSplit:'450'
},
{closedPrice:'300,000~499,999.99 ',
brokerSplit:'600 '
},
{closedPrice:'500,000~799,999.99',
brokerSplit:'800'
},
{closedPrice:'800,000~999,999.99',
brokerSplit:'1,200'
},
{closedPrice:'1,000,000~1,299,999.99',
brokerSplit:'1,500'
},
///6~11
{closedPrice:'1,300,000~1,499,999.99',
brokerSplit:'2,000'
},

{closedPrice:'1,500,000~1,799,999.99',
brokerSplit:'2,500'
},
{closedPrice:'1,800,000~1,999,999.99',
brokerSplit:'3,000'
},
{closedPrice:'2,000,000~2,499,999.99',
brokerSplit:'3,500'
},
{closedPrice:'2,500,000~2,999,999.99',
brokerSplit:'4,000'
},
{closedPrice:'3,000,000~3,499,999.99',
brokerSplit:'4,500'
},
{closedPrice:'3,500,000~3,999,999.99',
brokerSplit:'5,000'
},
{closedPrice:'4,000,000~4,999,999.99',
brokerSplit:'5,500'
},
{closedPrice:'5,000,000~5,999,999.99',
brokerSplit:'6,000'
},
{closedPrice:'6,000,000~',
brokerSplit:'7,000'
},
{closedPrice:'10,000,000~',
brokerSplit:'10,000'
},
...Array(14).fill({
  closedPrice:' ~ ',
  brokerSplit:''
})



        ];
          
        const updateObjectList = {
          [`selectList.${selectedValue}`]: updatedSelectList,
          [`eno.${selectedValue}`]:"0"
      };
   
        // 전체 데이터를 업데이트
        await updateDoc(docRef, updateObjectList);
        window.confirm("Successfully saved.")

        getTableDatas();
      } else {
      }
    } catch (error) {
    }
  };
 
const handleRadios=(e)=>{
  setSelectedValue(e.target.value)
}
const handleAmountRadios=(e)=>{
  setSelectedAmount(e.target.value)
}
const handleTableSave=async(e)=>{
 
  const optionsCollection = collection(db, "options");
  const docRef = doc(optionsCollection, "closedDetail");
const type=e.target.id
  try {
    const amountDocRef = doc(optionsCollection, "amountType");
    await updateDoc(amountDocRef, {type:selectedAmount});
    if(type==="absolute"){// 전체 데이터를 업데이트
    await updateDoc(docRef, {selectList:tableDatas

      ,[`eno.${selectedValue}`]:enoVal
    });
    window.confirm("Successfully absolute value saved.")}
    else{
      await updateDoc(docRef, {selectPercentList:{
        O:ownerPercent,
        M:managerPercent,
        L:leaderPercent,
        A:agentPercent
      }});
      window.confirm("Successfully percent value saved.")}
    
    
  } catch (error) {
  }

}
// const onChangePercent=(e)=>{
//   const userType=e.target.id;
//   const newValue=e.target.value.toString()
//    // 숫자와 마이너스 부호만 허용하는 정규식
//    const validInput = newValue.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용

//    // 원하는 형식으로 포맷팅
//    const formattedValue = allowDotFormatNumber(validInput);
//   console.log("on change percent>>>",userType)
//   if (userType==="O") {
//     setOwnerPercent(formattedValue)
//   }
//   else if(userType==="M"){
//     setManagerPercent(formattedValue)
//   }
//   else if(userType==="L"){
//     setLeaderPercent(formattedValue)
//   }
//   else{
//     setAgentPercent(formattedValue)
//   }
// }
const onChangePercent = (e) => {
  const userType = e.target.id;
  let newValue = e.target.value.toString();

  // 숫자와 마이너스 부호만 허용하는 정규식
  const validInput = newValue.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용

  // 백의 자릿수까지만 허용하는 정규식
  const validInputWithDecimal = validInput.match(/^\d{1,3}(\.\d{0,2})?/); // 백의 자릿수까지 허용하고, 소숫점도 허용

  // 범위 내의 값으로 조정
  newValue = validInputWithDecimal ? validInputWithDecimal[0] : '';

  // 원하는 형식으로 포맷팅
  const formattedValue = allowDotFormatNumber(newValue);

  if (userType === "O") {
    setOwnerPercent(formattedValue);
  } else if (userType === "M") {
    setManagerPercent(formattedValue);
  } else if (userType === "L") {
    setLeaderPercent(formattedValue);
  } else {
    setAgentPercent(formattedValue);
  }
}


    return (
        <div className="ContentBox">
            {/* <div
                style={{ display: "flex", width: "100%", justifyContent: "center", gap: "32px" }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:"flex-end",
                        gap: "16px",
                        width: "600px"
                    }}
                ><Typography variant='body2'>Closed Price : </Typography>
                <TextField size='small'/> ~ <TextField size='small'/>
                </div>
                <div
       style={{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        gap:"16px",
    width:"600px"   
    }}
       ><Typography variant='body2'>Broker Split : </Typography>
       <TextField size='small'/> ~ <TextField size='small'/></div> 
                
                <Button
                    onClick={handleSearchClick}
                    variant='contained' color='success' sx={{ width: "100px" }}>SEARCH</Button>
            </div> */}
            {/* All Save ,SET Default Setting , All Clear 버튼 세개*/}
            <div 
            style={{
              display:"flex",
              width:"100%",
              justifyContent:"flex-start",
              gap:"16px"
            }}
            >
              <div
              style={{
                display:"flex",
                paddingLeft:"16px",
                gap:"32px",
                alignItems:"center"
              }}
              >
                <span
                style={{
                  fontWeight:700
                }}
                >Select user type</span>
                <div> O <Radio
              value={"O"}
    checked={selectedValue === 'O'}
    onChange={handleRadios}
  /></div><div> M <Radio
  value={"M"}
checked={selectedValue === 'M'}
onChange={handleRadios}
/></div>
<div> L <Radio
 value={"L"}
  checked={selectedValue === 'L'}
  onChange={handleRadios}
/></div>
<div> A <Radio
 value={"A"}
  checked={selectedValue === 'A'}
  onChange={handleRadios}
/></div></div>
        
<div
style={{
  flex:1
}}
/>
<Button variant='contained' size='small' 
  id={selectedAmount}
sx={{
  width:"120px"
}}
onClick={handleTableSave}
>SAVE</Button>
<Button variant='contained' size='small' color='secondary'
id={selectedAmount}
sx={{
  width:"120px"
}}
onClick={handleSetDefault}
>SET DEFAULT</Button>

{/* <Button variant='contained' size='small'
color='error'
sx={{
  width:"120px"
}}
>ALL CLEAR</Button> */}
{/* <Button variant='contained' size='small'
sx={{
  width:"120px"
}}
onClick={handleSetAllSave}
>ALL SAVE</Button> */}

            </div>
            {/* 금액을 퍼센트로 적용할 지 절댓값으로 적용 할 지 */}
            <div
              style={{
                width:"100%",
                display:"flex",
                paddingLeft:"16px",
                gap:"32px",
                alignItems:"center"
              }}
              >
                <span
                style={{
                  fontWeight:700
                }}
                >Select amount application type</span>
                <div> percent(lease) <Radio
              value={"percent"}
    checked={selectedAmount === 'percent'}
    onChange={handleAmountRadios}
  /></div><div> absolute(sale) <Radio
  value={"absolute"}
checked={selectedAmount === 'absolute'}
onChange={handleAmountRadios}
/></div>
</div>
        {selectedAmount==="percent"?<AdminAmountTable
        //
        ownerPercent={ownerPercent}
        managerPercent={managerPercent}
        leaderPercent={leaderPercent}
        agentPercent={agentPercent}
        onChangePercent={onChangePercent}
        />:  
        
        <AdminBrokerSplitTable 
        enoVal={enoVal}
        setEnoVal={setEnoVal}
            classType={selectedValue}
            isAllSave={isAllSave}
            handleAllSave={handleAllSave}
            setTableDatas={setTableDatas}
            tableDatas={tableDatas} getTableDatas={getTableDatas}/>
        }</div>
    );
}

export default AdminBrokerSplitContent;