import React from 'react';
import TxtfieldRow from '../components/TxtfieldRow';
import "../App.css"
import { Card, CardContent } from '@mui/material';
export default function LenderInfo({
  disabled,
  name,originatorName,phoneNum,email,
setName,setOriginatorName,setPhone,setEmail
}) {
    const today = new Date()
    const todayFullDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const onChangeName=(e)=>{
      setName(e.target.value)
    }
    const onChangeOriginatorName=(e)=>{
      setOriginatorName(e.target.value)
    }
    const onChangePhone=(e)=>{
      setPhone(e.target.value)
    }
    const onChangeEmail=(e)=>{
      setEmail(e.target.value)
    }
    return (
        <Card sx={{width:"100%"}} variant='outlined'>
        <CardContent >
        <div className='ContentBox'>
            <div>Lender Information</div>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Lender Name"} value={name} onChange={onChangeName}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Loan Originator Name"} value={originatorName} onChange={onChangeOriginatorName}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Phone Number"} value={phoneNum} onChange={onChangePhone}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Email Address"} value={email} onChange={onChangeEmail}/>
     
      {/* 아래는 파일피커 하는방법이라 나중에 필요할까봐 주석처리 해놓음 */}
      {/* <div
        style={{
          display: "flex",
          width: "500px",
          gap: "16px"
        }}
      > <TextField sx={{ width: "100%", }}
        label={file === null ? "파일 경로" : file.name}



        />
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button onClick={handleUploadButtonClick}>업로드</Button></div>
      <div style={{ width: "500px", height: "500px", display: "flex", backgroundColor: "grey", color: "white", alignItems: "center", justifyContent: "center" }}>이미지</div>

      <Button sx={{ width: "500px" }} variant='contained'>저장</Button> */}
        </div>
        </CardContent>
        </Card>
    );
}
