import React, { useState } from 'react';
import './CircleArrowBtn.css';
import { Button } from '@mui/material';
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

function CircleArrowBtn({onClick,title="MORE"}) {
    const [hover, setHover] = useState(false);
    const {t} = useTranslation();
    const onMouseEnter = () => {
        setHover(true);
    }

    const onMouseLeave = () => {
        setHover(false);
    }

    return (
        <Button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        sx={{
            display:"flex",
            gap:"8px",
            color:"#333333",
            fontWeight:600
        }}
        >
            <div>
                <IoArrowForwardCircleSharp 
                  
                    size={36}
                    color={hover ? "#EDD6B6" : '#AA8B3B'}
                    className={`icon-rotate ${hover ? 'icon-rotate-hover' : ''}`} // 클래스 이름 추가
                />
            </div>
            <span
            style={{
                fontSize:"16px"
            }}
            >
                {title==="MORE"?t(`etc.more`):t(`etc.showAll`)}
                {/* 더 알아보기 */}
                </span>
            
        </Button>
    );
}

export default CircleArrowBtn;
