import React from 'react';
import TxtfieldRow from '../components/TxtfieldRow';
import "../App.css"
import { Card, CardContent } from '@mui/material';
import DtPickerRow from '../components/DtPickerRow';
export default function EscrowInfo({
  disabled,
  escrowOpenDate,
  setEscrowOpenDate,
  companyName,number,officerName,phoneNum,email,
setCname,setNum,setOfficerName,setPhone,setEmail
}) {
   const onChangeCname=(e)=>{
    setCname(e.target.value)
   }
   const onChangeNum=(e)=>{
    setNum(e.target.value)
   }
   const onChangeOfficerName=(e)=>{
    setOfficerName(e.target.value)
   }
   const onChangePhone=(e)=>{
    setPhone(e.target.value)
   }
   const onChangeEmail=(e)=>{
    setEmail(e.target.value)
   }
   const onDtChange=(e)=>{
    setEscrowOpenDate(e.target.value)
   }
    return (
        <Card sx={{width:"100%"}} variant='outlined'>
        <CardContent>
        <div className='ContentBox'>
            <div>Escrow Information</div>
            <DtPickerRow
            titleWidth='20vw'
           containerWidth='50vw'
            readOnly={disabled}
            title={"Escrow Open Date"}
            value={escrowOpenDate} onDtChange={onDtChange}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Escrow Company Name"} value={companyName} onChange={onChangeCname}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Escrow Number"} value={number} onChange={onChangeNum}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Escrow Officer Name"} value={officerName} onChange={onChangeOfficerName}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Escrow Phone Number"} value={phoneNum} onChange={onChangePhone}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Escrow Email Address"} value={email} onChange={onChangeEmail}/>
   
      {/* 아래는 파일피커 하는방법이라 나중에 필요할까봐 주석처리 해놓음 */}
      {/* <div
        style={{
          display: "flex",
          width: "500px",
          gap: "16px"
        }}
      > <TextField sx={{ width: "100%", }}
        label={file === null ? "파일 경로" : file.name}



        />
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button onClick={handleUploadButtonClick}>업로드</Button></div>
      <div style={{ width: "500px", height: "500px", display: "flex", backgroundColor: "grey", color: "white", alignItems: "center", justifyContent: "center" }}>이미지</div>

      <Button sx={{ width: "500px" }} variant='contained'>저장</Button> */}
        </div>
      </CardContent>
         </Card>
    );
}
