export const bdFormat=(number)=>{
  // 5자리 숫자로 변환
  const formattedNumber = `BD${String(number).padStart(5, '0')}`;
  return formattedNumber;
}
export const notApplicableLabel="Not applicable"
//소숫점이랑
export const allowDotFormatNumber = (number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};
export const closedPriceFormat=(num)=> {
  const regexp = /\B(?=(\d{3})+(?!\d))/g
  return num.toString().replace(regexp, ",")
  }
//broker split 셀렉트 하는 함수
// export const getCalcedBrokerSplit=(transactionType,comChk,value,mul)=>{

 

// const condition1=transactionType==="Residential Listing"||
// transactionType==="Residential Buying"||
// transactionType==="Residential New Home"||
// transactionType==="Commercial Listing"||
// transactionType==="Commercial Buying"||
// transactionType==="Land Listing"||
// transactionType==="Land Buying"
// const condition2=transactionType==="Residential Listing and Buying (Dual)"||
// transactionType==="Commercial Listing and Buying (Dual)"||
// transactionType==="Land Listing and Buying (Dual)"

// const condition3=
// transactionType==="Residential Lease Listing (landlord side)"||
// transactionType==="Residential Lease Buying (tenant side)"||
// transactionType==="Commercial Lease Listing"||
// transactionType==="Commercial Lease Buying"||
// transactionType==="Land Lease Listing"||
// transactionType==="Land Lease Buying"||
// transactionType==="Residential Lease Listing and Buying (Dual)"||
// transactionType==="Commercial Lease Listing and Buying (Dual)"||
// transactionType==="Land Lease Listing and Buying"

//  const zero=0; 
//  console.log("replace err",value) 
//  if (condition1) {
//   //값 그대로

//  const res=parseFloat(value.includes(",")?value.replace(/,/g, ''):value).toFixed(2);

// return res===NaN?zero.toFixed(2):res
// } else if(condition2) {
// const res=parseFloat(value.replace(/,/g, ''))*mul.toFixed(2)
//   return res===NaN?zero.toFixed(2):res //mul
// }else if(condition3){
//   // console.log("리플레이스 에러>>>",comChk)
//   const removeComma = typeof comChk === 'string' ? comChk.replace(/,/g, '') : comChk.toString().replace(/,/g, '');
//   const onlyNumComChk= parseFloat(removeComma)
//   const res= onlyNumComChk*0.1.toFixed(2)
//   return res===NaN?zero.toFixed(2):res
// }else{
//   const res=0;
//   return res.toFixed(2)
// }

// }
export const getCalcedBrokerSplit = (transactionType, comChk, numberVal, mul) => {
  const condition1 =
    transactionType === "Residential Listing" ||
    transactionType === "Residential Buying" ||
    transactionType === "Residential New Home" ||
    transactionType === "Commercial Listing" ||
    transactionType === "Commercial Buying" ||
    transactionType === "Land Listing" ||
    transactionType === "Land Buying";
  const condition2 =
    transactionType === "Residential Listing and Buying (Dual)" ||
    transactionType === "Commercial Listing and Buying (Dual)" ||
    transactionType === "Land Listing and Buying (Dual)";
    //condition3->리스의 경우
  const condition3 =
    transactionType === "Residential Lease Listing (landlord side)" ||
    transactionType === "Residential Lease Buying (tenant side)" ||
    transactionType === "Commercial Lease Listing" ||
    transactionType === "Commercial Lease Buying" ||
    transactionType === "Land Lease Listing" ||
    transactionType === "Land Lease Buying" ||
    transactionType === "Residential Lease Listing and Buying (Dual)" ||
    transactionType === "Commercial Lease Listing and Buying (Dual)" ||
    transactionType === "Land Lease Listing and Buying";

  const zero = 0;
 
  const value=numberVal.toString() 
  if (condition1) {
    const res = parseFloat(value.replace(/,/g, "")).toFixed(2);
    return isNaN(res) ? zero.toFixed(2) : res;
  } else if (condition2) {
    const res = parseFloat(value.replace(/,/g, "")) * mul.toFixed(2);
    return isNaN(res) ? zero.toFixed(2) : res; //mul
  } else if (condition3) {
    const removeComma = typeof comChk === "string" ? comChk.replace(/,/g, "") : comChk.toString().replace(/,/g, "");
    const onlyNumComChk = parseFloat(removeComma);
    const res = (onlyNumComChk * 0.1).toFixed(2);
    //리스일때는 원래 값 리턴해서, 함수 밖에서 관리자모드에서 직위별로 설정한 값만큼 공제
    return isNaN(res) ? zero.toFixed(2) : onlyNumComChk;
  } else {
    const res = 0;
    return res.toFixed(2);
  }
};
export const removeCommaToFloat=(value)=>{
  // console.log("value.replace error>>>",value)
  if (value===undefined||value===NaN||value===""||value===null) {
    return parseFloat(0.0)
  } else {
    const stringVal=value.toString()
  if (stringVal===undefined||stringVal===NaN||stringVal===""||stringVal===null) {
    return parseFloat(0.0)
  } else {


    return parseFloat(stringVal.replace(/,/g, ''))

 
  }
  }
  
  

}
//회원가입 유효성 검사
// 이메일 형식 검사 함수
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// 비밀번호 형식 검사 함수
export const isValidPassword = (password) => {
  // 비밀번호는 최소 8자 이상, 영문 대소문자 및 숫자를 포함해야 합니다.
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return passwordRegex.test(password);
};
const isValidString=(str)=>{
  return str.trim()!=""
}
// 회원가입 폼 검사 함수
export const validateSignupForm = (email, password,name,licenseNum) => {
  let errors = {email:"",password:"",name:"",licenseNum:""};

  // 이메일 형식 검사
  if (!isValidEmail(email)) {
    errors.email = "-The email format is not valid.";
  }

  // 비밀번호 형식 검사
  if (!isValidPassword(password)) {
    errors.password =
      "-The password must be at least 8 characters long and include a combination of uppercase and lowercase letters, as well as numbers.";
  }
  if(!isValidString(name)){
    errors.name="-Please fill in the name text field"
  }
  if (!isValidString(licenseNum)) {
    errors.licenseNum="-Please fill in the license number text field"
  }

  return errors;
};
export const preventScroll = () => {
  const currentScrollY = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
  document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
  document.body.style.overflowY = 'scroll';
  return currentScrollY;
};

/**
 * 스크롤을 허용하고, 스크롤 방지 함수에서 반환된 위치로 이동한다.
 * @param prevScrollY 스크롤 방지 함수에서 반환된 스크롤 위치
 */
export const allowScroll = (prevScrollY) => {
  document.body.style.position = '';
  document.body.style.width = '';
  document.body.style.top = '';
  document.body.style.overflowY = '';
  window.scrollTo(0, prevScrollY);
};
export const sxTableCellWidth="60px"
export const mdTableCellWidth="100px"
export const wideTableCellWidth="600px"
export const basicTableHeight="680px"
export const controllerHeight="30px"
export const smFontSize="13px"
export const mdFontSize="14px"
export const lgFontSize="16px"
export const reviewColor="#1976D2"
export const submitColor="#20A363"
export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    // ...(mode === 'light'
    //   ? {
    //       // palette values for light mode
    //       primary: amber,
    //       divider: amber[200],
    //       text: {
    //         primary: grey[900],
    //         secondary: grey[800],
    //       },
    //     }
    //   : {
    //       // palette values for dark mode
    //       primary: deepOrange,
    //       divider: deepOrange[700],
    //       background: {
    //         default: deepOrange[900],
    //         paper: deepOrange[900],
    //       },
    //       text: {
    //         primary: '#fff',
    //         secondary: grey[500],
    //       },
    //     }),
  },
});
export const wrongPwMsg="Wrong password"
export const errMsg="Error"
        
export const mainHomePgMainColor="#AA8B3B"