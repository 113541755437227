import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, TableHead, Tooltip } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { transactionTypeOptionsState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import { FaCircleCheck } from "react-icons/fa6";
import { FaArrowCircleUp } from "react-icons/fa";
import { basicTableHeight, mdFontSize, primaryCode, reviewColor, smFontSize, submitColor, sxTableCellWidth } from '../const/const';
import ReviewRow from './ReviewRow';
import AdminTableLoader from "./AdminTableLoader"

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



export default function ReviewTable({ tableDatas, setTableDatas, searchClick, bdNumList, addressList,
  transTypeList, }) {
  const transTypeCollection = collection(db, "options")
  const datasCollection = collection(db, "pjDatas")
  const dataCollection = collection(db, "pjDatas")

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [transTypeOptions, setTransTypeOptions] = useRecoilState(transactionTypeOptionsState)

  const [filteredRow, setFilteredRow] = React.useState([])
  const [userNames,setUserNames]=React.useState([])
  const theme = useTheme()

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  //transType options 파베에서 가지고 오기
  const userCollection = collection(db, "userInfos")

  async function getTransTypeOptions() {
    const docRef = doc(transTypeCollection, "transactionType")
    const transData = await getDoc(docRef)

    if (transData.exists()) {
      setTransTypeOptions(transData.data()["selectList"])


    } else {
    }
  }
  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {

    getTransTypeOptions()
   
  }, [])
useEffect(()=>{
  setFilteredRow(filterRows(tableDatas))


},[tableDatas])
  const onReview = async (rowId, userId) => {
    const docRef = doc(datasCollection, userId);
    const data = await getDoc(docRef);

    if (data.exists()) {

      const originRes = data.data()[rowId]["finish"];
      const updateObject = {
        [`${rowId}.finish`]: !originRes,
      };
      await updateDoc(doc(datasCollection, userId), updateObject);

      // tableDatas를 직접 업데이트
      setTableDatas((prevTableDatas) => {
        const updatedTableDatas = prevTableDatas.map((item) => {
          if (item.id === rowId && item.userId === userId) {
            return {
              ...item,
              finish: !originRes,
            };
          }
          return item;
        });
        return updatedTableDatas;
      });

    } else {
    }
  };
  const onSubumit=async(rowId, userId)=>{

    try {
      const docRef = doc(datasCollection, userId);
    const data = await getDoc(docRef);

    if (data.exists()) {

      const submitRes = data.data()[rowId]["formState"]==="save"?"submit":"save";
      const updateObject = {
        [`${rowId}.formState`]: submitRes,
      };
      await updateDoc(doc(datasCollection, userId), updateObject);
            // tableDatas를 직접 업데이트
            setTableDatas((prevTableDatas) => {
              const updatedTableDatas = prevTableDatas.map((item) => {
                if (item.id === rowId && item.userId === userId) {
                  return {
                    ...item,
                    formState: submitRes,
                  };
                }
                return item;
              });
              return updatedTableDatas;
            });
    
    }
    } catch (error) {
      
    }
  }
  const filterRows = (originRows) => {
    //     if (!isFilter) {
    //       return originRows;
    //     }
    // else{
    return originRows.filter((row) => {
      const bdNumMatch =bdNumList.length===0?true: bdNumList.includes(row.bdNum);
      const addressMatch =addressList.length===0?true: addressList.includes(row.newTransactionDetail.propertyAddress);
      const transTypeMatch = transTypeList.length===0?true:transTypeList.includes(transTypeOptions[row.newTransactionDetail.transType]);
      const res=bdNumMatch && addressMatch && transTypeMatch;
    
      return res;
    });

  };
  useEffect(() => {
    if (tableDatas === undefined || tableDatas.length === 0 || tableDatas === null) return
      setFilteredRow(filterRows(tableDatas))


  }, [searchClick])
  return (
    filteredRow === null || filteredRow === undefined  ? 
    <TableContainer variant='outlined'
  component={Paper} sx={{
        height: basicTableHeight,
        width: "100%"
      }}>

      Loading...
    </TableContainer> :
      <TableContainer  variant='outlined'
      component={Paper} sx={{
            height: basicTableHeight,
            width: "100%"
          }}>  <Table

        size='small'
        stickyHeader
        sx={{ minWidth: 500 }} aria-label="a dense table">
           <TableHead >
       <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Number
              </TableCell>
              <TableCell style={{ width: "130px" }} scope="row" >
                BD Number
              </TableCell>
              <TableCell style={{ width: "180px" }} scope="row" >
                Closing Date
              </TableCell>
              <TableCell style={{ width: "600px" }} scope="row" >
                ID
              </TableCell>
              <TableCell style={{ width: "1100px" }} scope="row" >
                Address
              </TableCell>
              <TableCell style={{ width: "300px" }} scope="row" >
                City
              </TableCell>
              <TableCell style={{ width: "425px" }} scope="row" >
                Transaction Type
              </TableCell>
              <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Submit /  Unsubmit
              </TableCell>
            
              <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Finish / Review
              </TableCell>
              {/* <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Content
              </TableCell>
              <TableCell style={{ width: sxTableCellWidth }} scope="row" >
                Documents
              </TableCell> */}
       </TableHead>
        <TableBody>

          {filteredRow.map((row, index) => {


            return (
              <ReviewRow
              onSubumit={onSubumit}
              setUsernameList={setUserNames}
              usernameList={userNames}
              row={row} index={index} transTypeOptions={transTypeOptions} onReview={onReview} length={filteredRow.length}/>
            )
          })}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}  */}
        </TableBody>

      </Table></TableContainer>


  );
}
