import { ArrowDownward, ArrowDownwardSharp, ArrowDropDown } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { IoIosArrowDown } from "react-icons/io";
function MenuBtn({title,onClick}) {
    return (
        <Button
        sx={{
            fontWeight:"bold",
                fontSize:"18px",
              color:"#444444",
              gap:"5px"
              
        }}
        onClick={onClick}
        >
            {title}
            <IoIosArrowDown />
        </Button>
    );
}

export default MenuBtn;