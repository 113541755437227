import React, { useEffect, useState } from 'react';
import ReviewTable from '../components/ReviewTable';
import { db } from '../service/firebase';
import { collection, getDocs } from 'firebase/firestore';
import CustomSearchBar from '../components/CustomSearchBar';
import { Button, IconButton, Popover } from '@mui/material';
import { FaSearch } from 'react-icons/fa';
import SearchPopOver from '../components/SearchPopOver';
import FilterSearchBar from '../components/FilterSearchBar';
import { useRecoilState } from 'recoil';
import { transactionTypeOptionsState } from '../atom/atom';

function ReviewContent(props) {
    const [tableDatas,setTableDatas]=useState([])
    const [bdNumsOption,setBdNumsOption]=useState([])
    const [addressOption,setAddressOption]=useState([])
    const [ttOption,setTtOption]=useState([])
    const [transTypeOptions,setTransTypeOptions]=useRecoilState(transactionTypeOptionsState)
    const [bdSearchList,setBdSearchList]=useState([])
    const [addressSearchList,setAddressSearchList]=useState([])
    const [transSearchList,setTransSearchList]=useState([])
   const [searchClick,setSearchClick]=useState(false)
    const dataCollection = collection(db, "pjDatas")
    
    async function getDatas() {
        const data = await getDocs(dataCollection)
        const list=data.docs.map(d=>d.data())
      
        const flatDatas= list
     
        .map((l) => Object.values(l))
        .flat().filter((l) => l.hasOwnProperty('bdNum'));
  
         // 정렬 함수를 수정하여 "BD"가 없는 경우에 대한 처리를 추가
    const dtSortTable = flatDatas.sort((a, b) => {
      const bdNumA = a.bdNum.split("BD")[1];
      const bdNumB = b.bdNum.split("BD")[1];

      // "BD"가 없는 경우에 대한 처리를 추가
      if (bdNumA === undefined) return 1;
      if (bdNumB === undefined) return -1;

      return bdNumB - bdNumA;
    }); setTableDatas(dtSortTable)
      }
      const handleSearchClick=()=>{

        setSearchClick(p=>!p)
      }
useEffect(()=>{


    getDatas()
},[])
useEffect(()=>{
  //테이블 데이터가 변할 때 마다 서치옵션이 변경되게
  if (!tableDatas)return;
  //bdNum Option
  const newBdNumsOption=tableDatas.map(td=>td.bdNum)
  const set = new Set(newBdNumsOption);

  const noSameArr = [...set];
setBdNumsOption(noSameArr)
//address option
const newAddressOption=tableDatas.map(td=>td.newTransactionDetail.propertyAddress)
const addressSet = new Set(newAddressOption);
const noSameAddress = [...addressSet];
setAddressOption(noSameAddress)
//transaction type option
const newTransTypes=tableDatas.map(td=>td.newTransactionDetail.transType)
const newTransTypeOptions=[];
  for (let i = 0; i < newTransTypes.length; i++) {
    const index = newTransTypes[i];
    const item=transTypeOptions[index];
    newTransTypeOptions.push(item)
 
  
}
const ttSet=new Set(newTransTypeOptions)
const noSameTt=[...ttSet]
setTtOption(noSameTt)
},[tableDatas])
const handleBdSearchList=(e,vals)=>{
  setBdSearchList(vals)
}
const handleAddressSearchList=(e,vals)=>{
  setAddressSearchList(vals)
}
const handleTransSearchList=(e,vals)=>{
  setTransSearchList(vals)
}
// const isFilter=bdSearchList.length!==0
    return (
        <div className="ContentBox">
       
       <div
       style={{display:"flex",width:"100%",justifyContent:"flex-start",gap:"16px"}}
       >
{/* bdnumber 서치 바 */}
<FilterSearchBar 
handleSearchList={handleBdSearchList}
width='900px'label={"BD Number"} options={bdNumsOption} limitTags={2}/>
{/* Address 서치 바 */}
<FilterSearchBar
handleSearchList={handleAddressSearchList}
width='100%'label={"Address"} options={addressOption} limitTags={1}/>
{/* Transaction Type 서치 바 */}
<FilterSearchBar

handleSearchList={handleTransSearchList}
width='100%'label={"Transaction Type"} options={ttOption} limitTags={1}/>
<Button 
onClick={handleSearchClick}
variant='contained' color='success'sx={{width:"200px"}}>SEARCH</Button>
       </div>
        <ReviewTable 
        searchClick={searchClick}
        bdNumList={bdSearchList}
        addressList={addressSearchList}
        transTypeList={transSearchList}
        tableDatas={tableDatas} setTableDatas={setTableDatas}/>
      </div> 
    );
}

export default ReviewContent;