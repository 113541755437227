import { Button, Checkbox, Chip, MenuItem, Select, TableCell, TableRow, TextField, Tooltip, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { db } from '../service/firebase';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { controllerHeight, smFontSize } from '../const/const';

function UserSettingRow({ length,row, index, deleteList,
    setDeleteList }) {
    const [approveSelect, setApproveSelect] = useState("")
    const [userTypeSelect, setUserTypeSelect] = useState("")
    const [classTypeSelect, setClassTypeSelect] = useState("")
    const [newName, setNewName] = useState("")
    const userCollection = collection(db, "userInfos")
    const theme = useTheme()
    useEffect(() => {


        setApproveSelect(row["approve"] === true ? "true" : "false")
        setUserTypeSelect(row["userType"])
        setClassTypeSelect(row["splitClass"])
        setNewName(row["name"])
    }, [row])
    const onChangeApprove = (e) => {
        setApproveSelect(e.target.value)
    }
    const onChangeUserType = (e) => {


        setUserTypeSelect(e.target.value)
    }
    const onChangeClassType = (e) => {

        setClassTypeSelect(e.target.value)
    }
    const onSaveRow = async () => {
        const booleanApprove = approveSelect === "true" ? true : false
        const rowId = row["id"]
        await updateDoc(doc(userCollection, rowId), {
            name: newName,
            approve: booleanApprove,
            userType: userTypeSelect,
            splitClass: classTypeSelect
        })
        window.confirm("Save Successfully!")
    }
    const getClassColor = (classType) => {

        if (classType === "O") {
            return theme.palette.error.main
        }
        else if (classType === "M") {
            return theme.palette.success.main
        }
        else if (classType === "L") {
            return theme.palette.primary.main
        }
        else if (classType === "A") {
            return theme.palette.secondary.main
        }
        else {
            return null
        }
    }
    const onChangeName = (e) => {

        setNewName(e.target.value)
    }
    const onChangeCheck = (e) => {
        const idToAdd = e.target.id;
        // 체크되어 있지 않은 경우, 해당 아이디를 삭제
        if (deleteList.includes(idToAdd)) {
          setDeleteList(prevList => prevList.filter(id => id !== idToAdd));
        } else {
          // 체크되어 있지 않은 경우, 해당 아이디를 추가
          setDeleteList(prevList => [...prevList, idToAdd]);
        }
      }
    return (
        <TableRow 
        
        key={row.id}


        >

            <TableCell component="th" scope="row">
                <Tooltip
                
                title={localStorage.getItem("nowUserIdInLocal")===row["id"]?"If the user ID is your own, deletion is not possible.":""}
                > <Checkbox
                disabled={localStorage.getItem("nowUserIdInLocal")===row["id"]}
                color='error'
                    id={row["id"]}
                    onChange={onChangeCheck} /></Tooltip>
               
            </TableCell>  <TableCell component="th" scope="row">
                {length-index}
            </TableCell>
            <TableCell component="th" scope="row">
                <TextField size='small' value={newName} onChange={onChangeName} />

            </TableCell>
            <TableCell component="th" scope="row">
                {row["id"]}
            </TableCell>

            <TableCell component="th" scope="row">
                <Select
                    sx={{ width: "100px", height: controllerHeight, fontSize: smFontSize }}
                    size='small'
                    value={approveSelect}
                    onChange={onChangeApprove}
                >
                    <MenuItem value={"true"}>true</MenuItem>
                    <MenuItem value={"false"}>false</MenuItem>
                </Select>
            </TableCell>

            <TableCell component="th" scope="row">
                <Select
                    sx={{ width: "100px", height: controllerHeight, fontSize: smFontSize }}
                    size='small'
                    value={userTypeSelect}
                    onChange={onChangeUserType}
                >
                    <MenuItem value={"user"}>user</MenuItem>
                    <MenuItem value={"admin"}>admin</MenuItem>
                </Select>

            </TableCell>
            <TableCell component="th" scope="row">
                <Select
                    sx={{ width: "160px", height: controllerHeight, fontSize: smFontSize }}
                    size='small'
                    value={classTypeSelect}
                    onChange={onChangeClassType}
                >
                    <MenuItem value={"O"}>
                        <Chip
                            label={"Owner"}
                            sx={{
                                color: "white",
                                width: "100px",
                                backgroundColor: getClassColor("O")
                            }}
                        />
                    </MenuItem>
                    <MenuItem value={"M"}>
                        <Chip
                            label={"Manager"}
                            sx={{
                                color: "white",
                                width: "100px",
                                backgroundColor: getClassColor("M")
                            }}
                        />
                    </MenuItem>
                    <MenuItem value={"L"}>
                        <Chip
                            label={"Leader"}
                            sx={{
                                color: "white",
                                width: "100px",
                                backgroundColor: getClassColor("L")
                            }}
                        />
                    </MenuItem>
                    <MenuItem value={"A"}>
                        <Chip
                            label={"Agent"}
                            sx={{
                                color: "white",
                                width: "100px",
                                backgroundColor: getClassColor("A")
                            }}
                        />
                    </MenuItem>
                </Select>

            </TableCell>
            <TableCell component="th" scope="row">
                <Button
                    size='small'

                    sx={{
                        fontSize: smFontSize,
                        height: controllerHeight
                    }}
                    variant='contained' onClick={onSaveRow}>

                    SAVE</Button>
            </TableCell>

        </TableRow>
    );
}

export default UserSettingRow;