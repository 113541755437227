import { Button, TableCell, TableRow, TextField } from '@mui/material';
import { collection, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../service/firebase';
import { allowDotFormatNumber, closedPriceFormat, controllerHeight } from '../const/const';
import NumberFormat from "react-number-format";

// import { closedPriceFormat } from '../const/const';

function ClosedDetailTableRow({ row, index,tableDatas, setTableDatas,classType }) {
  const [closedPrice0, setClosedPrice0] = useState("")
  const [closedPrice1, setClosedPrice1] = useState("")
  const [brokerSplit, setBrokerSplit] = useState("")
  useEffect(
    () => {
      if (row===undefined)return;
      setClosedPrice0(row["closedPrice"].split("~")[0]===undefined?"":row["closedPrice"].split("~")[0])
      setClosedPrice1(row["closedPrice"].split("~")[1]===undefined?"":row["closedPrice"].split("~")[1])
      setBrokerSplit(row["brokerSplit"])

    }, [row]
  )
  const formatNumber = (input) => {
    // 이 부분에 원하는 포맷팅 로직 추가
    // 예를 들어, 세 자리마다 콤마 추가
    const number = parseFloat(input);
    if (!isNaN(number)) {
      return number.toLocaleString('en-US');
    } else {
      return input;
    }
  };
  const handlePrice0 = (e,index,classType) => {
    const inputValue = e.target.value;

    // 숫자와 마이너스 부호만 허용하는 정규식
    const validInput = inputValue.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용

    // 원하는 형식으로 포맷팅
    const formattedValue = allowDotFormatNumber(validInput);
    setClosedPrice0(formattedValue)
    const updatedSelectList=tableDatas[classType]
     
     setTableDatas(prev => {
      const updatedSelectList = prev[classType];
      updatedSelectList[index] = {
        ...updatedSelectList[index],
        closedPrice: formattedValue + "~" + closedPrice1,
      };
     
      return prev;
    });
  }
  const handlePrice1 = (e) => {
    const inputValue = e.target.value;

    // 숫자와 마이너스 부호만 허용하는 정규식
    const validInput = inputValue.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용

    // 원하는 형식으로 포맷팅
    const formattedValue = allowDotFormatNumber(validInput);
    setClosedPrice1(formattedValue)
    setTableDatas(prev => {
      const updatedSelectList = prev[classType];
      updatedSelectList[index] = {
        ...updatedSelectList[index],
        closedPrice: closedPrice0 + "~" + formattedValue,
      };
     
      return prev;
    });
  }
  const handleBrokerSplit = (e,index) => {
    const inputValue = e.target.value;

    // 숫자와 마이너스 부호만 허용하는 정규식
    const validInput = inputValue.replace(/[^\d.]/g, ''); // 숫자와 소수점만 허용

    // 원하는 형식으로 포맷팅
    const formattedValue = allowDotFormatNumber(validInput);
    setBrokerSplit(formattedValue)
    setTableDatas(prev => {
      const updatedSelectList = prev[classType];
      updatedSelectList[index] = {
        ...updatedSelectList[index],
        brokerSplit: formattedValue
      };
     
      return prev;
    });
  }

  return (
    <TableRow key={index} >
      <TableCell component="th" scope="row" >
 {index + 1}
        
      </TableCell>
      <TableCell component="th" scope="row"  >
        <div
        
        style={{
          display:"flex"
        }}
        ><TextField

          size='small'
          sx={{

            width: "160px",
          }}
          onChange={(e)=>handlePrice0(e,index,classType)}
          value={closedPrice0 === "" ? closedPrice0 : "$" + closedPrice0}
          inputProps={{
      style:{
        height:"10px"
      },
            inputMode: 'numeric',
            pattern: '[0-9]*',

          }}
        />
        
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2px",
          }}
        >
          ~
        </div>
        <TextField
          size='small'
          sx={{
            width: "160px",
          }}
          onChange={(e)=>handlePrice1(e,index)}
          inputProps={{
            style:{
              height:"10px"
            },
            inputMode: 'numeric',
            pattern: '[0-9]*',

          }}
          value={closedPrice1 === "" ? closedPrice1 : "$" + closedPrice1}
        /></div>
     
      </TableCell>
      <TableCell component="th" scope="row">
        <TextField
          size='small'
          sx={{
            width: "160px",
          }}
          inputProps={{
            style:{
              height:"10px"
            },
            inputMode: 'numeric',
            pattern: '[0-9]*',

          }}
          onChange={(e)=>handleBrokerSplit(e,index)}
          value={brokerSplit === "" ? brokerSplit : "$" + brokerSplit}
        />
      </TableCell>
    </TableRow>
  );
}

export default ClosedDetailTableRow;