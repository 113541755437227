import React from 'react';
import TxtfieldRow from '../components/TxtfieldRow';
import "../App.css"
import { Card, CardContent } from '@mui/material';
import { useRecoilState } from 'recoil';
import { buyerBrokerNameState } from '../atom/atom';

export default function BuyerAgentInfo({
  isLease,
  disabled,
  brokerName,buyerAgentName,cellPhone,email,buyerName
,setBrokerName,setBuyerAgentName,setBuyerName,setPhone,setEmail}) {
    
  const onChangeBrokerName=(e)=>{

    setBrokerName(e.target.value)
  }
  const onChangeAgentName=(e)=>{
    setBuyerAgentName(e.target.value)
  }
  const onChangePhone=(e)=>{
    setPhone(e.target.value)
  }
  const onChangeEmail=(e)=>{
    setEmail(e.target.value)
  }
  const onChangeName=(e)=>{
    setBuyerName(e.target.value)
  }
  return (
        <Card sx={{width:"100%"}} variant='outlined'>
        <CardContent >
        <div className='ContentBox'>
            <div>{isLease?"Tenant":"Buyer"} Agent Information</div>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Broker Name(Firm name)"} value={brokerName} onChange={onChangeBrokerName}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={`${isLease?"Tenant":"Buyer"} Agent Name`} value={buyerAgentName} onChange={onChangeAgentName}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Cell Phone"} value={cellPhone} onChange={onChangePhone}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Email Address"} value={email} onChange={onChangeEmail}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={`${isLease?"Tenant":"Buyer"} Name`} value={buyerName} onChange={onChangeName}/>
      </div>
        </CardContent>
        </Card>
    );
}
