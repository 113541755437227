import { Button, Card, Checkbox, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TxtfieldRow from './TxtfieldRow';
import TxtAreaRow from './TxtAreaRow';
import CommunityFileUploader from './CommunityFileUploader';
import NoticeFileDownloadBtn from './NoticeFileDownloadBtn';
import IconButton from '@mui/material/IconButton';
import { IoTrash } from 'react-icons/io5';
import { useRecoilState } from 'recoil';
import { mainLoadingAtom } from '../atom/atom';
import { useLocation } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../service/firebase';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { FaLongArrowAltLeft } from "react-icons/fa";
function NoticeContentsDialog({
  row,
  type,
  rowOriginFileList,
  rowOriginUrlList,
  handleFileDownload,
  urlListForDownload,
  disable,
  open,
  handleClose,
  title,
  handleTitleChange,
  todayFullDate,
  content,
  // originFileList,
  // setOriginFileList,
  handleContentChange,
  fileLoader,
  handleFileData,
  handleDelFileLoader,
  handleAddFileLoader,
  filesForDb
  // setNoticeForDb,
}) {
  const theme = useTheme();
  const pjDatasCollection = collection(db, "pjDatas")
  const [deleteList, setDeleteList] = useState([]);
  const [originFileList, setOriginFileList] = useState([]);
  const [mainLoading, setMainLoading] = useRecoilState(mainLoadingAtom)
  const [allChk, setAllChk] = useState(false)
  const location = useLocation()
  const userType = location.state.userType
  const nowUserId = location.state.id
  useEffect(() => {
    // setOriginFileList([])
    // row.urlList.forEach(async (item, idx) => {
    //   setOriginFileList(prev => [...prev, { url: item, id: row.id + `_${idx}`, name: row.filenamelist[idx] }]);
    // });
    const newUrlList = row.urlList.map((item, idx) => { return { url: item, id: row.id + `_${idx}`, name: row.filenamelist[idx] } })
    setOriginFileList(newUrlList)
  }, [row]);

  const onCheckAll = (e) => {
    setAllChk(e.target.checked)

  };
  useEffect(() => {
    if (allChk) {
      const allItemList = originFileList.map(item => item.id)
      setDeleteList(allItemList);
    } else {
      setDeleteList([]);
    }

  }, [allChk])
  const getDownUrl = async (file, path) => {
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    await uploadTask;

    // 파일 업로드 후 다운로드 URL 가져오기
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };
  const setNoticeForDb = async () => {
    setMainLoading(true)
    const rowId = row.id;
    const adminId = row.adminId;
    const boardName = type === 0 ? "ann" : "ins";
    const path = `${boardName}/${nowUserId}/${rowId}/`;

    const urlList = [];
    for (let i = 0; i < filesForDb.length; i++) {
      const file = filesForDb[i];
      let downUrl = "";
      if (file !== null) {
        downUrl = await getDownUrl(file, path + file.name);
      }
      urlList.push(downUrl);
    }
    const newObj = {
      id: rowId,
      adminId: adminId,
      title: title,
      todayFullDate: todayFullDate,
      content: content,
      filenamelist: [...originFileList.map(of => of.name), ...fileLoader],
      urlList: [...originFileList.map(of => of.url), ...urlList],
    };

    const docRef = doc(pjDatasCollection, nowUserId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();

      // 'ann' 또는 'ins'가 Firestore 문서의 배열이라고 가정
      const newArray = existingData[type === 0 ? 'ann' : 'ins'].map((item) => {
        if (item.id === rowId && item.adminId === adminId) {
          // 기존 항목을 업데이트
          return newObj;
        } else {
          return item;
        }
      });

      if (type === 0) {
        await updateDoc(docRef, {
          ann: newArray,
        });
      }
      if (type === 1) {
        await updateDoc(docRef, {
          ins: newArray,
        });
      }
    }
    setMainLoading(false)
    handleClose()
  };
  const onCheck = (rowId) => {
    const idToAdd = rowId
    // 체크되어 있지 않은 경우, 해당 아이디를 삭제
    if (deleteList.includes(idToAdd)) {
      setDeleteList(prevList => prevList.filter(id => id !== idToAdd));
    } else {
      // 체크되어 있지 않은 경우, 해당 아이디를 추가
      setDeleteList(prevList => [...prevList, idToAdd]);
    }
    if (deleteList.length === originFileList.length) {
      setAllChk(true)
    }
  };

  const handleDeleteFiles = () => {
    const updatedFileList = originFileList.filter(file => !deleteList.includes(file.id));
    setOriginFileList(updatedFileList);
    setDeleteList([]); // Clear deleteList after deletion
    setAllChk(false)
  };
  return (
    <Dialog

      maxWidth={"xl"}
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Edit`}</DialogTitle>
      <DialogContent>
        <TxtfieldRow
          title={"Title"}
          readOnly={disable}
          value={title}
          onChange={handleTitleChange}
          titleWidth='70px' width="100%" containerWidth='100%' />
        <div style={{ height: "16px" }} />
        <TxtfieldRow
          readOnly
          value={todayFullDate}
          title={"Date"}
          titleWidth='70px' width="100%" containerWidth='100%' />
        <div style={{ height: "16px" }} />
        <TxtAreaRow
          readOnly={disable}
          value={content}
          onChange={handleContentChange}
          title={"Content"}
          titleWidth='70px' width="100%" containerWidth='100%' />
        <div style={{ height: "16px" }} />

        {originFileList.filter(v => v.url !== "").length != 0 && <Card variant='outlined'>
          <CardContent>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Typography variant='h6'>Uploaded Files</Typography>
              {<NoticeFileDownloadBtn
                handleFileDownload={handleFileDownload}
                rowUrlList={originFileList.filter(url => url.url != "").map(fi => fi.url)}
                rowFileNameList={originFileList.filter(fn => fn.url != "").map(fi => fi.name)} />}
              <Alert icon={<FaLongArrowAltLeft />}
              variant='filled'
              severity="info">
                Click the left button to download the uploaded file.!
              </Alert>
              
            </div>
            {userType === "admin" && <div
            style={{
              width:"100%",
              display:"flex",
              alignItems:"center"
            }}
            ><Checkbox
              checked={allChk}
              indeterminate={deleteList.length != 0 && deleteList.length != originFileList.length}
              onChange={onCheckAll} />
              All Delete 
            {userType === "admin" && <IconButton onClick={handleDeleteFiles}><IoTrash size={20} /></IconButton>}
            <Alert icon={<FaLongArrowAltLeft />}
              variant='filled'
              severity="error">
                Click the left button to delete the uploaded file.!
              </Alert>
              </div>}
            <ul style={{ margin: 0, padding: 0, listStyle: "none", display: "flex", flexDirection: "column", gap: "8px" }}>
              {
                originFileList.filter(v => v.url !== "").map((originF, originFIdx) =>
                  <li
                    key={originF.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      paddingBottom: "5px",
                      borderBottom: `1px solid ${theme.palette.grey[400]}`
                    }}
                  >
                    {(originF.url !== "" && userType === "admin") && <Checkbox
                      id={originF.id}
                      checked={deleteList.includes(originF.id)} onChange={() => onCheck(originF.id)} />}
                    {originF.url !== "" && originF.name}
                  </li>
                )
              }
            </ul>
          </CardContent>
        </Card>}
        <div style={{ height: "16px" }} />
        {fileLoader.map((fl, flIdx) =>
          <div key={flIdx}>
            <CommunityFileUploader
              downloadDisable={fl === ""}
              // onDownload={() => {
              //   handleFileDownload(fl.url, fl.name);
              // }}
              readOnly={disable}
              handleFileData={handleFileData}
              fileName={fl}
              fileLoaderIdx={flIdx}
              handleDelFileLoader={handleDelFileLoader}
            />
            <div style={{ height: "16px" }} />
          </div>
        )}
        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end"
        }}>
          <Button variant='outlined'
            disabled={disable}
            sx={{ width: "100%" }} onClick={handleAddFileLoader}>ADD FILE</Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disable}
          onClick={setNoticeForDb}>SAVE</Button>
        <Button onClick={handleClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
}

export default NoticeContentsDialog;
