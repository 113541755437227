import { TextField } from '@mui/material';
import React from 'react';

function OtherTxtFieldRow({
    disabled=false,
    title,money,onChangeMoney,value,titleWidth="500px",onChange,width="220px",containerWidth="800px"}) {
    return (
        <div
        style={{
            display:"flex",
alignItems:"center"
        }}
        >
            <div style={{fontSize:"16px",display:"flex",width:titleWidth,justifyContent:"flex-end",marginRight:"2px"}}>{title+" : "}</div>  
            <TextField 
            disabled={disabled}
            sx={{ width: width }}
      size='small'
        value={value}
onChange={onChange}
/>
           <div style={{
  
            width:containerWidth,display:"flex",marginRight:`calc(${width} + 34px)`}}>
            {/* <TextField sx={{ width: width }}
      size='small'
        value={value}
onChange={onChange}
/> */}
<div style={{
    width:"20px",
    display:"flex",
    alignItems:"flex-end",
    justifyContent:"center"
}}>,</div>
<TextField 
disabled={disabled}
sx={{ width: width }}
      size='small'
        value={money}
onChange={onChangeMoney}
/>

</div> 
        </div>
    );
}

export default OtherTxtFieldRow;