import React, { useEffect, useRef, useState } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import M from '../images/commercial/commercial-main.jpg'
import './Purchase.css'
import { useTranslation } from 'react-i18next';
function Commercial(props) {
    const {t} = useTranslation();
         // ref를 생성하여 purchase-txt 구간을 참조
         const purchaseTxtRef = useRef(null);
         const [fadeIn, setFadeIn] = useState(false);
        
         // 버튼 클릭 시 스크롤 이벤트 처리
         const handleScrollToSection = () => {
             if (purchaseTxtRef.current) {
                 purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
             }
         }; 
         useEffect(() => {
              setTimeout(() => setFadeIn(true), 200); // 약간의 지연 후 애니메이션 시작
          }, []);
    return (
        <div>
            <PC>
            <div className={`remodeling-area ${fadeIn ? 'fade-in' : ''}`}>
                {/* 맨위에 이미지랑 글자 */}
<div className="main-banner-txts">
        <div className="banner-txt-top">
       <div className="column"
       style={{
        display:"flex",
        flexDirection:"column"
       }}
       >
       <span>
       {t("commercial.bannerTop1")}
        </span>
        <span>
            <strong
            style={{
                fontSize:"55px"
            }}
            >
            {t("commercial.bannerTopStrong")}
            </strong>
        </span>
       </div>
        </div>
        <div className="banner-txt-bottom">
        {t("commercial.bannerBottom")}
        </div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
    </div>
    <div className="remodeling-img">
    <img src={M}
    style={{
        width:"800px"
    }}
    />

    </div>
</div>
<div className="staging-txt"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
        whiteSpace:"nowrap"
    }}
    >{t("commercial.commercialTitle")}</strong>
    <div className="staging-txt-bottom"
    
    >
   <p>
   {t("commercial.content1")}
    </p>
   <p>
   {t("commercial.content2")} </p>
   <p>
   {t("commercial.content3")}
    </p>
    </div>

</div>
            </PC>
            <Mobile>
            <div className="remodeling-area-mb">
                {/* 맨위에 이미지랑 글자 */}
                <div className="remodeling-img-mb">
    <img src={M}
    style={{
        transform:"translateX(-300px)",
        width:"800px"
    }}
    />

    </div>
<div className="main-banner-txts-mb">
        <div className="banner-txt-top-mb">
     
       <span
       style={{
        // whiteSpace:"nowrap"
       }}
       >
           {t("commercial.bannerTop1")}
        </span>
        <span>
            <strong
            style={{
                    // whiteSpace:"nowrap",
                fontSize:"36px"
            }}
            >
             {t("commercial.bannerTopStrong")}
            </strong>
        </span>
        </div>
        <div className="banner-txt-bottom-mb">
        {t("commercial.bannerBottom")}</div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
    </div>
   
</div>
<div className="staging-txt-mb"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
    }}
    >{t("commercial.commercialTitle")}</strong>
    <div className="staging-txt-bottom"
    
    >
   <p>
   {t("commercial.content1")}
    </p>
   <p>
   {t("commercial.content2")}
   </p>
   <p>
   {t("commercial.content3")} </p>
    </div>

</div>
            </Mobile>
        </div>
    );
}

export default Commercial;