import { useTheme } from '@emotion/react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import UserSettingRow from './UserSettingRow';
import { db } from '../service/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { basicTableHeight } from '../const/const';
import AdminTableLoader from './AdminTableLoader';
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
function UserSettingTable({deleteList,setDeleteList, searchClick, tableDatas, userIdList, approveValue, userTypeList }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filteredRow, setFilteredRow] = React.useState([])

  const filterRows = (originRows) => {
    //     if (!isFilter) {
    //       return originRows;
    //     }
    // else{
    return originRows.filter((row) => {
      const stringApprove = row.approve === true ? "true" : "false"
      const userIdMatch = userIdList.length === 0 ? true : userIdList.includes(row.id);
      const approveMatch = approveValue === "" || approveValue === null ? true : approveValue === stringApprove;
      const userTypeMatch = userTypeList.length === 0 ? true : userTypeList.includes(row.userType);

      const res = userIdMatch && approveMatch && userTypeMatch;
      return res;
    });

  };
  

  useEffect(() => {
    setFilteredRow(filterRows(tableDatas))
  }, [tableDatas])
  useEffect(() => {
    // if (tableDatas === undefined ||  tableDatas === null) return
    setFilteredRow(filterRows(tableDatas))


  }, [searchClick])


  return (
    filteredRow === null || filteredRow === undefined || Object.values(tableDatas).length === 0 ? <TableContainer variant='outlined'

      component={Paper} sx={{
        height: basicTableHeight,
        width: "100%"
      }}>

      Loading...
    </TableContainer> :
      <TableContainer
        variant='outlined'

        component={Paper} sx={{
          height: basicTableHeight,
          width: "100%"
        }}>


        <Table
          stickyHeader
          size='small'
          sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead
          >
            <TableCell style={{ width: 30 }} scope="row" >
              Delete
            </TableCell> <TableCell style={{ width: 30 }} scope="row" >
              Number
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row" >
              Name
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row" >
              User ID
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row" >
              Approve
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row" >
              User Type
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row" >
              Split Class Typedd
            </TableCell>
            <TableCell style={{ width: 160 }} scope="row" >
              Save
            </TableCell>
          </TableHead>

          <TableBody>

            {filteredRow.reverse().map((row, index) => {

              return ( 
                <UserSettingRow
                length={filteredRow.length}
                  deleteList={deleteList}
                  setDeleteList={setDeleteList}
                  row={row}
                  index={index}
                />
              )
            })}
          </TableBody>

        </Table>
      </TableContainer>
  )
}

export default UserSettingTable;