import { Button, TextField, TextareaAutosize } from '@mui/material';
import React from 'react';

function TxtFieldWithBtn({onClick,readOnly=false,title,value,onChange,onBlur,titleWidth="500px",width="200px",containerWidth="800px"}) {
    return (
        <div
        style={{
            display:"flex",
            flexDirection:"row",
alignItems:"center",gap:"8px"
        }}
        >
            <div style={{fontSize:"16px",display:"flex",width:titleWidth,alignItems:"center",justifyContent:"flex-end"}}>{title+" : "}</div>  
           <div style={{width:containerWidth}}>
            
            <TextField sx={{ width: width ,marginRight:"20px"}}
      size='small'
        value={value}
    disabled={readOnly}
onChange={onChange}
onBlur={onBlur}
/>
<Button variant='contained'
sx={{
    width:"80px"
}}

color='success' onClick={onClick}>CALC</Button>
</div> 
        </div>
    );
}

export default TxtFieldWithBtn;