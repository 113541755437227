import { Typography } from "@mui/material";
import CustomAppBar from "./components/CustomAppBar";
import "./App.css"
import CustomPaginationActionsTable from "./components/CustomPaginationActionsTable";

function App() {
  return (
    <div className="App">
      <CustomAppBar />
      {/* 사진 업로드하는 게시판 ui */}
      <div className="ContentBox">
        <CustomPaginationActionsTable/>
      </div>
    </div>
  );
}

export default App;
