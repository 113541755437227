import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, CardHeader, IconButton, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompanyAnnouncementsTable from './NoticeTable';
// import CompanyAnnouncementsWriteDialog from './CompanyAnnouncementsWriteDialog';
import NoticeShowDialog from './NoticeShowDialog';
import { useLocation } from 'react-router-dom';
import NoticeWriteDialog from './NoticeWriteDialog';
import NoticeTable from './NoticeTable';
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import { mainLoadingAtom } from '../atom/atom';
import { useRecoilState } from 'recoil';
import FilterSearchBar from './FilterSearchBar';

export default function NoticeBoard({type=0}) {
  const theme=useTheme()
  // type가 0이면 announcements 1이면 instructions
  const [openShow,setOpenShow]=useState(false)
  const [openWrite,setOpenWrite]=useState(false)
  const [tableRows,setTableRows]=useState([])
  const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
  const [deleteList,setDeleteList]=useState([])
  const [searchTxt,setSearchTxt]=useState("")
  const [searchedTable,setSearchedTable]=useState([])
  const pjDatasCollection = collection(db, "pjDatas")
  const location=useLocation()
  const userType=location.state.userType
  const nowId=location.state.id
  async function getDocumentsWithCommunityField() {
    const collectionRef = collection(db, "pjDatas");

    try {
      const boardName=type===0?"ann":"ins"
        const querySnapshot = await getDocs(query(collectionRef, where(boardName, "!=", null)));
const arr=[];
        querySnapshot.forEach((doc) => {
            const boardData = doc.data()[boardName];
            arr.push(boardData)
            // 이후에 가져온 데이터를 사용할 수 있습니다.
        });
        setTableRows(arr.flat().reverse())
        setSearchedTable(arr.flat().reverse())
    } catch (error) {
    }
}

  // useEffect(()=>{
  
  //     // 함수 호출
  //     getDocumentsWithCommunityField();
    
    
  //     },[openWrite,openShow,mainLoading])
  const handleShowClickOpen=()=>{

    setOpenShow(true)
  }
  const handleShowClose=()=>{

    setOpenShow(false)
       // 함수 호출
  }
  
  
  const onDelete = async () => {
    //deleteList empty면 리턴
    if (deleteList.length === 0) return;
    //게시판 로우 딜리트 하기
    const res = window.confirm("Do you want to delete the selected user information?");
    if (res) {
        try {
            let updateRows = [];
            for (let i = 0; i < tableRows.length; i++) {
                const tr = tableRows[i];
                let shouldDelete = false;
                for (let j = 0; j < deleteList.length; j++) {
                    const delId = deleteList[j];
                    if (tr.id === delId) {
                        shouldDelete = true;
                        break;
                    }
                }
                if (!shouldDelete) {
                    updateRows.push(tr);
                }
            }
            // 새로운 데이터로 tableRows 업데이트
            const docRef = doc(pjDatasCollection, nowId);
            if (type === 0) {
              await updateDoc(docRef, {
                ann: updateRows,
              });
            }
            if (type === 1) {
              await updateDoc(docRef, {
                ins: updateRows,
              });
            }
            setTableRows(updateRows);
            setSearchedTable(updateRows)
setDeleteList([])
        } catch (error) {
            window.confirm("error");
        }  
    } else {
        // 사용자가 취소한 경우 아무 작업도 수행하지 않음
    }
  
};
// useEffect(()=>{

//   getDocumentsWithCommunityField()
// },[openWrite])
useEffect(()=>{
if (openWrite)return;
getDocumentsWithCommunityField()

},[openWrite])
const onChangeSearchTxt=(e)=>{
  setSearchTxt(e.target.value)
}
const onSearch=(searchT)=>{

const searchedRows=tableRows.filter(tr=>tr.title.includes(searchT))
setSearchedTable(searchedRows)
}
    return (
        <Card 
      sx={{
        padding:"8px",
        paddingBottom:"0px",
        width:"100%"
      }}
        >
         <div
         style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}
         ><Typography variant='body1' >{type===0?"Company Announcements":"Company Instructions"}</Typography>
         <div>
          <div style={{display:"flex",alignItems:"center",paddingRight:"8px"}}> 
          {/* 게시판 서치바 */}
          <TextField
          value={searchTxt}
          onChange={onChangeSearchTxt}
          placeholder='Search Title'
          size='small'
          sx={{
            width:"500px"
          }}
          />
          <div style={{width:"16px"}}/>
         <Button
         size='small' variant='contained' color='success'
         onClick={()=>onSearch(searchTxt)}
         >SEARCH</Button>
          <div style={{width:"16px"}}/>
          <div
          style={{
            backgroundColor:theme.palette.grey[400],
            height:"30px",
            width:"2px"}}
          />
          <div style={{width:"16px"}}/>
          {/* 삭제하는 버튼 */} 
          {userType==="admin"&&<Button size='small' variant='contained' color='error' onClick={onDelete}>DELETE</Button>}
         <div style={{width:"16px"}}/>
         {userType==="admin"&& <NoticeWriteDialog
         
          type={type}
          open={openWrite}
          setOpenWrite={setOpenWrite}
          handleClickOpen={()=>{setOpenWrite(true)}}
          handleClose={()=>{
            getDocumentsWithCommunityField()
            setOpenWrite(false)}}
        
          />}
          </div>
         
         
          </div>
          
          </div>
          
          <CardContent
          sx={{padding:"8px"}}
          ><NoticeTable 
          getDocumentsWithCommunityField={getDocumentsWithCommunityField}
          deleteList={deleteList}
          setDeleteList={setDeleteList}
          type={type} open={openShow}  tableRows={searchedTable}/></CardContent>
     
      </Card>
    );
}
