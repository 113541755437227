import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UploadTable from './UploadTable';
import { Paper, Table, TableBody, TableContainer, TextField, Typography, useTheme } from '@mui/material';
import { useRecoilState } from 'recoil';
import { filesState, mainLoadingAtom, temp5FileState, uploadListPerRowState } from '../atom/atom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db, storage } from '../service/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import CustomFileInput from './CustomFileInput';
import UploadTableForEdit from './UploadTableForEdit';
import UploadRowForEdit from './UploadRowForEdit'
import PDFViewer from './PDFViewer';
import { Page,Document } from 'react-pdf';
export default function PDFViewerDialog({
 
  disabled,
pdfFile,}) {  
  // const scrollRef=React.useRef(null)
  const theme=useTheme()
  const [open, setOpen] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1); // 현재 페이지
  const [numPages, setNumPages] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    
  };

  // const handleScroll = (event) => {
  //        const target = event.target;
  //        const scrollBottom =  event.target.scrollTop >= event.target.scrollHeight - event.target.clientHeight
  //        console.log("스크롤>>>",)
  //        if (scrollBottom) {
  //            console.log("스크롤 바닥에 닿임>>>")
  //            setPageNumber(numPages === pageNumber ? pageNumber : pageNumber + 1)
  //        //   setScrollIdx((prevIdx) => prevIdx + 2); // Increase index by 2
  //        }
  //      };
  //  console.log()
return <>     

 
  <Button 
      disabled={disabled}
      variant='contained' 
      
       onClick={handleClickOpen}
  

  >
 SHOW PDF
  </Button>
     

      <div></div>
      <Dialog
      fullWidth={true}
maxWidth={"lg"}

        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
     
        <DialogContent
          //  ref={scrollRef}
          //  onScroll={handleScroll}
        sx={{
            display:"flex",
            width:"100%",
            height: "800px", // 다이얼로그의 높이를 100%로 설정
            alignItems:"flex-start",
            justifyContent:"center"
        }}
        >
  {/* <PDFViewer pdfFile={pdfFile} pageNumber={pageNumber} setPageNumber={setPageNumber} numPages={numPages} setNumPages={setNumPages}/> */}
  <iframe src={pdfFile}
  style={{
    width: "100%", // iframe의 너비를 100%로 설정하여 다이얼로그에 꽉 차게 함
    height: "100%", // iframe의 높이도 100%로 설정하여 다이얼로그에 꽉 차게 함
    border: "none", // iframe의 테두리를 없애서 깔끔하게 보이도록 함
  }}
  >  Your browser does not support iframes.</iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>

}
