import { Button, Checkbox, Chip, IconButton, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FaDownload } from 'react-icons/fa6';
import { IoIosImages } from 'react-icons/io';
import NoticeFileDownloadBtn from './NoticeFileDownloadBtn';
import { useLocation } from 'react-router-dom';
import NoticeEditDialog from './NoticeEditDialog';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useRecoilState } from 'recoil';
import { mainLoadingAtom } from '../atom/atom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../service/firebase';
import NoticeContentsDialog from './NoticeContentsDialog';
import { CheckBox } from '@mui/icons-material';

function NoticeRow({
  getDocumentsWithCommunityField,
  deleteList,
  setDeleteList,
  index, row, type, dialogTitle }) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("")
  // const [originFileList, setOriginFileList] = React.useState([]);
  const [urlListForDownload, setUrlListForDownload] = React.useState([])
  const [todayFullDate, setTodayFullDate] = React.useState("")
  const [content, setContent] = React.useState("")
  const [fileLoader, setFileLoader] = React.useState([""])
  const [filesForDb, setFilesForDb] = React.useState([null])
  const [mainLoading, setMainLoading] = useRecoilState(mainLoadingAtom)
  const [chk, setChk] = useState(false)

  const pjDatasCollection = collection(db, "pjDatas")
  const location = useLocation()
  const userType = location.state.userType
  const nowUserId = location.state.id
  const editDisable = !(userType === "admin" );
  React.useEffect(() => {
    if (row === undefined) return;
    if (!open)return
    setTitle(row.title)
    setTodayFullDate(row.todayFullDate)
    setContent(row.content)
    // setUrlListForDownload(row.urlList)
    // urlList가 존재하는 경우에만 처리
    // if (row.urlList && Array.isArray(row.urlList)) {
    //   row.urlList.forEach((item, idx) => {
    //       const existingFileLoader = fileLoader.find(loader => loader.url === item);
    //       if (!existingFileLoader) {
    //           setFileLoader(prevFileLoaders => [
    //               ...prevFileLoaders,
    //              row.filenamelist[idx]
    //           ]);
    //       }
    //   });}
  }, [open])
  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }
  const handleContentChange = (e) => {
    setContent(e.target.value)
  }
  const handleAddFileLoader = () => {
    setFileLoader(p => {
      const copyArr = [...p]
      copyArr.push("")
      return copyArr
    }
    )
    setFilesForDb(p => {
      const copyArr = [...p]
      copyArr.push(null)
      return copyArr
    }
    )
  }

  const handleClose = async () => {
    // 테이블 정보 가져오기& 파일로더 컴포넌트 초기화
    getDocumentsWithCommunityField()
    setFileLoader([""])
    setFilesForDb([null])
    setOpen(false);
  };
  const handleDelFileLoader = (fileLoaderIdx) => {
    setFileLoader(p => {
      const copyArr = [...p]
      const res = copyArr.filter((v, idx) => idx != fileLoaderIdx)
      return res
    }
    )
    setFilesForDb(p => {
      const copyArr = [...p]
      const res = copyArr.filter((v, idx) => idx != fileLoaderIdx)
      return res
    }
    )
  }
  const handleFileData = (e, flIdx) => {
    const uploadFile = e.target.files[0]
    if (fileLoader.includes(uploadFile.name)) {
      window.confirm("You cannot upload files with the same file name.")
      return;
    } else {
      setFileLoader(p => {
        const copyArr = [...p]
        copyArr[flIdx] = uploadFile.name

        return copyArr
      }
      )
      setFilesForDb(p => {
        const copyArr = [...p]
        copyArr[flIdx] = uploadFile

        return copyArr
      }
      )
    }

  }
//   const handleFileData = (e, flIdx) => {
//     const uploadFile = e.target.files[0];
//     const fileName = uploadFile.name;

//     // 파일 이름이 중복되는지 확인
//     if (fileLoader.some(loader => loader.name === fileName)) {
//         window.confirm("You cannot upload files with the same file name.");
//         return;
//     } else {
//         // 새로운 파일 로더 객체 생성
//         let newUrl = "";

//         // 파일을 읽고 URL을 가져오기
//         var reader = new FileReader();
//         reader.onload = function(e) {
            
//           newUrl= e.target.result;
// console.log("new url>>>",newUrl)
//             // fileLoader 배열 업데이트
//             //url부분 db에 저장한다음 가져오는걸로 바꾸기->용량 에러 남
//             setFileLoader(prevFileLoaders => {
//                 const copyArr = [...prevFileLoaders];
//                 copyArr[flIdx] = {url:"",name:fileName};
//                 return copyArr;
//             });
//         };
//         reader.readAsDataURL(uploadFile); // 파일을 읽기 시작

//         // filesForDb 배열 업데이트
//         setFilesForDb(prevFilesForDb => {
//             const copyArr = [...prevFilesForDb];
//             copyArr[flIdx] = uploadFile;
//             console.log("파일디비>>>",copyArr)
//             return copyArr;
//         });
//     }
// };


  const getDownUrl = async (file, path) => {
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    await uploadTask;

    // 파일 업로드 후 다운로드 URL 가져오기
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };
//   const setNoticeForDb = async () => {
//     setMainLoading(true)
//     const rowId = row.id;
//     const adminId = row.adminId;
//     const boardName = type === 0 ? "ann" : "ins";
//     const path = `${boardName}/${nowUserId}/${rowId}/`;

//     const urlList = [];
//     for (let i = 0; i < filesForDb.length; i++) {
//       const file = filesForDb[i];
//       let downUrl = "";
//       if (file !== null) {
//         downUrl = await getDownUrl(file, path + file.name);
//       }
//       urlList.push(downUrl);
//     }
// console.log("원래파일리스트 건드린거>>>",originFileList.map(of=>of.name))
//     const newObj = {
//       id: rowId,
//       adminId: adminId,
//       title: title,
//       todayFullDate: todayFullDate,
//       content: content,
//       filenamelist: [...originFileList.map(of=>of.name),...fileLoader],
//       urlList: [...originFileList.map(of=>of.url),...urlList],
//     };

//     const docRef = doc(pjDatasCollection, nowUserId);
//     const docSnapshot = await getDoc(docRef);

//     if (docSnapshot.exists()) {
//       const existingData = docSnapshot.data();

//       // 'ann' 또는 'ins'가 Firestore 문서의 배열이라고 가정
//       const newArray = existingData[type === 0 ? 'ann' : 'ins'].map((item) => {
//         if (item.id === rowId && item.adminId === adminId) {
//           // 기존 항목을 업데이트
//           return newObj;
//         } else {
//           return item;
//         }
//       });

//       if (type === 0) {
//         await updateDoc(docRef, {
//           ann: newArray,
//         });
//       }
//       if (type === 1) {
//         await updateDoc(docRef, {
//           ins: newArray,
//         });
//       }
//     }
//     setMainLoading(false)
//     setOpen(false)
//   };
  const handleClickOpen = async () => {
    // if (userType==="admin"&&nowUserId===row.adminId) {
    setOpen(true);
    // }else{
    //     return
    // }

  };
  const onChangeChk = (rowId) => {
    const idToAdd = rowId
    // 체크되어 있지 않은 경우, 해당 아이디를 삭제
    if (deleteList.includes(idToAdd)) {
      setDeleteList(prevList => prevList.filter(id => id !== idToAdd));
    } else {
      // 체크되어 있지 않은 경우, 해당 아이디를 추가
      setDeleteList(prevList => [...prevList, idToAdd]);
    }
  }
  const handleFileDownload=async(fileUrl,fileName)=>{

    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
  
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName; // You can customize the downloaded file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
      }
}
  return (
    //row의 높이 44.35px
    <>
      <NoticeContentsDialog
      //원래 가지고 있는 urlList
    row={row}
    type={type}
//     originFileList={originFileList}
// setOriginFileList={setOriginFileList}
      handleFileDownload={handleFileDownload}
        urlListForDownload={urlListForDownload}
        disable={editDisable}
        title={title}
        content={content}
        dialogTitle={dialogTitle}
        fileLoader={fileLoader}
        handleAddFileLoader={handleAddFileLoader}
        handleClose={handleClose}
        handleContentChange={handleContentChange}
        handleDelFileLoader={handleDelFileLoader}
        handleFileData={handleFileData}
        handleTitleChange={handleTitleChange}
        open={open}
        filesForDb={filesForDb}
        // setNoticeForDb={setNoticeForDb}
        todayFullDate={todayFullDate}

      />
      <TableRow
        sx={{
          maxHeight: "20px",
          cursor: "pointer",
          width:"100%"
        }}>
        {!editDisable && <TableCell
          width={"60px"}
          sx={{
            whiteSpace: "nowrap",

          }}
        ><Checkbox color='error'
          checked={deleteList.includes(row.id)}
          onChange={() => onChangeChk(row.id)} /></TableCell>}
        <TableCell
          width={"60px"}
          onClick={handleClickOpen}
          sx={{
            whiteSpace: "nowrap",

          }}
        >{index === 1 && <Chip size='small' color='success' label="New" />}</TableCell>
        <TableCell width={"100%"}
          onClick={handleClickOpen}
          sx={{


            maxWidth: "200px",
            whiteSpace: "nowrap",
            height: "20px",
            maxHeight: "20px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}

        >

          {row.title}</TableCell>
        {/* <TableCell width={"100%"}
        sx={{
          

            maxWidth:"200px",
            whiteSpace: "nowrap" ,
            height:"20px",
    maxHeight:"20px",
        textOverflow:"ellipsis",    
            overflow:"hidden",
        }}

        >
            
            {row.content}</TableCell> */}
        <TableCell

        >

          {row.urlList.filter(url => url != "").length != 0 ? <NoticeFileDownloadBtn
          
          handleFileDownload={handleFileDownload}
          rowUrlList={row.urlList.filter(url => url != "")}
            rowFileNameList={row.filenamelist.filter(fn => fn != "")} /> : <IconButton
              disabled={true}
              style={{
                opacity: 0
              }}
            ><FaDownload size={16} /></IconButton>}</TableCell>
        {/* {userType==="admin"&&nowUserId===row.adminId?<TableCell> 
                <NoticeEditDialog
                type={type} 
                dialogTitle={row.title}
                row={row} />
              </TableCell>:<TableCell></TableCell>} */}

      </TableRow></>

  );
}

export default NoticeRow;