import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UploadTable from './UploadTable';
import { TextField, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { filesState, mainLoadingAtom, temp5FileState, uploadListPerRowState } from '../atom/atom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { arrayUnion, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db, storage } from '../service/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import CustomFileInput from './CustomFileInput';
import TxtfieldRow from './TxtfieldRow';
import TxtAreaRow from './TxtAreaRow';
import CommunityFileUploader from './CommunityFileUploader';
import { useState } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import LoadingPage from './LoadingPage';
export default function NoticeWriteDialog({

  type,
  handleClickOpen,
handleClose,
setOpenWrite,
  open}) {

  const [title,setTitle]=useState("")
  const [todayFullDate,setTodayFullDate]=useState(moment().format('YYYY-MM-DD HH:mm:ss'))
  const [content,setContent]=useState("")
  const [fileLoader,setFileLoader]=useState([""])
  const [filesForDb,setFilesForDb]=useState([null])
  // const [loading,setLoading]=useState(false)
  const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
  const location=useLocation()
  const nowId=location.state.id
  const pjDatasCollection = collection(db, "pjDatas")
  const getDownUrl = async (file, path) => {
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    await uploadTask;
  
    // 파일 업로드 후 다운로드 URL 가져오기
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };
  const setNoticeForDb = async () => {
    setMainLoading(true)
    const rowId = uuid();
const boardName=type===0?"ann":"ins"
    const path = `${boardName}/${nowId}/${rowId}/`;
  
   
    const urlList = [];
    for (let i = 0; i < filesForDb.length; i++) {
      const file = filesForDb[i];
      let downUrl=""
      if (file !== null){
       downUrl= await getDownUrl(file, path + file.name);
     } urlList.push(downUrl);
    }
  
    // Update the 'community' field in the document
    const newObj = {
      id: rowId,
      adminId: nowId,
      title: title,
      todayFullDate: todayFullDate,
      content: content,
      filenamelist: fileLoader,
      urlList:urlList
    };
if (type===0) {
  await updateDoc(doc(pjDatasCollection, nowId), {
      ann: arrayUnion(newObj)
    });
}if (type===1) {
  await updateDoc(doc(pjDatasCollection, nowId), {
    ins: arrayUnion(newObj)
  });
}
    
    // setTitle("")
    // setContent("")
    // setFileLoader([""])
    // setFilesForDb([null])
    setMainLoading(false)
    setOpenWrite(false)
  };

 const handleTitleChange=(e)=>{
    setTitle(e.target.value)
 }
 const handleContentChange=(e)=>{
    setContent(e.target.value)
 }
 const handleAddFileLoader=()=>{
    setFileLoader(p=>
       { 
        const copyArr=[...p]
        copyArr.push("")
        return copyArr}
        )
        setFilesForDb(p=>
            { 
             const copyArr=[...p]
             copyArr.push(null)
             return copyArr}
             )
 }
 const handleDelFileLoader=(fileLoaderIdx)=>{
setFileLoader(p=>
    {
        const copyArr=[...p]
        const res=copyArr.filter((v,idx)=>idx!=fileLoaderIdx)
        return res}
    )
    setFilesForDb(p=>
        {
            const copyArr=[...p]
            const res=copyArr.filter((v,idx)=>idx!=fileLoaderIdx)
            return res}
        )
 }
 const handleFileData=(e,flIdx)=>{
const uploadFile=e.target.files[0]
if (fileLoader.includes(uploadFile.name)) {
  window.confirm("You cannot upload files with the same file name.")
return ;
} else {
  setFileLoader(p=>
    
    {
        const copyArr=[...p]
        copyArr[flIdx]=uploadFile.name
        
        return copyArr}
    )
    setFilesForDb(p=>
    
        {
            const copyArr=[...p]
            copyArr[flIdx]=uploadFile
            
            return copyArr}
        )
}

 }
 useEffect(() => {
    let animationFrameId;

    const updateClock = () => {
        const m = moment().format('YYYY-MM-DD HH:mm:ss');
        setTodayFullDate(m);
        animationFrameId = requestAnimationFrame(updateClock);
    };

    updateClock();

    return () => {
        cancelAnimationFrame(animationFrameId);
    };
}, [setTodayFullDate]);


useEffect(()=>{
if (!open) {
  setTitle("")
  setContent("")
  setFileLoader([""])
  setFilesForDb([null])
}

},[open])
  return <>
     <Button 
 size='small'
variant='contained'
onClick={handleClickOpen}
 color='warning'sx={{width:"80px"}} >WRITE</Button>
      <Dialog
       maxWidth={"xl"}
       fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${type===0?"Comapny Announcements":"Company Instructions"} Write`}
        </DialogTitle>
        <DialogContent

        >
          
      
       <TxtfieldRow 
        title={"Title"}
        re
        value={title}
        onChange={handleTitleChange}
        titleWidth='70px' width="100%" containerWidth='100%'/>
        <div style={{
            height:"16px"
        }}/>
         <TxtfieldRow 
         readOnly
         value={todayFullDate}
        title={"Date"}
        titleWidth='70px' width="100%" containerWidth='100%'/>
        <div style={{
            height:"16px"
        }}/>
        <TxtAreaRow
        onChange={handleContentChange}
          title={"Content"}
          titleWidth='70px' width="100%" containerWidth='100%'/>
            <div style={{
            height:"16px"
        }}/>
       {fileLoader.map((fl,flIdx)=>
       <div><CommunityFileUploader 
       handleFileData={handleFileData}
       fileName={fl}
       fileLoaderIdx={flIdx} handleDelFileLoader={handleDelFileLoader}/><div style={{
            height:"16px"
        }}/></div>
       )
       }
       <Button variant='outlined' fullWidth onClick={handleAddFileLoader}>ADD FILE</Button>
        </DialogContent>
        <DialogActions>
        <Button onClick={setNoticeForDb}>SAVE</Button>
          <Button onClick={handleClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </>

}
