import { Button, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import React from 'react';
import uuid from 'react-uuid';
import { uploadListPerRowState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { IoTrash } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

function UploadRow({
  
  rowKey,docName,
  docSrc,index,row,updateUploadTable,deleteUploadTable}) {
    // const [file, setFile] = React.useState(null)
    const location=useLocation()
    
    const fileInputRef = React.useRef(null)
    
  // 파일 선택 버튼 클릭 시 파일 입력란 클릭
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
    return (
      <TableRow 
        
      key={index} >
      {/* 업로드 하기전에는 로우 하나에 버튼 한개씩 나오게 */}
      {/* Document name */}
      {/* <TableCell component="th" scope="row">
        Home Warranty Order Confirmation
      </TableCell> */}
      {/* Source of document */}
      {/* <TableCell component="th" scope="row">
        Offer form
      </TableCell> */}
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell component="th" scope="row">
        {/* <Button variant='contained'>File Upload</Button> */}
        <div
          style={{
            display: "flex",
            // width: "500px",
            gap: "16px"
          }}
        >
          <TextField sx={{ width: "100%", }}
            label={ row.name}
          />
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={
            (e)=>{updateUploadTable(e,index,docName,docSrc,rowKey)}}
          />
          <div
         style={{
          width:"40px"
         }} 
          > <img id={`p`} /></div>
         
          <Button 
          id=''
          size='small'
          onClick={handleUploadButtonClick}>UPLOAD</Button> 
          
          
         </div>
     
    
     
      </TableCell>    
      <TableCell>
         <IconButton color='error'
         
         onClick={ (e)=>{deleteUploadTable(e,index,rowKey,docName,docSrc,row.name)} }
         ><IoTrash/></IconButton>
         </TableCell>
    </TableRow>
    );
}

export default UploadRow;