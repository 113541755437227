import React, { useEffect, useRef, useState } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import M from '../images/sale/sale.jpg'
import './Purchase.css'
import { useTranslation } from 'react-i18next';
function Sale(props) {
    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
          setTimeout(() => setFadeIn(true), 200); // 약간의 지연 후 애니메이션 시작
      }, []);
    const {t} = useTranslation();
     // ref를 생성하여 purchase-txt 구간을 참조
     const purchaseTxtRef = useRef(null);

     // 버튼 클릭 시 스크롤 이벤트 처리
     const handleScrollToSection = () => {
         if (purchaseTxtRef.current) {
             purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
         }
     };
    return (
        <div>
            <PC>
            <div className={`remodeling-area ${fadeIn ? 'fade-in' : ''}`}>
                {/* 맨위에 이미지랑 글자 */}
<div className="main-banner-txts">
        <div className="banner-txt-top">
       <div className="column"
       style={{
        display:"flex",
        flexDirection:"column"
       }}
       >
       <span>
        <strong
        style={{
            fontSize:"55px"
        }}> {t("sale.bannerTopStrong")}</strong>
        </span>
        <span>
        {t("sale.bannerTop1")}
        </span>
       <span>
       {t("sale.bannerTop2")}</span>

       </div>
        </div>
        <div className="banner-txt-bottom">
        {t("sale.bannerBottom")}
        </div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
    </div>
    <div className="remodeling-img">
    <img src={M}
    style={{
        transform:"translateY(-200px)",
        width:"800px"
    }}
    />

    </div>
</div>
<div className="purchase-txt"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
        whiteSpace:"nowrap"
    }}
    >{t("sale.saleTitle")}</strong>
    <div className="purchase-txt-bottom"
    
    >
   <p>
   {t("sale.content1")}
   </p>
   <p>
   {t("sale.content2")} </p>
    </div>

</div>
            </PC>
            <Mobile>
            <div className="remodeling-area-mb">
                {/* 맨위에 이미지랑 글자 */}
                <div className="remodeling-img-mb">
    <img src={M}
    style={{
        transform:"translateX(-120px)",
        // width:"800px"
    }}
    />

    </div>
<div className="main-banner-txts-mb">
        <div className="banner-txt-top-mb">
      
       <span>
        <strong
        style={{
            fontSize:"36px"
        }}> {t("sale.bannerTopStrong")}</strong>
        </span>
        <span>
        {t("sale.bannerTop1")}
        </span>
       <span>
       {t("sale.bannerTop2")}</span>
        </div>
        <div className="banner-txt-bottom-mb">
        {t("sale.bannerBottom")}
        </div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
    </div>
    
</div>
<div className="purchase-txt-mb"
ref={purchaseTxtRef}
>
    <strong
    style={{
        color:"black",
    }}
    >{t("sale.saleTitle")}</strong>
    <div className="purchase-txt-bottom-mb"
    
    >
   <p>
   {t("sale.content1")}
   </p>
   <p>
   {t("sale.content2")}
   </p>
    </div>

</div>
            </Mobile>
        </div>
    );
}

export default Sale;