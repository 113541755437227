import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import FilterSearchBar from './FilterSearchBar';
import { Button, Card } from '@mui/material';
import { basicTableHeight } from '../const/const';

export default function UserCheckList({nowRecipients,handleSelectRecipients}) {
  const [checked, setChecked] = React.useState([]);
  const [ids,setIds]=React.useState([]);
  const [searchOptions,setSearchOptions]=React.useState([])
  const [searchList,setSearchList]=React.useState([])
  const [filteredRow,setFilteredRow]=React.useState([])
  const [clickSearch,setClickSearch]=React.useState(false)
  const [toggleAll,setToggleAll]=React.useState(false)
  const showFilterRows = (originRows) => {
    //     if (!isFilter) {
    //       return originRows;
    //     }
    // else{
    return originRows.filter((row) => {
      const match =searchList.length===0?true: searchList.includes(row.id);
    
      const res=match;
      return res;
    });

  };
  const handleToggleAll=(e)=>{
setToggleAll(p=>!p)
if (toggleAll) {  
  const newIds=[...ids]
  const newChecked=[...checked]
  for (let i = 0; i < nowRecipients.length; i++) {
const recipient = nowRecipients[i];

if (!newIds.includes(recipient.id)) {
newIds.push(recipient.id)
}
if(!newChecked.includes(i)){

newChecked.push(i)
}

}
setChecked(newChecked)
setIds(newIds)
}else{

}
  }
  React.useEffect(()=>{
    if (toggleAll) {  
      const newIds=[...ids]
      const newChecked=[...checked]
      for (let i = 0; i < nowRecipients.length; i++) {
  const recipient = nowRecipients[i];

  if (!newIds.includes(recipient.id)) {
    newIds.push(recipient.id)
  }
  if(!newChecked.includes(i)){

    newChecked.push(i)
  }

}
setChecked(newChecked)
setIds(newIds)
    }else{
      setChecked([])
      setIds([])
    }

  },[toggleAll])
  const handleToggle = (value,recipientId) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
const newIds=[...ids]
    if (currentIndex === -1) {
      newChecked.push(value);
      newIds.push(recipientId)
    } else {
      newChecked.splice(currentIndex, 1);
      newIds.splice(currentIndex,1)
     
    }
    if (newChecked.length===nowRecipients.length) {
      setToggleAll(true)
    }
    
setIds(newIds)
    setChecked(newChecked);

  };
  const handleSearch=(e,vals)=>{
    setSearchList(vals)
  }
  const handleClickSearch=()=>{
setClickSearch(p=>!p)
  }
  React.useEffect(()=>{
    
    setFilteredRow(showFilterRows(nowRecipients))
  },[nowRecipients])
  React.useEffect(() => {
      setFilteredRow(showFilterRows(nowRecipients))
  setToggleAll(false)
  setChecked([])
  setIds([])
  }, [clickSearch])

  React.useEffect(()=>{

    const newSearchList=nowRecipients.map(td=>td.id)
  
  setSearchOptions(newSearchList)

  },[nowRecipients])
React.useEffect(()=>{  handleSelectRecipients(ids) },[ids])
  return (
    
    <Card
    variant='outlined'
    sx={{width:"100%",
        height:basicTableHeight, boxSizing: "border-box",
       
   
    }}
    > <List
    
    dense 
    sx={{ width: '100%',   height:`100%`, bgcolor: 'background.paper',borderRadius:1 }}>
        <div
        style={{paddingLeft:"16px",paddingRight:"10px",display:"flex",gap:"8px",marginBottom:"16px",width:"100%"}}
        ><FilterSearchBar label={"Recipients Search"} options={searchOptions} handleSearchList={handleSearch} limitTags={2}  width='100%'/>
        <Button 
       
        variant='contained' color='success' onClick={handleClickSearch}>SEARCH</Button>
        </div>
        <ListItem
            key={'all'}
         
            secondaryAction={
              <Checkbox
                edge="end"
                indeterminate={checked.length!=0&&checked.length!=filteredRow.length}
                onChange={handleToggleAll}
                checked={toggleAll}
                
              />
            }
            disablePadding
          >
            <ListItemButton >
             
              <ListItemText id={'all'} primary={'All Select'} />
            </ListItemButton>
          </ListItem>
      {filteredRow.map((value,idx) => {
        const labelId = `checkbox-list-secondary-label-${idx}`;
        return (
          <ListItem
            key={value.id}
         
            secondaryAction={
              <Checkbox
                edge="end"
                
                onChange={handleToggle(idx,value.id)}
                checked={checked.indexOf(idx) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            }
            disablePadding
          >
            <ListItemButton >
              <ListItemAvatar>
                <Avatar
                sx={{
                    backgroundColor:value.userType === "admin" ? "purple" : null
                }}
                //   alt={`Avatar n°${value + 1}`}
                //   src={`/static/images/avatar/${value + 1}.jpg`}
                >{value.userType === "user" ? "U" : "A"}</Avatar>
              </ListItemAvatar>
              <ListItemText id={labelId} primary={value.id} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List></Card>
   
  );
}
