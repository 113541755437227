import React from 'react';
import AppBarTab from './AppBarTab';

function UserAppBarTabView(props) {
    const tabNames=[
        {id:"table",
            name:"HOME"},
      
    ]
    return (
        <div
        style={{display:"flex",gap:"16px"}}
        >
            {tabNames.map(tab=><AppBarTab 
            id={tab.id}
            name={tab.name} width={null}/>)}
          
            
        </div>
    );
}

export default UserAppBarTabView;