import { Button, IconButton } from '@mui/material';
import React from 'react';
import { FaDownload } from 'react-icons/fa6';

function NoticeFileDownloadBtn({rowUrlList,rowFileNameList,handleFileDownload}) {

  
    return (
        <IconButton
        size='small'
        sx={{
            height:"50px"
        }}
     variant='contained'
        // color='info'
        onClick={()=>
            
            
          {
            
            for (let i = 0; i < rowUrlList.length; i++) {
                const rowUrl = rowUrlList[i];
                const rowFileName=rowFileNameList[i]
                 handleFileDownload(rowUrl,rowFileName)
            }
            
           }}
        ><FaDownload size={16}/></IconButton>
    );
}

export default NoticeFileDownloadBtn;