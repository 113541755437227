import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { RiFileUploadFill } from "react-icons/ri";
import { IoTrash } from "react-icons/io5";
import { RiDownload2Fill } from "react-icons/ri";
const CommunityFileUploader = ({ 
  
  
  // onDownload,
  
  
  downloadDisable,readOnly = false, width = "70px", fileLoaderIdx, fileName, handleFileData,
  handleDelFileLoader }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  // 이미지가 선택되었을 때 호출되는 함수
  const handleImageUpload = (event) => {
    const uploadFiles = event.target.files[0];
    //   if (input.files && input.files[0]) {
    //     // 선택된 파일을 읽기
    //     const reader = new FileReader();

    //     reader.onload = function (e) {
    //       // 이미지를 상태에 저장
    //       setSelectedImage(e.target.result);
    //     };

    //     reader.readAsDataURL(input.files[0]);
    //   }
  };

  return (
    <div
      style={{
        display: "flex"
      }}
    >
      {/* 이미지를 업로드할 TextField */}
      <TextField
        size='small'
        type="file"
        id={`imageInput_${fileLoaderIdx}`}
        style={{ display: 'none' }}
        onChange={(e) => { handleFileData(e, fileLoaderIdx) }}
      />

      {/* 이미지를 업로드하는 버튼 */}
      <Button
        sx={{
          width: width
        }}
        disabled={readOnly}
        variant="contained"
        component="label"
        htmlFor={`imageInput_${fileLoaderIdx}`}
      >
        <RiFileUploadFill size={20} />
      </Button>
      <div style={{ width: "8px" }} />
      {/* 이미지를 오픈(다운로드)하는 버튼 */}
      {/* <Button
        sx={{
          width: width
        }}
        color='success'
        disabled={downloadDisable}
        variant="contained"
        component="label"
        onClick={onDownload}
      >
        <RiDownload2Fill size={20} />
      </Button> */}
      <div style={{ width: "8px" }} />
      <TextField
        disabled
        value={fileName}
        size='small' sx={{
          width: "100%"
        }} />
      <div style={{ width: "8px" }} />
      <Button
        disabled={readOnly}
        color='error'
        variant="outlined"
        onClick={(e) => { handleDelFileLoader(fileLoaderIdx) }}
      >
        <IoTrash size={20} />
      </Button>
    </div>
  );
};

export default CommunityFileUploader;