import React from 'react';
import AppBarTab from './AppBarTab';

function AdminAppBarTabView(props) {
    const tabNames=[
          {id:"review",
            name:"REVIEW"}, 
            {id:"user-setting",
            name:"USER SETTING"},
     
        {id:"broker-split",
            name:"BROKER SPLIT"},
        {id:"transaction-doc",
            name:"TRANSACTION DOCUMENT"},
        {id:"email-send",
            name:"EMAIL SEND"}
    ]
    return (
        <div
        style={{display:"flex",gap:"16px"}}
        >
            {tabNames.map(tab=><AppBarTab 
            id={tab.id}
            name={tab.name} width={null}/>)}
          
            
        </div>
    );
}

export default AdminAppBarTabView;