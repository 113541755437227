import { Alert, Button, Card, CardContent, CssBaseline, IconButton, InputAdornment, MenuItem, Select, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../App.css"
import TxtfieldRow from '../components/TxtfieldRow';
import { useRecoilState } from 'recoil';
import { themeAtom, userIdState } from '../atom/atom';
import { db } from '../service/firebase';
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import NoRequiredAlertDialog from '../components/NoRequiredAlertDialog';
import { getDesignTokens, validateSignupForm } from '../const/const';
import { IoIosArrowForward } from 'react-icons/io';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ThemeToggleBtn from '../components/ThemeToggleBtn';
function SignUpPage(props) {
    const userCollection = collection(db, "userInfos")
    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [id, setId] = useState("")
    const [pw, setPw] = useState("")
    const [licenseNum,setLicenseNum]=useState("")
    const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 두 자리로 맞춥니다.
const day = String(today.getDate()).padStart(2, '0'); // 일도 두 자리로 맞춥니다.

const formattedDate = `${year}-${month}-${day}`;
    const [licenseExpirationDate,setLicenseExpirationDate]=useState(formattedDate)
    const [nowUserId, setNowUserId] = useState("")
    const [userType, setUserType] = useState("user")
    const [openIdPwAlert,setOpenIdPwAlert]=useState(false)
    const [showPassword,setShowPassword]=useState(false)
    const [mode, setMode] = useRecoilState(themeAtom);
  const defaultSplitClass="D"
    const colorMode =useMemo(
      () => ({
        // The dark mode switch would invoke this method
        toggleColorMode: () => {
          setMode((prevMode) =>
            prevMode === 'light' ? 'dark' : 'light',
          );
        },
      }),
      [],
    );
  
    // Update the theme only if the mode changes
    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])
    // const [openPwAlert,setOpenPwAlert]=useState(false)
    const handleSignupSubmit = async(email, password,name,licenseNum) => {
        const errors = validateSignupForm(email, password,name,licenseNum);
      
        if (Object.values(errors)[0]===""&&Object.values(errors)[1]===""&&Object.values(errors)[2]===""&&Object.values(errors)[3]==="") {
          // 유효성 검사 통과: 회원가입 요청 등의 로직 수행
         await setUserInfo()
        //   window.confirm("회원가입 성공!");
         
        } else {
          // 유효성 검사 실패: 에러 메시지 출력 등의 처리
          window.confirm(errors.email+"\n"+errors.password+"\n"+errors.name+"\n"+errors.licenseNum);
        }
      };
      
    //id textfield
    const onChangeId = (e) => {
      setId(e.target.value)
  }
    //name textfield
    const onChangeName = (e) => {
      setName(e.target.value)
  }

    //pw textfield
    const onChangePw = (e) => {
      setPw(e.target.value)
  }   
  const onChangeLicenseNum = (e) => {
    const maxLength = 8; // 최대 입력 길이를 8로 설정합니다.
  // 이벤트가 발생한 input 요소를 가져옵니다.
    
    // 입력된 값의 길이가 최대 입력 길이보다 큰지 확인합니다.
    if (e.target.value.length > maxLength) {
        // 최대 입력 길이를 초과한 경우 입력을 막습니다.
        return
    }else{
      setLicenseNum(e.target.value)
    }
    
}
    const navigateLoginPage = () => {
        navigate("/fileupload/login")
    }
    const onChangeType = (evt) => {
        setUserType(evt.target.value)
    }
    const closeAlert=()=>{
        setOpenIdPwAlert(false)
    }
    const setUserInfo = async () => {
        if (id.trim() === "" || pw.trim() === "") {
          const res = window.confirm('"E-mail address" and "PW" are required fields. Please enter them.');
        } else {
          // 문서가 존재하는지 확인 
           const dataCollection=collection(db, "pjDatas")
          const docRef = doc(userCollection, id);
          const dataDocRef=doc(dataCollection,id)
          const docSnap = await getDoc(docRef);
      const dataDocSnap=await getDoc(dataDocRef)
          if (docSnap.exists()) {
            // 이미 존재하는 경우
            const res=window.confirm("The E-mail address already exists.")
          } else {
            try {
              // 문서가 존재하지 않는 경우에만 새로운 문서를 추가
              await setDoc(docRef, {
                name:name,
                id: id,
                pw: pw,
                // admin이 user를 admin으로 지정해야 함. -> admin페이지에서 설정 할 기능
                userType: "user",
                licenseNum:licenseNum,
                licenseExpirationDate:licenseExpirationDate,
                splitClass:defaultSplitClass,
                approve: false
              });  
              //pjDatas에도 문서 추가하기.
            if (!dataDocSnap.exists()) {
                await setDoc(dataDocRef, {
            ann:[],
            ins:[]
              });    
            }
            
              const res=window.confirm("Sign-up successful.")
              if (res) {
                setName("")
                setId("")
                setPw("")
                setLicenseNum("")
                setLicenseExpirationDate(formattedDate)
                navigate("/fileupload/login")
              }
             
            } catch (error) {
          const res=window.confirm("Sign-up failed.",error)
            }
          }
        }
      };
      const handleClickShowPassword=()=>{
        setShowPassword(true)
      }
    const handleMouseDownPassword=()=>{
      setShowPassword(false)
    }
    const onChangeDate=(e)=>{
      setLicenseExpirationDate(e.target.value)
    }
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
               
                alignItems: "center",
                justifyContent: "center"
            }}
        > 
        <ThemeProvider theme={theme}>
          <CssBaseline/>
    <NoRequiredAlertDialog open={openIdPwAlert} handleClose={closeAlert} msg={'"E-mail address" and "PW" are required fields. Please enter them.'}/>
        <div
        style={{
        display:"flex",
        flexDirection:"column",
          marginBottom:"64.01px",
          gap:"16px"
        
        }}
        >
          
        <Alert severity="warning" >Sign up with an actual email address.</Alert>
          <Card
            variant='outlined'
            // className='ContentBox'


            sx={{
                width: "330px",
                height: "600px",
                display: 'flex',
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
            }}
        >

                <CardContent

                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px"
                    }}
                >
                  <Typography variant="h6" component="div" sx={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  BD file management
                </Typography>
                  <div
                  style={{
                    display:"flex",
                    flexDirection:"row", justifyContent: "space-between",
                    alignContent: "center",
                  }}
                  ><ThemeToggleBtn disable={true}/>
     
     <Typography variant="h5" component="div" sx={{fontSize:"20px",display:"flex",alignItems:"center",justifyContent:"center"}}>
   SIGN UP
       </Typography>
       <ThemeToggleBtn  toggleColorMode={colorMode.toggleColorMode}/></div>
                      
                    <div>
                      <div>
                      E-mail address
                        <TextField
                            size='small'
                            placeholder='E-mail address'
                            sx={{ width: "100%" }}
                            value={id}
                            onChange={onChangeId}
                        /></div>

                        </div>

                    {/* pw */}
<div>
  PW
  <TextField
                        size='small'
                        placeholder='PW'
                        sx={{ width: "100%" }}
                        value={pw}
                        onChange={onChangePw}
                        type={showPassword ? "text" : "password"}
                        InputProps={{ // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                    /></div>
                    <div>
                      Name
                      <TextField
                            size='small'
                            placeholder='Name'
                            sx={{ width: "100%" }}
                            value={name}
                            onChange={onChangeName}
                        />
                    </div>
                    
                    {/* licenseNum */}
                    <div> 
                      License Number
                      <TextField
                        size='small'
                        placeholder='License Number'
                        sx={{ width: "100%" }}
                        value={licenseNum}
                        onChange={onChangeLicenseNum}
                        type={"text" }
                       
                    /></div>
                   
                    {/* license expiration date */}
                    <div
                    
                    >License Expiration Date
                    <input 
            type={'date'}

          value={licenseExpirationDate}
onChange={onChangeDate}
        style={{
            height:"40px",
            width:"100%",
            borderRadius:"6px",
            border:`1px solid ${theme.palette.grey[500]}`
        }}
            /></div>
                    
                    {/* select type */}
                    {/* <Select
                        size='small'
                        sx={{ width: "100%" }}
                        value={userType}
                        onChange={onChangeType}
                    >

                        <MenuItem value={"user"}>User</MenuItem>
                        <MenuItem value={"admin"}>Admin</MenuItem>
                    </Select> */}
                  <Button 
                   color='secondary'
                  variant='contained' sx={{ width: "100%" }}
                        onClick={()=>handleSignupSubmit(id,pw,name,licenseNum)}
                    >sign up</Button>   
                    
                     <div  style={{
                  display:"flex"
                }}><Button  sx={{ width: "100%" }}
                onClick={navigateLoginPage}
            >GO LOGIN<IoIosArrowForward size={20}/></Button>
                
                 {/* <Button 
                color='error'
                sx={{width:"100%"}}
                onClick={()=>{}}
                >FORGET PW<IoIosArrowForward size={20}/></Button> */}
                </div>
                    </CardContent>


            </Card></div>
            </ThemeProvider>
        </div>



    );
}

export default SignUpPage;