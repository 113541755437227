
import { atom } from "recoil";
//현재 유저 아이디
export const userIdState=atom({
  key:"userId",
  default:""
});
export const temp5FileState=atom({
  key:"temp5Files",
  default:Array.from({length: 5}, (v, i) => "")
}
 
);
export const urlRecived=atom({
  
  key:"down",
  default:""
})
//업로드 테이블에 쓸 거
export const uploadListPerRowState=atom({

  key:"upload",
  default:[]
}
  )
  export const uploadLoadingState=atom({

    key:"uploadLoading",
    default:false
  }
    )
export const tabNameState=atom({
  key:"tabName",
  default:"user-setting"
})

  export const filesState=atom({
    key:"files",
    default:[
      
    ]
  }) 
  export const transactionTypeOptionsState=atom({
    key:"transactionTypeOption",
    default:null

  }) 
  export const themeAtom=atom({
    key:"theme",
    default:"light"
  })
  export const mainLoadingAtom=atom({
    key:"loading",
    default:false
  })
  export const loadingPercentAtom=atom({
    key:"loadingPercent",
    default:null
  })