import React, { useEffect, useState } from 'react';
import UserSettingTable from '../components/UserSettingTable';
import { db } from '../service/firebase';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import FilterSearchBar from '../components/FilterSearchBar'
import { Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import { mainLoadingAtom } from '../atom/atom';
function UserSettingContent(props) {
  const [userIdOption,setUserIdOption]=useState([])
  const [approveOption,setApproveOption]=useState([])
  const [userTypeOption,setUserTypeOption]=useState([])
  //search list
  const [userIdSearchList,setUserIdSearchList]=useState([])
  const [approveValue,setApproveValue]=useState("")
  const [userTypeSearchList,setUserTypeSearchList]=useState([])
  const [searchClick,setSearchClick]=useState(false)
  const [tableDatas,setTableDatas]=useState([])
  const [deleteList, setDeleteList] = useState([])
  const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
  const datasCollection = collection(db, "pjDatas")
  window.addEventListener('beforeunload', () => {
  });
    async function getTablePerUsers() {
      setMainLoading(true)
    const userCollection = collection(db, "userInfos")

      const data = await getDocs(userCollection)
      const list=data.docs.map(d=>d.data())
      setTableDatas(list)
      setMainLoading(false)
    }

  const handleUserIdSearch=(e,vals)=>{
    setUserIdSearchList(vals)
  }
  const handleApproveSearch=(e,val)=>{
setApproveValue(val)
  }
  const handleUserTypeSearch=(e,vals)=>{
setUserTypeSearchList(vals)
  }
 const handleSearchClick=()=>{


  setSearchClick(p=>!p)
 }
 useEffect(()=>{

getTablePerUsers()

},[])
  useEffect(()=>{
if (!tableDatas)return;
//user id option
const newUserIdOption=tableDatas.map(td=>td.id)
const idSet=new Set(newUserIdOption)
const noSameIdArr=[...idSet]
setUserIdOption(noSameIdArr)
//approve option
// const newApproveOption=tableDatas.map(td=>td.approve)
// const approveSet=new Set(newApproveOption)
// const noSameApproveArr=[...approveSet]
setApproveOption(["true","false"])
//user type option
// const newUserTypeOption=tableDatas.map(td=>td.userType)
// const typeSet=new Set(newUserTypeOption)
// const noSameTypeArr=[...typeSet]
setUserTypeOption(["user","admin"])

  },[tableDatas])
  const onDelete=async ()=>{
    //deleteList empty면 리턴
    if (deleteList.length===0)return;
    //유저 딜리트 하기
    const res=window.confirm("Do you want to delete the selected user information?")
    if (res) {
      try {

        for (let i = 0; i < deleteList.length; i++) {
          const del = deleteList[i];
          //유저별 데이터 삭제
        const docDataRef=doc(db,"pjDatas",del)
       
       await deleteDoc(docDataRef)
     //유저별 정보 삭제
     const docInfoRef=doc(db,"userInfos",del)
       
   await deleteDoc(docInfoRef)
        }
        
  
    } catch (error) {
        window.confirm("error")
      }
      
    } else {
      
    }
    setDeleteList([])
    getTablePerUsers()
    }
    return (
        <div className="ContentBox">
       
       <div
       style={{display:"flex",width:"100%",justifyContent:"flex-start",gap:"16px"}}
       >
{/* bdnumber 서치 바 */}
<FilterSearchBar 
handleSearchList={handleUserIdSearch}
width='100%'label={"User Id"} options={userIdOption} limitTags={2}/>
{/* Address 서치 바 */}
<FilterSearchBar
multiple={false}
handleSearchList={handleApproveSearch}
width='100%'label={"Approve"} options={approveOption} limitTags={1}/>
{/* Transaction Type 서치 바 */}
<FilterSearchBar

handleSearchList={handleUserTypeSearch}
width='100%'label={"User Type"} options={userTypeOption} limitTags={1}/>
<Button 
onClick={handleSearchClick}
variant='contained' color='success'sx={{width:"500px"}}>SEARCH</Button>
<Button 
onClick={onDelete}
variant='contained' color='error'sx={{width:"500px"}}>DELETE</Button>
       </div>
       
         <UserSettingTable 
         deleteList={deleteList}
         setDeleteList={setDeleteList}
         searchClick={searchClick} 
         tableDatas={tableDatas} 
         userIdList={userIdSearchList} 
         approveValue={approveValue} 
         userTypeList={userTypeSearchList}/>
       </div> 
    );
}

export default UserSettingContent;