import { TextField, TextareaAutosize, useTheme } from '@mui/material';
import React from 'react';

function TxtAreaRow({ readOnly = false, title, value, onChange, onBlur, titleWidth = "500px", width = "200px", containerWidth = "800px" }) {
    const theme = useTheme()
    return (
        <div
            style={{
                display: "flex",
                alignItems: "start", gap: "8px"
            }}
        >
            <div style={{ fontSize: "16px", display: "flex", width: titleWidth, justifyContent: "flex-end" }}>{title + " : "}</div>
            <div style={{ width: containerWidth }}>

                <TextareaAutosize

                    style={{
                         width: width
                        , height: "300px"
                        , backgroundColor: theme.palette.background.paper
                    ,color:theme.palette.mode==='light'?"black":"white" }}
                    size='small'
                    value={value}
                    disabled={readOnly}
                    onChange={onChange}
                    onBlur={onBlur}
                /></div>
        </div>
    );
}

export default TxtAreaRow;