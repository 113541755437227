import { TextField, TextareaAutosize } from '@mui/material';
import React from 'react';

function TxtfieldRow({
    placeholder,
   
    readOnly=false,title,value,onChange,onBlur,titleWidth="500px",width="200px",containerWidth="800px"}) {
    return (
        <div
        style={{
            display:"flex",
alignItems:"center",gap:"8px"
        }}
        >
            <div style={{fontSize:"16px",display:"flex",width:titleWidth,justifyContent:"flex-end"}}>{title+" : "}</div>  
           <div style={{width:containerWidth}}>
            
            <TextField sx={{ width: width }}
            placeholder={placeholder}
      size='small'
        value={value}
    disabled={readOnly}
onChange={onChange}
onBlur={onBlur}
/></div> 
        </div>
    );
}

export default TxtfieldRow;