import React, { useState, useEffect, useRef } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import './ListProperties.css';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Input,
    FormHelperText,
    CardMedia,
    CircularProgress,
    Alert,
    styled,
    Tooltip,
} from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../service/firebase';
import { addDoc, collection, getDocs, updateDoc, doc, orderBy, query, where, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function ListProperties() {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [authOpen, setAuthOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthForAdd, setIsAuthForAdd] = useState(false);
    const [isAuthForEdit, setIsAuthForEdit] = useState(false);
    const [isAuthForDel, setIsAuthForDel] = useState(false);
    const [delId,setDelId]=useState(null)
    const [formData, setFormData] = useState({
        address: '',
        listPrice: '',
        bed: '',
        bath: '',
        sqft: '',
        lotSize: '',
        level: '',
        garage: '',
        yearBuilt: '',
        type: '',
        hoa: '',
        contactPhone: '',
        contactEmail: '',
        description: '',
    });
    const [editData, setEditData] = useState(null);
    const [properties, setProperties] = useState([]);
    const [images, setImages] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [authData, setAuthData] = useState({ id: '', pw: '' });
    const [hasMore, setHasMore] = useState(true); // 데이터가 더 있는지 확인
    const [currentPage, setCurrentPage] = useState(1);
    const [show,setShow]=useState(false)
    const [showData,setShowData]=useState(null)
    const tableContainerRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const navigate=useNavigate()
      // 스크롤 감지 함수
  const handleScroll = () => {
    if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
            // 페이지 번호 증가
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }
};

// 스크롤 이벤트 리스너 추가
useEffect(() => {
    const currentTableContainer = tableContainerRef.current;
    if (currentTableContainer) {
        currentTableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (currentTableContainer) {
            currentTableContainer.removeEventListener('scroll', handleScroll);
        }
    };
}, [hasMore, loading]);
    const StyledTableCell = styled(TableCell)({
        cursor:"pointer",
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    })
    const TableCellWithTooltip = ({ children,...props }) => (
        <Tooltip title={children}>
          <StyledTableCell 
          
          {...props}>{children}</StyledTableCell>
        </Tooltip>
      );
      const handleAuthChange = (e) => {
        const { name, value } = e.target;
        setAuthData({ ...authData, [name]: value });
    };

    const handleAuthSubmit = async () => {
        try {
            const userInfosRef = collection(db, 'userInfos');
            const q = query(userInfosRef, where("id", "==", authData.id), where("pw", "==", authData.pw));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty && querySnapshot.docs[0].data().userType === "admin") {
                setIsAuthenticated(true);
                setAuthOpen(false);
                if (isAuthForAdd) {
                    setOpen(true); // Register a Property 창 열기
                    setIsAuthForAdd(false);
                }
                if (isAuthForEdit) {
                    setEditOpen(true); // Edit Property 창 열기
                    setIsAuthForEdit(false);
                }
                if (isAuthForDel) {
                    if (window.confirm("정말로 이 매물을 삭제하시겠습니까?")) {
                        try {
                            const docRef = doc(db, 'properties', delId);
                            await deleteDoc(docRef);
                            alert("매물이 삭제되었습니다.");
                            fetchProperties(); // 삭제 후 리스트 갱신
                        } catch (error) {
                            console.error("매물 삭제 중 오류 발생: ", error);
                            alert("삭제 중 문제가 발생했습니다. 다시 시도해 주세요.");
                        }
                    }
                    setIsAuthForDel(false);
                }
            } else {
                alert("Invalid ID or Password.");
            }
        } catch (error) {
            alert("Invalid ID or Password.");
            console.error("Authentication error: ", error);
        }
    };

    const handleClickOpen = () => {
        if (!isAuthenticated) {
            setAuthOpen(true); // 인증 창 열기
            setIsAuthForAdd(true); // ADD NEW 버튼 클릭 플래그 설정
        } else {
            setOpen(true); // 인증된 경우 바로 등록 창 열기
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            address: '',
            listPrice: '',
            bed: '',
            bath: '',
            sqft: '',
            lotSize: '',
            level: '',
            garage: '',
            yearBuilt: '',
            type: '',
            hoa: '',
            contactPhone: '',
            contactEmail: '',
            description: '',
        });
        setImages([]);
    };

    const handleEditClickOpen = (property) => {
        setEditData(property);
        setFormData({
            address: property.address,
            listPrice: property.listPrice,
            bed: property.bed,
            bath: property.bath,
            sqft: property.sqft,
            lotSize: property.lotSize,
            level: property.level,
            garage: property.garage,
            yearBuilt: property.yearBuilt,
            type: property.type,
            hoa: property.hoa,
            contactPhone: property.contactPhone,
            contactEmail: property.contactEmail,
            description: property.description,
        });
        const existingImages = property.images.map((imageUrl) => ({
            preview: imageUrl,
            file: null
        }));
        setImages(existingImages);
        if (!isAuthenticated) {
            setAuthOpen(true); // 인증 창 열기
            setIsAuthForEdit(true); // EDIT 버튼 클릭 플래그 설정
        } else {
            console.log("property in handleEditClickOpen>>>",property)
           
            setEditOpen(true);
           
          
        }
    };

    // 추가된 handleDelete 함수
    const handleDelete = async (propertyId) => {
        setDelId(propertyId)
        if (!isAuthenticated) {
            setAuthOpen(true); // 인증되지 않은 경우 인증 다이얼로그 열기
            setIsAuthForDel(true)
        } else {
            if (window.confirm("정말로 이 매물을 삭제하시겠습니까?")) {
                try {
                    const docRef = doc(db, 'properties', propertyId);
                    await deleteDoc(docRef);
                    alert("매물이 삭제되었습니다.");
                    fetchProperties(); // 삭제 후 리스트 갱신
                } catch (error) {
                    console.error("매물 삭제 중 오류 발생: ", error);
                    alert("삭제 중 문제가 발생했습니다. 다시 시도해 주세요.");
                }
            }
        }
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setFormData({
            address: '',
            listPrice: '',
            bed: '',
            bath: '',
            sqft: '',
            lotSize: '',
            level: '',
            garage: '',
            yearBuilt: '',
            type: '',
            hoa: '',
            contactPhone: '',
            contactEmail: '',
            description: '',
        });
        setImages([]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files); // 여러 파일을 배열로 변환
        if (files.length + images.length <= 50) { // 업로드 가능한 파일 수 제한
            const imagePreviews = files.map((file) => ({
                file,
                preview: URL.createObjectURL(file)
            }));
            setImages((prevImages) => [...prevImages, ...imagePreviews]); // 기존 이미지와 새 이미지 병합
        } else {
            alert("You can only upload a maximum of 50 images.");
        }
    };
    

    const removeImage = (index) => {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    };

    const handleSubmit = async () => {
        for (const field in formData) {
            if (formData[field] === '') {
                setError("Please fill out all required fields.");
                return;
            }
        }

        setIsUploading(true);
        setError('');

        try {
            console.log("submit>>>!!!")
            const uploadPromises = images.map(async (image) => {
                const imageRef = ref(storage, `properties/${Date.now()}_${image.file.name}`);
                await uploadBytes(imageRef, image.file);
                return await getDownloadURL(imageRef);
            });

            const imageUrls = await Promise.all(uploadPromises);

            await addDoc(collection(db, 'properties'), {
                ...formData,
                images: imageUrls,
                timestamp: new Date()
            });

            alert("Property successfully registered.");
            setOpen(false);
            handleClose();
            fetchProperties();
        } catch (error) {
            console.error("Error adding document: ", error);
            setError("Error registering property. Please try again.");
        }

        setIsUploading(false);
    };

    const handleEditSubmit = async () => {
        if (!editData) return;
        const docRef = doc(db, 'properties', editData.id);

        try {
            const newUploadImages = images.filter(image => image.file !== null);

            const uploadPromises = newUploadImages.map(async (image) => {
                const imageRef = ref(storage, `properties/${Date.now()}_${image.file.name}`);
                await uploadBytes(imageRef, image.file);
                return await getDownloadURL(imageRef);
            });

            const newImageUrls = await Promise.all(uploadPromises);
            const updatedImageUrls = [
                ...images.filter(image => image.file === null).map(image => image.preview),
                ...newImageUrls,
            ];

            await updateDoc(docRef, {
                ...formData,
                images: updatedImageUrls
            });

            alert("매물 정보가 성공적으로 업데이트되었습니다.");
            handleEditClose();
            setEditData(null);
            fetchProperties();
        } catch (error) {
            console.error("문서를 업데이트하는 중 오류가 발생했습니다: ", error);
            setError("매물 정보를 업데이트하는 중 문제가 발생했습니다. 다시 시도해 주세요.");
        }
    };

    const fetchProperties = async () => {
        try {
            setLoading(true)
            const propertiesRef = collection(db, "properties");
            const propertiesQuery = query(propertiesRef, orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(propertiesQuery);

            const propertiesList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            const ITEMS_PER_PAGE=6
            const paginatedData=propertiesList.slice(0,currentPage*ITEMS_PER_PAGE)
            setProperties(paginatedData);
            setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching properties: ", error);
        }
    };
const onClickRow=(row)=>{
console.log("row>>>",row)
    localStorage.setItem("nowProperty",JSON.stringify(row))
    navigate("/property-show")
}
    useEffect(() => {
        fetchProperties();
    }, [currentPage]);

console.log("form data edit open>>>",formData,editData)
    return (
        <div className='list-properties-container'>
        


            {/* 인증 다이얼로그 */}
            <Dialog open={authOpen} onClose={() => setAuthOpen(false)}>
                <DialogTitle>Admin Authentication</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Admin ID"
                        name="id"
                        value={authData.id}
                        onChange={handleAuthChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        name="pw"
                        type="password"
                        value={authData.pw}
                        onChange={handleAuthChange}
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAuthOpen(false)}>Cancel</Button>
                    <Button onClick={handleAuthSubmit}>Authenticate</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Register a Property</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill out the form below to register a new property.
                    </DialogContentText>
                    {error && <Alert severity="error">{error}</Alert>}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="List Price"
                        name="listPrice"
                        value={formData.listPrice}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bed"
                        name="bed"
                        type="number"
                        value={formData.bed}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bath"
                        name="bath"
                        type="number"
                        value={formData.bath}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Sqft"
                        name="sqft"
                        type="number"
                        value={formData.sqft}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Lot Size"
                        name="lotSize"
                        value={formData.lotSize}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Level"
                        name="level"
                        value={formData.level}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Garage"
                        name="garage"
                        value={formData.garage}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Year Built"
                        name="yearBuilt"
                        type="number"
                        value={formData.yearBuilt}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="HOA"
                        name="hoa"
                        value={formData.hoa}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Phone"
                        name="contactPhone"
                        value={formData.contactPhone}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Email"
                        name="contactEmail"
                        value={formData.contactEmail}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                    />

<input
    accept="image/*"
    id="image-upload"
    type="file"
    multiple
    onChange={handleImageUpload}
    style={{ marginTop: '20px' }}
/>

                    <FormHelperText>Upload up to 50 images.</FormHelperText>
                    <Typography variant="body2" color="textSecondary">
                        {images.length} / 50 images uploaded
                    </Typography>

                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        {images.map((image, index) => (
                            <Grid item xs={4} key={index}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        image={image.preview}
                                        alt={`Image ${index + 1}`}
                                    />
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => removeImage(index)}
                                    >
                                        Remove
                                    </Button>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={isUploading}>
                        {isUploading ? <CircularProgress size={24} /> : "Submit"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* 수정 다이얼로그 */}
            {<Dialog open={editOpen} onClose={handleEditClose} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Property</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update the property details below.
                    </DialogContentText>
                    {error && <Alert severity="error">{error}</Alert>}
                    <TextField
                        margin="dense"
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="List Price"
                        name="listPrice"
                        value={formData.listPrice}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bed"
                        name="bed"
                        type="number"
                        value={formData.bed}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bath"
                        name="bath"
                        type="number"
                        value={formData.bath}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Sqft"
                        name="sqft"
                        type="number"
                        value={formData.sqft}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Lot Size"
                        name="lotSize"
                        value={formData.lotSize}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Level"
                        name="level"
                        value={formData.level}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Garage"
                        name="garage"
                        value={formData.garage}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Year Built"
                        name="yearBuilt"
                        type="number"
                        value={formData.yearBuilt}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="HOA"
                        name="hoa"
                        value={formData.hoa}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Phone"
                        name="contactPhone"
                        value={formData.contactPhone}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Email"
                        name="contactEmail"
                        value={formData.contactEmail}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                    />
                   
                  <input
    accept="image/*"
    id="image-upload"
    type="file"
    multiple
    onChange={handleImageUpload}
    style={{ marginTop: '20px' }}
/>

                    <FormHelperText>Upload up to 50 images.</FormHelperText>
                    <Typography variant="body2" color="textSecondary">{images.length} / 50 images uploaded</Typography>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        {images.map((image, index) => (
                            <Grid item xs={4} key={index}>
                                <Card>
                                    <CardMedia component="img" height="100" image={image.preview || image} alt={`Image ${index + 1}`} />
                                    <Button size="small" color="secondary" onClick={() => removeImage(index)}>Remove</Button>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>}
            {/* show 다이얼로그 */}
            {showData && (
    <Dialog open={showData !== null} onClose={() => setShowData(null)} maxWidth="sm" fullWidth>
        <DialogTitle>Show Property</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Here are the details of the selected property.
            </DialogContentText>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
                margin="dense"
                label="Address"
                name="address"
                value={showData.address}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="List Price"
                name="listPrice"
                value={showData.listPrice}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Bed"
                name="bed"
                type="number"
                value={showData.bed}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Bath"
                name="bath"
                type="number"
                value={showData.bath}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Sqft"
                name="sqft"
                type="number"
                value={showData.sqft}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Lot Size"
                name="lotSize"
                value={showData.lotSize}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Level"
                name="level"
                value={showData.level}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Garage"
                name="garage"
                value={showData.garage}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Year Built"
                name="yearBuilt"
                type="number"
                value={showData.yearBuilt}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Type"
                name="type"
                value={showData.type}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="HOA"
                name="hoa"
                value={showData.hoa}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Contact Phone"
                name="contactPhone"
                value={showData.contactPhone}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Contact Email"
                name="contactEmail"
                value={showData.contactEmail}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                margin="dense"
                label="Description"
                name="description"
                value={showData.description}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />

            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                {showData.images?.map((image, index) => (
                    <Grid item xs={4} key={index}>
                        <Card>
                            <CardMedia
                                component="img"
                                height="100"
                                image={image}
                                alt={`Image ${index + 1}`}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowData(null)} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
)}

            <PC>
            <div
                className='btn-row'
            ><Button variant="contained" color="primary" onClick={handleClickOpen} sx={{
                marginBottom: "20px",
                backgroundColor: '#AA8B3B',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#8C722B' // 호버 시 색상
                },
                borderRadius: '8px', // 필요시 모서리 둥글기 설정
                padding: '10px 20px', // 버튼 패딩
                fontWeight: 'bold' // 텍스트 굵기
            }}>
                    ADD NEW
                </Button></div>
                <TableContainer
                ref={tableContainerRef}
                    variant='outlined'
                    sx={{
                        width: "1600px",
                        maxHeight: '500px', 
                        overflowY: 'auto' 
                    }}
                    component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                <TableCell>List Price</TableCell>
                                <TableCell>Bed</TableCell>
                                <TableCell>Bath</TableCell>
                                <TableCell>Sqft</TableCell>
                                <TableCell>Lot Size</TableCell>
                                <TableCell>Level</TableCell>
                                <TableCell>Garage</TableCell>
                                <TableCell>Year Built</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>HOA</TableCell>
                                <TableCell>Contact Info</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Edit</TableCell>
                                {/* <TableCell>Show</TableCell> */}
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties.map((property) => (
                                <TableRow key={property.id}>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }} >{property.address}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.listPrice}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.bed}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.bath}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.sqft}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.lotSize}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.level}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.garage}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.yearBuilt}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.type}</TableCellWithTooltip>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.hoa}</TableCellWithTooltip>
                                    <StyledTableCell onClick={()=>{
                                        onClickRow(property)
                                    }}>
                                    <Tooltip title={`Phone: ${property.contactPhone}`}>
                                    <Typography
                                            sx={{
                                                maxWidth: 200,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >Phone: {property.contactPhone}</Typography>
                                    </Tooltip>
                                    <Tooltip title={`Email: ${property.contactEmail}`}>
                                    <Typography
                                            sx={{
                                                maxWidth: 200,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >Email: {property.contactEmail}</Typography>
                                    </Tooltip>
                                       
                                    </StyledTableCell>
                                    <TableCellWithTooltip onClick={()=>{
                                        onClickRow(property)
                                    }}>{property.description}</TableCellWithTooltip>
                                    <StyledTableCell>
                                        <Button

                                            variant="outlined"
                                            sx={{
                                                borderColor: '#AA8B3B',
                                                color: '#8C722B',
                                                '&:hover': {
                                                    borderColor: '#8C722B'
                                                },
                                                borderRadius: '8px',
                                                padding: '6px 12px',
                                                fontWeight: 'bold',
                                                marginRight: '10px'
                                            }}
                                            onClick={() => handleEditClickOpen(property)}>
                                            EDIT
                                        </Button>
                                    </StyledTableCell>
                                    {/* <StyledTableCell>
                                        <Button

                                            variant="outlined"
                                            sx={{
                                                borderColor: '#AA8B3B',
                                                color: '#8C722B',
                                                '&:hover': {
                                                    borderColor: '#8C722B'
                                                },
                                                borderRadius: '8px',
                                                padding: '6px 12px',
                                                fontWeight: 'bold',
                                                marginRight: '10px'
                                            }}
                                            onClick={() => {
                                                setShowData(property)
                                            }}>
                                            SHOW
                                        </Button>
                                    </StyledTableCell> */}
                                    <StyledTableCell>
                                        <Button variant="contained"
                                            sx={{
                                                backgroundColor: '#D32F2F', // Delete 버튼은 빨간색으로 강조
                                                color: '#fff',
                                                '&:hover': {
                                                    backgroundColor: '#B71C1C'
                                                },
                                                borderRadius: '8px',
                                                padding: '6px 12px',
                                                fontWeight: 'bold'
                                            }}
                                            onClick={() => handleDelete(property.id)}>
                                            Delete
                                        </Button>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </PC>

            <Mobile>
                <Grid
                 ref={tableContainerRef}
                 container spacing={2}
                 sx={{
                maxHeight: '800px',
                     overflowY: 'auto',
                     width: "100%"
                 }}

                >
                    {properties.map((property) => (
                        <Grid item xs={12} key={property.id}
                            sx={{
                                width: "100%"
                            }}
                        >
                            <Card
                            onClick={()=>{
                                onClickRow(property)
                            }}
                                variant='outlined'
                                sx={{
                                    width: "calc(100% - 20px)",
                                    marginLeft: "10px",
                                    marginRight: "10px"
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6">{property.address}</Typography>
                                    <Typography><strong>List Price:</strong> {property.listPrice}</Typography>
                                    <Typography><strong>Bed:</strong> {property.bed}</Typography>
                                    <Typography><strong>Bath:</strong> {property.bath}</Typography>
                                    <Typography><strong>Sqft:</strong> {property.sqft}</Typography>
                                    <Typography><strong>Lot Size:</strong> {property.lotSize}</Typography>
                                    <Typography><strong>Level:</strong> {property.level}</Typography>
                                    <Typography><strong>Garage:</strong> {property.garage}</Typography>
                                    <Typography><strong>Year Built:</strong> {property.yearBuilt}</Typography>
                                    <Typography><strong>Type:</strong> {property.type}</Typography>
                                    <Typography><strong>HOA:</strong> {property.hoa}</Typography>
                                    <Typography><strong>Contact Info:</strong> {property.contactPhone}, {property.contactEmail}</Typography>
                                    <Typography><strong>Description:</strong> {property.description}</Typography>
                                    {/* <Button

variant="outlined"
sx={{
    borderColor: '#AA8B3B',
    color: '#8C722B',
    '&:hover': {
        borderColor: '#8C722B'
    },
    borderRadius: '8px',
    padding: '6px 12px',
    fontWeight: 'bold',
    marginRight: '10px'
}}
onClick={() => {
    setShowData(property)
}}>
SHOW
</Button> */}
                                    {/* <Button variant="outlined" onClick={() => handleEditClickOpen(property)}>Edit</Button>
                                    <Button variant="outlined"

                                        onClick={() => handleDelete(property.id)}>Delte</Button> */}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Mobile>
        </div>
    );
}

export default ListProperties;
