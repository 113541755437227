import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';

function NoRequiredAlertDialog({msg,open,handleClose}) {
  return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {msg}
        </DialogTitle>
        
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
          
        </DialogActions>
      </Dialog>
    );
}

export default NoRequiredAlertDialog;