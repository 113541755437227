import React from 'react';
import {useMediaQuery} from 'react-responsive';

export const Mobile = ({children}) => {
  const isMobile = useMediaQuery({
    
    query : "(max-width:1199px)"
  });
  
  return <>{isMobile && children}</>
}

export const Tablet = ({children}) => {
  const isTablet = useMediaQuery({
    query : "(max-width:810px)"
  });
  
  return <div >{isTablet && children}</div>
}
export const PC = ({children}) => {
  const isPc = useMediaQuery({
    query : "(min-width:1200px)"
  });
  
  return <div >{isPc && children}</div>
}