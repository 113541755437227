import React, { useEffect, useState } from 'react';
import "../App.css"
import CustomPaginationActionsTable from '../components/CustomPaginationActionsTable';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import FilterSearchBar from '../components/FilterSearchBar';
import { mainLoadingAtom, transactionTypeOptionsState } from '../atom/atom';
import uuid from 'react-uuid';
import moment from 'moment';
function TableContent() {
  const location = useLocation()
  
  const navigate = useNavigate()
  const [tableDatas,setTableDatas]=useState([])
  const [bdNumsOption,setBdNumsOption]=useState([])
  const [closingYearsOption,setClosingYearsOption]=useState([])
  const [addressOption,setAddressOption]=useState([])
  const [cityOption,setCityOption]=useState([])
  const [ttOption,setTtOption]=useState([])
const [bdSearchList,setBdSearchList]=useState([])
const [closingYear,setClosingYearList]=useState([])
const [addressSearchList,setAddressSearchList]=useState([])
const [citySearchList,setCitySearchList]=useState([])
const [transSearchList,setTransSearchList]=useState([])
 const [searchClick,setSearchClick]=useState(false)
 const transTypeCollection = collection(db, "options")
 const [transTypeOptions,setTransTypeOptions]=useRecoilState(transactionTypeOptionsState)
 const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
  const nowUserId=location.state.id


 

  async function getTableDatas() {
    const collectionRef = collection(db, "pjDatas");
    const docRef = doc(collectionRef, nowUserId);
    const data = await getDoc(docRef);
  
    if (data.exists()) {
      const tableData = data.data();
      const tableDataMap = Object.values(tableData)
        .flat()
        .filter((l) => l.hasOwnProperty('bdNum'));
  
      // bdNum을 "BD" + 5자리 숫자로 설정
      const tableDataWithBdNum = tableDataMap.map((d, idx) => {
        return { ...d, bdNum: `BD${String(idx + 1).padStart(5, '0')}` };
      });
  
      // dateTime을 기준으로 내림차순 정렬
      const dtSortTable = tableDataWithBdNum.sort((a, b) => {
        return new Date(b.dateTime) - new Date(a.dateTime);
      });
  
      console.log("tableDataWithBdNumAndSorted>>>", dtSortTable);
      setTableDatas(dtSortTable);
    } else {
      // 데이터가 없을 때의 처리
      setTableDatas([]);
    }
  }
  
  

//transType options 파베에서 가지고 오기
async function getTransTypeOptions() {
  const docRef = doc(transTypeCollection, "transactionType")
    const transData = await getDoc(docRef)
    
    if (transData.exists()) {
      setTransTypeOptions(transData.data()["selectList"])
     

    } else {
    }
  }
  useEffect(()=>{

    getTransTypeOptions()
    //현재 유저 아이디를 키값으로 데이터 가져오기
if (nowUserId===null||nowUserId===undefined) return;

getTableDatas() 

  },[])
  useEffect(()=>{
    //테이블 데이터가 변할 때 마다 서치옵션이 변경되게
    if (!tableDatas)return;
    if (tableDatas.flat().length===0)return;
    if (tableDatas[0].length===0)return;
    //bdNum Option
    setMainLoading(true)
    const newBdNumsOption=tableDatas.map(td=>td.bdNum)
    const set = new Set(newBdNumsOption);
  
    const noSameArr = [...set];
  setBdNumsOption(noSameArr)
    //closing Year Option

    const newClosingYearsOption=tableDatas.map(td=>td.closingDetail.closingDate.split("-")[0])
    const closingSet = new Set(newClosingYearsOption);
  
    const noSameArrClosing = [...closingSet];
  setClosingYearsOption(noSameArrClosing)
  //address option
  const newAddressOption=tableDatas.map(td=>td.newTransactionDetail.transAddress)
  const addressSet = new Set(newAddressOption);
  const noSameAddress = [...addressSet];
  setAddressOption(noSameAddress)  
  //city option
  const newCityOption=tableDatas.map(td=>td.newTransactionDetail.transCity)
  const citySet = new Set(newCityOption);
  const noSameCity = [...citySet];
  setCityOption(noSameCity)
  //transaction type option
  const newTransTypes=tableDatas.map(td=>td.newTransactionDetail.transType)
  const newTransTypeOptions=[];
    for (let i = 0; i < newTransTypes.length; i++) {
      const index = newTransTypes[i];
      const item=transTypeOptions[index];
      newTransTypeOptions.push(item)
   
    
  }
  const ttSet=new Set(newTransTypeOptions)
  const noSameTt=[...ttSet]
  setTtOption(noSameTt)
  setMainLoading(false)
  },[tableDatas])
  const handleBdSearchList=(e,vals)=>{
    setBdSearchList(vals)
  }
  const handleClosingYearSearchList=(e,vals)=>{
    setClosingYearList(vals)
  }
  const handleAddressSearchList=(e,vals)=>{
    setAddressSearchList(vals)
  }
  const handleCitySearchList=(e,vals)=>{
    setCitySearchList(vals)
  }
  const handleTransSearchList=(e,vals)=>{
    setTransSearchList(vals)
  }
  const handleSearchClick=()=>{

    setSearchClick(p=>!p)
  }
  useEffect(()=>{
  //   const isSaved=localStorage.getItem("isSaved")
  //   const checkList=localStorage.getItem("checkList")
  //   const rowKey=localStorage.getItem("rowKey")
  //  //rowKey가 path
  //   console.log("뒤로가기 한 경우에 지울거",isSaved,checkList,rowKey)
  //   if (isSaved!=null&&checkList!=null&&rowKey!=null) {
  //     if (isSaved==="false") {
  //       //save버튼 안누르고 뒤로가기 한 경우
  //     }
  //   }
  },[])
  return (
    <div className="ContentBox">
      <div
        style={{ display: "flex", width: "100%", justifyContent: "flex-start", gap: "16px" }}
      >
        {/* bdnumber 서치 바 */}
<FilterSearchBar 
handleSearchList={handleBdSearchList}
width='500px'label={"BD Number"} options={bdNumsOption} limitTags={2}/>

 {/* Closing Date 서치 바 */}
 <FilterSearchBar 
handleSearchList={handleClosingYearSearchList}
// multiple={false}
width='500px'label={"Closing Date"} options={closingYearsOption} limitTags={2}/>

{/* Address 서치 바 */}
<FilterSearchBar
handleSearchList={handleAddressSearchList}
width='900px'label={"Address"} options={addressOption} limitTags={1}/>
{/* City 서치 바 */}
<FilterSearchBar
handleSearchList={handleCitySearchList}
width='700px'label={"City"} options={cityOption} limitTags={1}/>
{/* Transaction Type 서치 바 */}
<FilterSearchBar

handleSearchList={handleTransSearchList}
width='900px'label={"Transaction Type"} options={ttOption} limitTags={1}/>
<Button 
onClick={handleSearchClick}
variant='contained' color='success'sx={{width:"100px"}}>SEARCH</Button>
<Button variant='contained'

sx={{
  // width:"300px",
  minWidth:"180px"
}}
onClick={() => {
            //사진 업로드 하는 페이지로 이어지게
            const nowUserId = location.state.id
            const userType = location.state.userType
            const uid=uuid()
            navigate("/fileupload/write", { state: { id: nowUserId, userType: userType, row: null ,rowKey:uid} })

          }}>New Transaction</Button>
      </div>

      <CustomPaginationActionsTable searchClick={searchClick}
      closingYear={closingYear}
        bdNumList={bdSearchList}
        citySearchList={citySearchList}
        addressList={addressSearchList}
        transTypeList={transSearchList}
        tableDatas={tableDatas} />
    </div>
  );
}

export default TableContent;