import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UploadTable from './UploadTable';
import { TextField } from '@mui/material';
import { useRecoilState } from 'recoil';
import { filesState, loadingPercentAtom, mainLoadingAtom, temp5FileState, uploadListPerRowState, uploadLoadingState, urlRecived } from '../atom/atom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db, storage } from '../service/firebase';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytesResumable } from 'firebase/storage';
import CustomFileInput from './CustomFileInput';
import uuid from 'react-uuid';
export default function UploadDialog({
  applicable,
  rowKey,
docName,docSrc,
fileUrls,setFileUrls,
  setUploadTable,uploadFiveFiles,upIdx}) {
  // const [temp5Files, setTemp5Files] = useRecoilState(temp5FileState)
  const [loadingPercent,setLoadingPercent]=useRecoilState(loadingPercentAtom)
  const [open, setOpen] = React.useState(false);
 const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
 const [urlTest,setUrlTest]=React.useState("")
 const [received,setRecived]=useRecoilState(urlRecived)
//  const [fileUrls, setFileUrls] = React.useState([]);
const [uploadLoading,setUploadLoading]=useRecoilState(uploadLoadingState)
const location=useLocation()
// const rowKey=location.state.rowKey
const getFileUrls = async (path) => {
  // setMainLoading(true)
  const fileRef = ref(storage, path+"/");
  // const fileUrls = await getFileUrlsRecursive(fileRef);
  const result = await listAll(fileRef);
  const urls = await Promise.all(
    result.items.map(async (item) => {

      const url = await getDownloadURL(item);
      const isPdf = item.name.includes("pdf");
      return { url: url, isPdf: isPdf };

    }),
  );
  const fileUrls = urls.flat()
  setFileUrls(fileUrls)

// setMainLoading(false)
  return fileUrls;
};
//pdf파일은 미리 스토리지에 올리자
// const uploadFileToStorage = async (file, path) => {
//     setMainLoading(true)
//   // console.log("upload to storage>>>", file)
//   const storageRef = ref(storage, path);

//   const uploadTask = uploadBytesResumable(storageRef, file);

//   // 업로드 진행률 이벤트 리스너 추가
//   uploadTask.on('state_changed',
//     (snapshot) => {
//       // 업로드 진행 중일 때 호출되는 콜백
//       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       // console.log('Upload is ' + Math.ceil(progress) + '% done');
//       const nowPercent=Math.ceil(progress) + '% done';
//       setLoadingPercent(nowPercent)
      
//     },
//     (error) => {
//       // 업로드 중 오류가 발생했을 때 호출되는 콜백
//       console.error('Error uploading file: ', error);
//     },
//     () => {
//       // 업로드가 완료되었을 때 호출되는 콜백
//       console.log('Upload complete!@!@!@!@');
      
//       // const timer = setTimeout(() => {
//         // setMainLoading(true);
//       // }, 3000);
     
//     }
//   );
//   await uploadTask;
//   // await getFileUrls(path)
//   //  setLoadingPercent(null)
//   // 파일 업로드 후 다운로드 URL 가져오기
//   const downloadURL = await getDownloadURL(storageRef).then;
// // setMainLoading(false)
// console.log("downloadUrl>>>",downloadURL)
// const isPdf = downloadURL.includes("pdf");
// const newFileUrl= { url: downloadURL, isPdf: isPdf };
// setFileUrls(p=>[...p,newFileUrl])
//   return downloadURL;

// };
const uploadFileToStorage = async (file, path) => {
  setRecived("")
  setMainLoading(true);

  const storageRef = ref(storage, path);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on('state_changed',
    (snapshot) => {
     
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      const nowPercent = Math.ceil(progress) + '% done';
      setLoadingPercent(nowPercent); 
      setUploadLoading(true)
    },
    (error) => {
      console.error('Error uploading file: ', error);
      console.log('Loading...');
    },
    async () => {
      console.log('Upload complete!');
      const downloadURL = await getDownloadURL(storageRef);

      if (downloadURL) {
        console.log('Download URL received:', downloadURL);
        // setRecived("recived")
     
        const isPdf = downloadURL.includes("pdf");
        const newFileUrl = { url: downloadURL, isPdf: isPdf,name:file.name };
        setFileUrls(p => [...p, newFileUrl]);
        setUploadLoading(false)
      } else {   
        console.log('Loading...');
      }
      // setRecived("")
      // setUploadLoading
      setMainLoading(false);
    }
  );

  await uploadTask;
};

  const handleCheckFile = (e, idx,docName,docSrc) => {
   const uploadFile = e.target.files[0]
   
   if (uploadFile===undefined)return;
    // 파일 확장자 확인
    const allowedExtensions = ["pdf", "jpg", "jpeg"];
    const uploadedExtension = uploadFile.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(uploadedExtension)) {
      alert("Please upload PDF file, JPG file, JPEG file only");
      return;
    }
    //파일 이름 중복되는지 확인 하기
    if (uploadFiveFiles.filter(v=>v!="").filter(file=>file.name===uploadFile.name).length>0) {
      window.confirm("You cannot upload files with the same file name.")
    return;
    }
    //파일 사이즈 확인 
    const mByte = 1024 * 1024
    const limitSize = mByte * 40
    const uploadSize = uploadFile.size
    if (limitSize <= uploadSize) {
      alert("The file size must not exceed 40 megabytes.")
    } else { 
      setMainLoading(true)
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        // const aaa=[];
        reader.onload = async function(e) {
          // aaa.push(e.target.result)
          // document.getElementById('p').src = e.target.result;
             // Blob 객체를 생성하여 PDF 파일의 첫 페이지만 자르기
           
          const res=e.target.result
          setUrlTest(e.target.result)
          //파일 저장
      setUploadTable((prevTable) => {
        const copyArr = [...prevTable];
        const res = e.target.result; // res를 여기서 가져오도록 수정
        copyArr[upIdx] = {
          custom:copyArr[upIdx].custom,
            name: copyArr[upIdx].name,
            source: copyArr[upIdx].source,
            originIdx: `${upIdx}_${idx}`,
            applicable:copyArr[upIdx].applicable,
            uploadFiles: copyArr[upIdx].uploadFiles.map((f, i) => (i === idx ? uploadFile : f)),
            thumbnails: copyArr[upIdx].thumbnails.map((f, i) => (i === idx ? res : f))
        };
        return copyArr;
    });
          // return res
        
          // if (uploadFile.type==="application/pdf") {
            const uid=uuid()

          const path=rowKey==="write"?`${uid}/${docName}/${docSrc}/${uploadFile.name}`:`${rowKey}/${docName}/${docSrc}/${uploadFile.name}`;
          const urlRes= uploadFileToStorage(uploadFile,path) 
          // }
        };
     reader.readAsDataURL(e.target.files[0]);
      } else {
  
        document.getElementById(`p`).src = "";
        return "";
      }
      
     
    // 파일 업로드가 완료되고 로딩 해제가 필요할 때 호출되므로 여기서 로딩 상태를 true로 설정
     setTimeout(() => {
       setMainLoading(false)
     }, 500);
  //  readThumbnails(e,idx)
    //여기서 파일 업로드 처리
    //  updateUploadTable(e,idx)
      // 파일 업로드 처리 후에 타이머를 클리어하고 로딩 상태를 변경
   
    }
   
   
  }
  const deleteFireStorageFile=async(path)=>{
    // Create a reference to the file to delete
const desertRef = ref(storage, path);

// Delete the file
deleteObject(desertRef).then(() => {
  // File deleted successfully
}).catch((error) => {
  // Uh-oh, an error occurred!
});
  }
const deleteUploadTable=async(e,idx,rowKey,docName,docSrc,fileName)=>{
setMainLoading(true)
//변수에서도 지우고, db에서도 지우고
setUploadTable((prevTable) => {
  const copyArr = [...prevTable];
  copyArr[upIdx] = {
    // custom:copyArr[upIdx].custom,
    // name: copyArr[upIdx].name,
    // source: copyArr[upIdx].source,
    // originIdx:`${upIdx}_${idx}`,
    // applicable:copyArr[upIdx].applicable,
    ...copyArr[upIdx],
    uploadFiles:copyArr[upIdx].uploadFiles.map((f, i) => (i === idx ? new File([],"") : f)),
    // thumbnails: copyArr[upIdx].thumbnails.map((f, i) => (i === idx ? new File([],f): f))
  };

  return copyArr;
});
const path=`${rowKey}/${docName}/${docSrc}/${fileName}`;
await deleteFireStorageFile(path)
setMainLoading(false)
}

  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    // setMainLoading(true)
    setOpen(false);
    // setMainLoading(false)
    
  };
  // useEffect(()=>{

  //   // if (!applicable) { 
  //     const initArr=Array(5).fill("")
  //     setUploadTable((prevTable) => {
  //       const newArr=[...prevTable]
  //       newArr[upIdx].applicable=applicable
  //       newArr[upIdx].uploadFiles=initArr
  //       newArr[upIdx].thumbnails=initArr
  //       return newArr
  //   });
  //   // }
    
  //   },[applicable])
    
  return <>
      <Button 
      disabled={!applicable}
      sx={{width:"80px"}}
      variant="outlined"
      size='small'
      onClick={handleClickOpen}>
        UPLOAD
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Upload documents"}
        </DialogTitle>
        <DialogContent>
          <UploadTable
          docName={docName}
          docSrc={docSrc}
 rowKey={rowKey}
          uploadFiveFiles={uploadFiveFiles} upIdx={upIdx} updateUploadTable={handleCheckFile} deleteUploadTable={deleteUploadTable}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>

}
