import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UploadTable from './UploadTable';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../service/firebase';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { IoIosImages } from 'react-icons/io';
import PDFViewer from './PDFViewer';
import ThumbnailExample from './ThumbnailExample';
import PDFViewerDialog from './PDFViewerDialog';
import {Document, Page, pdfjs} from 'react-pdf';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import { Info } from '@mui/icons-material';
import { mainLoadingAtom, uploadLoadingState, urlRecived } from '../atom/atom';
import { useRecoilState } from 'recoil';
export default function UserDocDialog({ filenameList
  ,uploadFiles
  ,thumbnails,
   rowId, docName,fileUrls,setFileUrls
  , docSrc }) {
    // const [received,setRecived]=useRecoilState(urlRecived)
  const [open, setOpen] = React.useState(false);
  // const [fileUrls, setFileUrls] = React.useState([]);
  const [pdf, setPdf] = React.useState("");
  const [pdfPages, setPdfPages] = React.useState(0); // 추가
  const [openPdfViewer, setPdfViewer] = React.useState(false)
  const [mainLoading, setMainLoading] = useRecoilState(mainLoadingAtom)
  const [thisLoading,setThisLoading]=React.useState(false)
  
const [uploadLoading,setUploadLoading]=useRecoilState(uploadLoadingState)

  // const [fileUrls,setFileUrls]=React.useState()
  const theme=useTheme() 
  const getFileUrls = async (path) => {
    // setMainLoading(true)
    // setThisLoading(true)
    const fileRef = ref(storage, path+"/");
    // const fileUrls = await getFileUrlsRecursive(fileRef);
    const result = await listAll(fileRef);
    const urls = await Promise.all(
      result.items.map(async (item) => {
  
        const url = await getDownloadURL(item);
        const isPdf = item.name.includes("pdf");
        return { url: url, isPdf: isPdf ,name:item.name};
  
      }),
    );
    // uploadFiles에 없는 파일들만 남기기
    const filteredFileUrls = urls.filter(fileUrl => uploadFiles.map(u => u.name).includes(fileUrl.name))

    setFileUrls(filteredFileUrls);
  // setThisLoading(false)
  setOpen(true)
  // setMainLoading(false)
    return fileUrls;
  };
  // const handleClickOpen = async () => {
  //   // getFiles(`${rowId}/${docName}/${docSrc}/`)
  //   await getFileUrls(`${rowId}/${docName}/${docSrc}/`);
  //   setOpen(true);
  // };
  const handleClickOpen = async () => {
    // getFiles(`${rowId}/${docName}/${docSrc}/`)
    // setMainLoading(true)
  // setThisLoading(true)
  await getFileUrls(`${rowId}/${docName}/${docSrc}/`);
    // setTimeout(async () => {  
      
    //   await getFileUrls(`${rowId}/${docName}/${docSrc}/`);
    //      setOpen(true); 
    //       //  setThisLoading(false)  
    //      // setMainLoading(false)
    // }, 7000); // 7000 milliseconds = 7 seconds
  
    
      
        
};

  const handleClose = async () => {
    setOpen(false);
  };
  const getFileUrlsRecursive = async (folderRef) => {
    const result = await listAll(folderRef);

    const urls = await Promise.all(
      result.items.map(async (item) => {
        if (item.isDirectory) {
          // 하위 디렉토리에서 파일을 재귀적으로 가져옵니다.
          return getFileUrlsRecursive(ref(storage, item.fullPath));
        } else {
          const url = await getDownloadURL(item);
          const isPdf = item.name.includes("pdf");
          return { url: url, isPdf: isPdf };
        }
      }),
    );

    // prefix로부터 내부에 있는 이미지 가져오기
    const prefixUrls = await Promise.all(
      result.prefixes.map(async (prefix) => {
        return getFileUrlsRecursive(ref(storage, prefix.fullPath));
      })
    );

    // 배열의 배열을 단일 배열로 평탄화합니다.
    return urls.concat(prefixUrls).flat();
  };
//   const getFileUrls = async (path) => {
//     setMainLoading(true)
//     const fileRef = ref(storage, path);
//     // const fileUrls = await getFileUrlsRecursive(fileRef);
//     const result = await listAll(fileRef);
//     const urls = await Promise.all(
//       result.items.map(async (item) => {

//         const url = await getDownloadURL(item);
//         const isPdf = item.name.includes("pdf");
//         return { url: url, isPdf: isPdf };

//       }),
//     );
//     const fileUrls = urls.flat()
//     setFileUrls(fileUrls)

// setMainLoading(false)
//     return fileUrls;
//   };

  const handleFileDownload=async(fileUrl,fileName)=>{

    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
  
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName; // You can customize the downloaded file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
      }
}
// const getFiles=async(path)=>{
//   await getFileUrls(path);
// }
React.useEffect(()=>{

// getFiles(`${rowId}/${docName}/${docSrc}/`)

},[uploadFiles])
// React.useEffect(()=>{
// getFiles(`${rowId}/${docName}/${docSrc}/`)
// },[])
const loading=false//uploadFiles.filter(u=>u.name!="").length!=fileUrls.length;
  return ( 
    <React.Fragment>
      <Button
  
        disabled={uploadFiles.filter(u => u.name != "").length === 0
   ||loading
        }
        sx={{ width: "80px" }}
        size='small'
        color='success' variant="outlined" onClick={handleClickOpen}>
        {/* <IoIosImages  size={20}/> */}
        {loading?<CircularProgress 
sx={{
  color:"grey"
}}
size={20} />:"VIEW"}
      </Button>
     <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Uploaded Files"}</DialogTitle>
       <DialogContent
          sx={{
            height: "800px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"space-between"
          }}
        >

          {/* 썸네일 가져오는거 */}
          {
uploadLoading?
<div
style={{
  height:"100%",
  display:"flex",
  alignItems:"center"
}}
><CircularProgress size={100} /></div>
:
            <Carousel 
            showThumbs={false}
            
            renderArrowPrev={(clickHandler, hasPrev,label) => {
              return (
                <button
                style={{
                  position:"absolute",
                  left:0,
                  top:0,
                  height:"100%",
                  border:"none",
                  backgroundColor:theme.palette.grey[300],
                  zIndex:999
                }}
                onClick={clickHandler}
                >
                 <ArrowLeftIcon/>
                </button>
              );
            }}
            renderArrowNext={(clickHandler,hasNext,label)=>{

              return <button
              style={{
                position:"absolute",
                right:0,
                top:0,
                height:"100%",
                border:"none",
                backgroundColor:theme.palette.grey[300],
                zIndex:999
              }}
              onClick={clickHandler}
              ><ArrowRightIcon/></button>
            }}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const defStyle = { marginLeft: 20,width:"26px",height:"26px",borderRadius:50,backgroundColor:"white",border:`1px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main, cursor: "pointer", };
              const style = isSelected
                ? { ...defStyle, backgroundColor:theme.palette.primary.main,border:"none",color:"white"}
                : { ...defStyle };
              return (
                <button
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >{index+1}</button>
              );
            }}
            width="530px"  renderItem={i => <div style={{
              width: "500px",
              height: "100%", objectFit: "contain",
              objectPosition: "center"
            }}>{i}</div>} >
              {/* {thumbnails.filter(thumb => thumb != "").map((info, index) => {
                if (info.includes("pdf")) {
               
                  return (
                    <div key={index} style={{
           
                      height: "300px",
                    }}>
                      <PDFViewerDialog
                     
                      pdfFile={info} />
                    </div>
                  )
                } else {
                  return (
                    <div key={index} style={{
                      height: "100%", objectFit: "contain",
                      objectPosition: "center"
                    }} >
                      <img src={info} alt={`Image ${index + 1}`} style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block"
                      }} />
                    </div>
                  );
                }

              })} */}
   {fileUrls.map((url, index) => {
                if (url.isPdf) { 
               
                  return (
                    <div key={index} style={{
           
                      height: "300px",
                    }}>
                      <PDFViewerDialog
                     
                      pdfFile={url.url} />
                    </div>
                  )
                } else {
                  return (
                    <div key={index} style={{
                      height: "100%", objectFit: "contain",
                      objectPosition: "center"
                    }} >
                      <img src={url.url} alt={`Image ${index + 1}`} style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block"
                      }} />
                    </div>
                  );
                }

              })}
            </Carousel>}
            <div
            style={{
              height:"20px"
            }}
            ></div>
            <Button

            onClick={()=>{
const filtered=uploadFiles.filter(up=>up.name!="");
            for (let i = 0; i < fileUrls.length; i++) {
              const fileName = fileUrls[i].name;
              const url=fileUrls[i].url
              // const thumb=thumbnails[i]
              // if (fileName!="") {
                     handleFileDownload(url,fileName)
              // }
          
            }
            
            }}
            fullWidth
            variant='contained'
            >FILES DOWNLOAD</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
