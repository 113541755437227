import { Button, TableCell, TableRow, Tooltip, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import { reviewColor, submitColor } from '../const/const';
import { useLocation, useNavigate } from 'react-router-dom';
import { db, storage } from '../service/firebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import FilesShowDialog from './FilesShowDialog';
import { collection, doc, getDoc } from 'firebase/firestore';

 function ReviewRow({onSubumit,row,index,transTypeOptions,onReview,length,setUsernameList,usernameList}) {
  const [hover,setHover]=useState(false)
  const [userName,setUserName]=useState(null)
  // const [userNameList,setUserNameList]=useState([])
  const [cellLoading,setCellLoading]=useState(false)
  const theme=useTheme()
  const navigate=useNavigate()
  const location=useLocation()
  const userCollection = collection(db, "userInfos")
  const getUserName = async () => {
    try {
      const docRef = doc(userCollection, row["userId"]);
      const docData = (await getDoc(docRef)).data();
      // const userNames=[];
      const name = docData.name;
      // userNames.push(name)
      setUsernameList(p=>[...p,{bdNum:row["bdNum"],id:row["userId"],name:name}])
      return name; // 문자열 형태의 이름 반환
    } catch (error) {
      return "-"; // 에러 발생 시 "-" 반환
    }
    
  }
  useEffect(()=>{
getUserName()
const nameSet= new Set(usernameList)
const newUserName=[...nameSet].filter(v=>(v.bdNum===row.bdNum)&&(v.id===row.userId))[0];
 setUserName(newUserName)
  },[row])
  useEffect(()=>{
if (userName) {
  setCellLoading(true)
} else {
  setCellLoading(false)
}

  },[userName])
  const onMouseEnter=()=>{
setHover(true)

  }
  const onMouseLeave=()=>{setHover(false)}
  const onEditNavigate=()=>{
      const nowUserId=row.userId//location.state.id
      const userType=location.state.userType
      const rowKey=row.id
      console.log("review table",rowKey,nowUserId,row,userType)

            localStorage.setItem("rowKey",rowKey)
            localStorage.setItem("isSaved","false")
            sessionStorage.setItem("editBdNum",row.bdNum)
        navigate(`/fileupload/edit/${index+1}`,
        {state:
          {id:nowUserId,
          userType:userType,
          row:row,
          rowKey:rowKey,
        
        }})
      

  }
  // console.log("유저네임네임>>>",cellLoading)
    return (
      <TableRow key={row.name}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
     
        sx={{
          // opacity:hover?"0.5":"1",
          backgroundColor:hover?"rgba(164, 204, 252, 0.2)":null,
          cursor: "pointer"
        }}

      >

        <TableCell component="th" scope="row"
        onClick={onEditNavigate}
        >
          {length-index}
        </TableCell>

        <TableCell component="th" scope="row"
         onClick={onEditNavigate}
        >
          {row["bdNum"]}
        </TableCell>
        <TableCell component="th" scope="row"
         onClick={onEditNavigate}
        >
                {row["closingDetail"]["closingDate"]}
              </TableCell>
              <TableCell component="th" scope="row"
         onClick={onEditNavigate}
        >
          {row["userId"]}
        </TableCell>
        <TableCell component="th" scope="row"
         onClick={onEditNavigate}
        >
          {row["newTransactionDetail"]["transAddress"]}
        </TableCell>
        <TableCell component="th" scope="row"
        onClick={onEditNavigate}
        >
              {row["newTransactionDetail"]["transCity"]}
              </TableCell>
        <TableCell component="th" scope="row"
         onClick={onEditNavigate}
        >
          {transTypeOptions[row["newTransactionDetail"]["transType"]]}
          
        </TableCell>
        
        <TableCell component="th" scope="row">
         
          <Tooltip
            title={row["formState"] === "save"  ?  "Not yet" : "Submitted"}
          ><div
            style={{ display: "flex", gap: "16px", alignItems: "center" }}

          >
              <FaArrowCircleUp size={20} color={row["formState"] === "save" ? "gray" :submitColor } />
              <Button
        variant='contained'
        size='small'
        // color='secondary'
        sx={{
          width:"80px",
          backgroundColor:row["formState"] === "save" ? submitColor : "gray",
          ":hover":{
            backgroundColor:row["formState"] === "save" ? submitColor : "gray",
          }
        }}
        onClick={()=>{
          const toChangeState = row["formState"] === "save" ? "submit" : "unsubmit"
          const res = window.confirm(`Would you like to change the status to "${toChangeState}"?`)
          if (res) {
            onSubumit(row["id"], row["userId"])
          }
        }}
        >{row["formState"] === "save" ?"Submit":"Unsubmit"}</Button>
          
            </div></Tooltip>
        </TableCell>
      
        <TableCell component="th" scope="row" >
          {/* {row["finish"]===true?"Finished":"Progressing"} */}
          <Tooltip
            title={row["finish"] === true ? "Finish" : "Progressing"}
          ><div
            style={{ display: "flex", gap: "16px", alignItems: "center" }}

          >
              <FaCircleCheck size={20} color={row["finish"] === true ? reviewColor : "gray"} />

              <Button
                variant='contained'
                color={row["finish"] === true?"error":"primary"}
                size='small'
                onClick={
                  () => {
                    const toChangeState = row["finish"] === true ? "Progressing" : "Finished"
                    const res = window.confirm(`Would you like to change the status to "${toChangeState}"?`)
                    if (res) {
                      onReview(row["id"], row["userId"])
                    }
                  }


                }>{row["finish"] === true?"CANCEL":"REVIEW"}</Button>

            </div></Tooltip>
        </TableCell>
        {/* <TableCell component="th" scope="row" >
          
<FilesShowDialog rowId={row.id}/>

            
        </TableCell> */}
      </TableRow>
    );
}

export default ReviewRow;