import { Chip } from '@mui/material';
import React from 'react';

function ChipRow({label,chipBackgroundColor,readOnly=false,title,titleWidth="500px",width="200px",containerWidth="800px"}) {
    return (
        <div
        style={{
            display:"flex",
alignItems:"center",gap:"8px"
        }}
        >
            <div style={{fontSize:"16px",display:"flex",width:titleWidth,justifyContent:"flex-end"}}>{title+" : "}</div>  
           <div style={{width:containerWidth}}>
            
           <Chip label={label}
        sx={{
            width:"60px",
          backgroundColor:chipBackgroundColor,
          color:"white"
        }}
        /></div> 
        </div>
    );
}

export default ChipRow;