import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, TableHead, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IoCheckmarkCircle } from "react-icons/io5";
import UploadDialog from './UploadDialog';
import UploadRow from './UploadRow';

export default function UploadTable({
  docName,
docSrc,
rowKey,
  uploadFiveFiles,updateUploadTable,deleteUploadTable}) {


  // Avoid a layout jump when reaching the last page with empty rows.
  return (
    <TableContainer component={Paper}>

      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
       
        <TableBody>
      {
      uploadFiveFiles===undefined?<>Loading...</>: 
      
      uploadFiveFiles.map((file,index)=>
      
      <UploadRow 
      docName={docName}docSrc={docSrc}
      rowKey={rowKey}
      index={index} row={file} updateUploadTable={updateUploadTable} deleteUploadTable={deleteUploadTable}/>)
      }
          {/* <UploadRow index={0} row={files[0]} handleFileChange={handleFilesUpdate}/>
          <UploadRow index={1} row={files[1]} handleFileChange={handleFilesUpdate}/>
          <UploadRow index={2} row={files[2]} handleFileChange={handleFilesUpdate}/>
          <UploadRow index={3} row={files[3]} handleFileChange={handleFilesUpdate}/>
          <UploadRow index={4} row={files[4]} handleFileChange={handleFilesUpdate}/> */}
        
        </TableBody>

      </Table>
      {/* <Button
      fullWidth
      onClick={handleUploadTableAdd}
      > 
     <IoMdAdd size={20}/> 
      ADD DOCUMENT NAME / SOURCE OF DOCUMENT </Button> */}
    </TableContainer>
  );
}
