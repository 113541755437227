import { Button, Chip, IconButton } from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import { MdZoomIn,MdZoomOut } from "react-icons/md";
import { FiZoomIn,FiZoomOut } from "react-icons/fi";
import { MdFirstPage,MdLastPage } from "react-icons/md";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.min.js"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// const options = {
//     cMapUrl: '/cmaps/',
//     standardFontDataUrl: '/standard_fonts/',
//   };
const PDFViewer = ({pdfFile,setPageNumber,pageNumber,numPages,setNumPages}) => {
    // 총 페이지수
   
    const [pageScale, setPageScale] = useState(1); // 페이지 스케일
 
 
    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return (
        <div
        style={{
            // width:"500px",
            height:`calc( 100% / ${numPages})`,
             objectFit: "contain",
                objectPosition: "center"
        }}
        // style={{ width:"500px",
        // height:"500px" }}
        >
            {/* pdf 크기가 1280 * 720이 넘는 경우, overflow 처리 */}
            <div    style={{
            // width:"500px",
            height:`calc( 100% / ${numPages})`, objectFit: "contain",
                objectPosition: "center"
        }}>
          
             <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}
            on
            onScroll={
                (e)=>{
                    

                }
            } >
               {
                Array(numPages).fill().map((v,idx)=><Page   pageNumber={idx+1} scale={pageScale}  />)
               }
            
   
            {/* <Page   pageNumber={pageNumber} scale={pageScale}  />
            <Page   pageNumber={pageNumber} scale={pageScale}  />
            <Page   pageNumber={pageNumber} scale={pageScale}  /> */}
 
  </Document>
            </div>
            <div
            style={{
                position:"absolute"
                ,left:0,top:0,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:"32px",
                marginLeft:"16px"
                
            }}
            >
                <Chip 
                color='success'
                label={`Page ${pageNumber} of ${numPages}`}></Chip>
               

                {/* <p>Move Page</p>
              <Button 

              variant='contained'
              style={{width:"30px",height:"30px"}}
              onClick={() => {
                    setPageNumber(pageNumber === 1 ? pageNumber : pageNumber - 1)
                }}> <MdFirstPage size={20}/>
                </Button>
               
                <Button 
                variant='contained'
                style={{width:"30px",height:"30px"}}
                onClick={() => {
                    setPageNumber(numPages === pageNumber ? pageNumber : pageNumber + 1)
                }}> <MdLastPage size={20}/>
                </Button>
                / */}
                <p>Page Scale</p>
                <Button 
                variant='contained'
                style={{
                    cursor:"pointer",width:"30px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}}
                onClick={() => {
                    setPageScale(pageScale === 3 ? 3 : pageScale + 0.1)
                }}><FiZoomIn size={20}/>

                </Button>
                <Button 
                variant='contained'
                style={{
                    cursor:"pointer",width:"30px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}}
                onClick={() => {
                    setPageScale((pageScale - 1) < 1 ? 1 : pageScale - 1)
                }}> <FiZoomOut size={20}/>
                </Button>
            </div>
        </div>
    );
};

export default PDFViewer;