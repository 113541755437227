import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { IoIosImages } from 'react-icons/io';
import NoticeRow from './NoticeRow';
import { db } from '../service/firebase';
import { useLocation } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';

function NoticeTable({
  getDocumentsWithCommunityField,
  deleteList,
  setDeleteList,tableRows,type,open}) {
  const theme=useTheme()
  const location=useLocation()
  // const [tableRows,setTableRows]=useState([])
 const [noticeTwoRows,setNoticeTwoRows]=useState([])
 const [scrollIdx,setScrollIdx]=useState(7)
 const scrollRef=useRef(null)
 const scrollBottomHeight=44*7
 //게시판을 스크롤 할 때마다 인덱스 플러스플러스해서 2개씩 잘라서 가져온다
 const handleScroll = (event) => {
  const target = event.target;
  const scrollBottom = Math.round(target.scrollHeight - target.scrollTop) === scrollBottomHeight;

  if (scrollBottom) {
    setScrollIdx((prevIdx) => prevIdx + 7); // Increase index by 2
  }
};

 useEffect(()=>{
setNoticeTwoRows(tableRows.slice(0,scrollIdx))

 },[tableRows,scrollIdx])


    return (
     
        <TableContainer component={Paper} variant='outlined'>
    {tableRows.length===0? <Table 
        size='small'
        sx={{ minWidth: 500,display:"flex" }} aria-label="custom pagination table">
   
          <TableBody
          sx={{
          width:"100%" ,
          borderRight:`1px solid ${theme.palette.grey[300]}`
        ,
        boxSizing:"content-box"  
        }}
          ><TableRow >No Data</TableRow></TableBody></Table>:
        <Table 
      
        size='small'
        sx={{ minWidth: 500,height:`calc(44px * 7)`,display:"flex" }} aria-label="custom pagination table">
   
          <TableBody  
          ref={scrollRef}
          
          onScroll={handleScroll}
          sx={{
          width:"100%" ,
          borderRight:`1px solid ${theme.palette.grey[300]}`
        ,
        boxSizing:"content-box"  ,
        overflowY:noticeTwoRows.length>1?"scroll":"hidden"
        }}
          >
            
            {noticeTwoRows.map((row,idx)=>
            <NoticeRow
            getDocumentsWithCommunityField={getDocumentsWithCommunityField}
            deleteList={deleteList}
setDeleteList={setDeleteList}
            type={type} row={row} index={idx+1}/>
            )}
           

          </TableBody>
          {/* <TableBody
            sx={{
              width:"100%" 
              }}
          >
            
            {tableRows.slice(2,4).map((row,idx)=>
            <NoticeRow row={row} index={idx+1}/>
            )}

          </TableBody> */}
         
        </Table>}
      </TableContainer>
    );
}

export default NoticeTable;