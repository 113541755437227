import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, TableHead, Tooltip } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { transactionTypeOptionsState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import { FaCircleCheck } from "react-icons/fa6";
import { FaArrowCircleUp } from "react-icons/fa";
import { basicTableHeight, mdFontSize, primaryCode, reviewColor, smFontSize, submitColor } from '../const/const';
import ReviewRow from './ReviewRow';
import AdminTableLoader from "./AdminTableLoader"
import TransDocRow from './TransDocRow';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



export default function TransDocTable({ tableDatas, setTableDatas, transTypeList }) {
  const transTypeCollection = collection(db, "options")
  const datasCollection = collection(db, "pjDatas")
  const dataCollection = collection(db, "pjDatas")

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [transTypeOptions, setTransTypeOptions] = useRecoilState(transactionTypeOptionsState)

  const [filteredRow, setFilteredRow] = React.useState([])
  const theme = useTheme()

  async function getTransTypeOptions() {
    const docRef = doc(transTypeCollection, "transactionType")
    const transData = await getDoc(docRef)

    if (transData.exists()) {
      setTransTypeOptions(transData.data()["selectList"])


    } else {
    }
  }
  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {

    getTransTypeOptions()
   
  }, [])

 const sixtyRows=Array.from({length:80},(v,i)=>i)
  return (
  //   filteredRow === null || filteredRow === undefined || Object.values(tableDatas).length === 0 ? 
  //   <TableContainer variant='outlined'
  // component={Paper} sx={{
  //       height: basicTableHeight,
  //       width: "100%"
  //     }}>

  //     <AdminTableLoader />
  //   </TableContainer> :
      <TableContainer  variant='outlined'
      component={Paper} sx={{
            height: basicTableHeight,
            width: "100%"
          }}> 
           <Table

        size='small'
        stickyHeader
        sx={{ minWidth: 500 }} aria-label="a dense table">
        
        <TableHead >
        <TableRow >
              <TableCell align="center" colSpan={3}>
                Residential Listing
              </TableCell>
        
            </TableRow>
            <TableRow>
            <TableCell style={{ width: 160 }} scope="row" align='center'>
            Number
          </TableCell>
              <TableCell style={{ width: 300 }} scope="row" align='center'>
            Document name
          </TableCell>
          <TableCell style={{ width: 20 }} scope="row" align='center'>
            Source of document
          </TableCell>
          </TableRow>
          
        </TableHead>

        <TableBody>
          {sixtyRows.map((l,lIdx)=>{
            return <TransDocRow row={tableDatas[lIdx]} tableDatas={tableDatas} setTableDatas={setTableDatas} index={lIdx}/>
          })}
          {/* {filteredRow.map((row, index) => {


            return (<></>
            )
          })} */}
          
        </TableBody>

      </Table></TableContainer>


  );
}
