import moment from 'moment';
import React, { useEffect, useState } from 'react';

function Clock({ time, setTime }) {
    useEffect(() => {
        let animationFrameId;

        const updateClock = () => {
            const m = moment().format('YYYY-MM-DD HH:mm:ss');
            setTime(m);
            animationFrameId = requestAnimationFrame(updateClock);
        };

        updateClock();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [setTime]);
    return (


        <div style={{ width: "50vw" }}>
            {time}
        </div>


    );
}

export default Clock;