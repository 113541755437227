import { IconButton, useTheme } from '@mui/material';
import React from 'react';
import { MdDarkMode, MdLightMode } from 'react-icons/md';

function ThemeToggleBtn({toggleColorMode, disable=false}) {
    const theme=useTheme()
    return (
        <IconButton
        disabled={disable}
        onClick={toggleColorMode} sx={{ p: 0,opacity:disable?0:1 }}>
          {/* <MdLightMode size={30}/> */}
          {theme.palette.mode==='light'?<MdDarkMode size={30}/>:<MdLightMode size={30}/>}
            </IconButton>
    );
}

export default ThemeToggleBtn;