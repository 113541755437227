import React, { useEffect, useState } from 'react';
import TransDocTable from '../components/TransDocTable';
import FilterSearchBar from '../components/FilterSearchBar';
import { Button } from '@mui/material';
import { db } from '../service/firebase';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import TransDocSearchBar from "../components/TransDocSearchBar"
import { useRecoilState } from 'recoil';
import { mainLoadingAtom } from '../atom/atom';
function TransDocContent(props) {
    const initialData = Array.from({ length: 60 }, () => ({ name: "", source: "" }));
    const [transacList,setTransacList]=useState([])
    const [selTransc,setSelTransac]=useState("")
    const [tableDatas,setTableDatas]=useState(initialData)
    const [mainLoading,setMainLoading]=useRecoilState(mainLoadingAtom)
    const getTransType=async()=>{
        setMainLoading(true)
        const transCollection = collection(db, "options")
        const docRef = doc(transCollection, "transactionType")
        const data = await getDoc(docRef)
        
        if (data.exists()) {
            const res=data.data()["selectList"]
            setTransacList(res)
            setSelTransac(res[0])
        } else {
            setTransacList([])
        }
        setMainLoading(false)
    }
    const getTableDatas = async () => {
        const docsCollection = collection(db, "documentsForTransaction");
        const docRef = doc(docsCollection, selTransc);
        const data = await getDoc(docRef);
    
        if (data.exists()) {
            const res = data.data()["documents"];
            setTableDatas(res);
        } else {
            // 문서가 존재하지 않으면 새로운 문서 생성
            const newDocRef = doc(docsCollection, selTransc);
            // 여기에서 초기화할 데이터를 설정해주세요.
            await setDoc(newDocRef, { documents: initialData });
    
            // 생성한 문서의 데이터로 tableDatas 설정
            setTableDatas(initialData);
        }
    };
    const handleSearchList=(e,val)=>{
            setSelTransac(val)
    }
    const handleDocSave = async () => {
        setMainLoading(true)
        const checkList=tableDatas.filter(row=>(row.source === '' && row.name !== '') || (row.source !== '' && row.name === ''))

        if (checkList.length>0) {
            const msgList=checkList.map(ch=>{return `- name : ${ch.name===""?"empty":ch.name} / source : ${ch.source===""?"empty":ch.source}\n`})
            window.confirm(`Please enter both name and source.\n${msgList.join("")}`)
            return;
        }else{
  const docsCollection = collection(db, "documentsForTransaction");
    
        // 체크할 문서의 Reference를 생성합니다.
        const docRef = doc(docsCollection, selTransc);
    
        // 문서가 존재하는지 확인합니다.
        const docSnapshot = await getDoc(docRef);
    
        if (docSnapshot.exists()) {
            // 문서가 이미 존재하면 업데이트합니다.
            const updateObjectList = {
                [`documents`]: tableDatas,
            };
            await updateDoc(docRef, updateObjectList);
        } else {
            // 문서가 존재하지 않으면 새로 생성합니다.
            const newDocData = {
                ["documents"]: tableDatas,
                
                // 다른 필요한 필드들도 추가할 수 있습니다.
            };
            await setDoc(docRef, newDocData);
        }
        window.confirm("Successfully saved.")

        }
        setMainLoading(false)
      
    };
    useEffect(()=>{
        getTransType()
    },[])
    useEffect(()=>{
        
if (selTransc==="")return;
getTableDatas()
    },[selTransc])
    return (
        <div className='ContentBox'>
        {/* transType select하는 구간 */}
        
       <div
       style={{display:"flex",width:"100%",justifyContent:"flex-start",gap:"16px"}}
       >
        <TransDocSearchBar 
        disableClearable={true}
        multiple={false}
        width='100%' 
        options={transacList}
        defaultValue={selTransc}
        handleSearchList={handleSearchList}
        />
        <Button variant='contained' color='secondary' onClick={handleDocSave}>SAVE</Button>
       </div>
            <TransDocTable tableDatas={tableDatas} setTableDatas={setTableDatas}/>
        </div>
    );
}

export default TransDocContent;