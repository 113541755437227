import React, { useEffect, useRef, useState } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import M from '../images/remodeling/remodeling.jpg'
import Before1 from '../images/remodelpurchase/before1.jpg'
import Before21 from '../images/remodelpurchase/before21.jpg'
import Before22 from '../images/remodelpurchase/before22.jpg'
import Before2 from '../images/remodelpurchase/before2.jpg'
import After1 from '../images/remodelpurchase/after1.jpg'
import After21 from '../images/remodelpurchase/after21.jpg'
import After22 from '../images/remodelpurchase/after22.jpg'
import After2 from '../images/remodelpurchase/after2.jpg'

import grid111 from '../images/remodelpurchase/grid111.jpg'
import grid112 from '../images/remodelpurchase/grid112.jpg'
import grid121 from '../images/remodelpurchase/grid121.jpg'
import grid122 from '../images/remodelpurchase/grid122.jpg'
import grid211 from '../images/remodelpurchase/grid211.jpg'
import grid212 from '../images/remodelpurchase/grid212.jpg'
import grid311 from '../images/remodelpurchase/grid311.jpg'
import grid322 from '../images/remodelpurchase/grid322.jpg'
import beforelast from '../images/remodelpurchase/beforelast.jpg'
import afterlast from '../images/remodelpurchase/afterlast.jpg'
import { Card, CardContent } from '@mui/material';
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import { useTranslation } from 'react-i18next';
function RemodelPurchase(props) {
    const {t}=useTranslation()
        // ref를 생성하여 purchase-txt 구간을 참조
        const purchaseTxtRef = useRef(null);
        const [fadeIn, setFadeIn] = useState(false);
        useEffect(() => {
                setTimeout(() => setFadeIn(true), 200); // 약간의 지연 후 애니메이션 시작
            }, []);
        // 버튼 클릭 시 스크롤 이벤트 처리
        const handleScrollToSection = () => {
            if (purchaseTxtRef.current) {
                purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };
    const SampleHouseCardSx={
        width:"335px",
        minWidth:"335px",
        display:"flex",
        flexDirection:"column",
        margin:0,
        backgroundColor:"#F5E9C7"
    }
    const BeforeCardSx={
   borderRadius:"30px",
   height:"300px",
   width:"260px"
    }
    const LeftAlignCardSx={
        borderRadius:"30px",
        height:"420px",
        width:"500px"
         }
         const LeftAlignCardMbSx={
            borderRadius:"30px",
            height:"420px",
            width:"100%"
             }
         const RightAlignCardSx={
            borderRadius:"30px",
            height:"420px",
            width:"700px"
             }
    const AfterCardSx={
        borderRadius:"30px",
        height:"300px",
        width:"100%"
         }
    const SampleHouseCardContentSx={
        padding:0
    }
    return (
        <div>
            <PC>
            <div className={`remodeling-area ${fadeIn ? 'fade-in' : ''}`}>
                {/* 맨위에 이미지랑 글자 */}
<div className="main-banner-txts">
        <div className="banner-txt-top">
       <div className="column"
       style={{
        display:"flex",
        flexDirection:"column"
       }}
       >
     
        <span>
            <strong
            style={{
                fontSize:"55px"
            }}
            >
            {t("remodelPurchase.bannerTop1")}
            </strong>
            
        </span>
       </div>
        </div>
        <div className="banner-txt-bottom">
        {t("remodelPurchase.bannerBottom")}
        </div>
        <div className="more">
    <CircleArrowBtn onClick={handleScrollToSection}/>
   </div>
    </div>
    <div className="remodeling-img">
    <img src={M}
    style={{
        width:"800px"
    }}
    />

    </div>
</div>
<div className="staging-txt"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
        whiteSpace:"nowrap"
    }}
    >{t("remodelPurchase.title")}</strong>
    <div className="staging-txt-bottom"
    style={{
        textAlign:"center"
    }}
    >
 {t("remodelPurchase.content1")}  </div>

</div>
{/* 왼쪽 정렬 비포1 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터1 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.after")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent3")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={After1}/>
            </CardContent>
        </Card>
        
</div>
{/* 왼쪽 정렬 비포2 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터2 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.after")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent4")}</span>
            
                
            
           
        </div>

<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={After2}/>
            </CardContent>
        </Card>
        
</div>

{/* 이미지 그리드 */}
<div className="remodeling-before-after"
style={{
    
    width: "calc(100% - 300px - 300px)",
    marginLeft: "300px",
    marginRight: "300px",
}}
>
    {/* 첫번째줄 사진 */}
    <div className="remodeling-row">

        {/* 전 */}
    <div className="before-area">
        <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={grid111}/>
            </CardContent>
        </Card>
        <span> {t("imgGrid.before")}</span>
    </div>
    {/* 후 */}
    <div className="after-area">
        <Card
        sx={AfterCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-after-img'
                src={grid112}/>
            </CardContent>
        </Card>
        <span> {t("imgGrid.after")}</span>
    </div>
    </div>
    {/* 두번째줄 사진 */}
    <div className="remodeling-row">

{/* 전 */}
<div className="before-area">
<Card
sx={BeforeCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-before-img'
        src={grid121}/>
    </CardContent>
</Card>
<span>{t("imgGrid.before")}</span>
</div>
{/* 후 */}
<div className="after-area">
<Card
sx={AfterCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-after-img'
        src={grid122}/>
    </CardContent>
</Card>
<span>{t("imgGrid.after")}</span>
</div>
</div></div>
{/* before after 두번째거 시작 */}
{/* 왼쪽 정렬 비포2-1 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before21}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent2")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터2-1 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.after")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent5")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={After21}/>
            </CardContent>
        </Card>
        
</div>
{/* 왼쪽 정렬 비포2-2 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before22}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent3")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터2-2 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.after")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent4")}</span>
            
                
            
           
        </div>

<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={After22}/>
            </CardContent>
        </Card>
        
</div>
{/* 이미지 그리드2 */}
<div className="remodeling-before-after"
style={{
    
    width: "calc(100% - 300px - 300px)",
    marginLeft: "300px",
    marginRight: "300px",
}}
>
    {/* 첫번째줄 사진 */}
    <div className="remodeling-row">

        {/* 전 */}
    <div className="before-area">
        <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={grid211}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.before")}</span>
    </div>
    {/* 후 */}
    <div className="after-area">
        <Card
        sx={AfterCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-after-img'
                src={grid212}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.after")}</span>
    </div>
    </div>
    {/* 두번째줄 사진 */}
    <div className="remodeling-row">

{/* 전 */}
<div className="before-area">
<Card
sx={BeforeCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-before-img'
        src={grid311}/>
    </CardContent>
</Card>
<span>{t("imgGrid.before")}</span>
</div>
{/* 후 */}
<div className="after-area">
<Card
sx={AfterCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-after-img'
        src={grid322}/>
    </CardContent>
</Card>
<span>{t("imgGrid.after")}</span>
</div>
</div></div>
{/* 왼쪽 정렬 비포 last*/}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={beforelast}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent4")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터 last */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.after")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent6")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={afterlast}/>
            </CardContent>
        </Card>
        
</div>
            </PC>
            <Mobile>
            <div className="remodeling-area-mb">
                {/* 맨위에 이미지랑 글자 */}
                <div className="remodeling-img-mb">
    <img src={M}
    style={{
            transform:"translateX(-120px)"
        // width:"800px"
    }}
    />

    </div>
<div className="main-banner-txts-mb">
        <div className="banner-txt-top-mb">
   
        <span>
            <strong
            style={{
                fontSize:"36px",
            }}
            >
           {t("remodelPurchase.bannerTop1")}
            </strong>
 
        </span>
       </div>
        <div className="banner-txt-bottom-mb">
        {t("remodelPurchase.bannerBottom")}
        </div>
        <div className="more">
    <CircleArrowBtn onClick={handleScrollToSection}/>
   </div>
    </div>
  
</div>
<div className="staging-txt-mb"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
    }}
    >{t("remodelPurchase.title")}</strong>
    <div className="staging-txt-bottom-mb"
    style={{
        textAlign:"center"
    }}
    >
  {t("remodelPurchase.content1")} </div>

</div>
{/* 왼쪽 정렬 비포1 */}
<div className="photos-grid-mb">
<Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")} </strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")} </span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-160px)"
                }}
                src={After1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent3")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-160px)"
                }}
                src={Before2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-160px)"
                }}
                src={After2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent4")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-130px)"
                }}
                src={grid111}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
          
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.before")}</strong>
             
           </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-100px)"
                }}
                src={grid112}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
           
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.after")}</strong>
                
            
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-130px)"
                }}
                src={grid121}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
          
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.before")}</strong>
             
           </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-100px)"
                }}
                src={grid122}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
           
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.after")}</strong>
                
            
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-130px)"
                }}
                src={Before21}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
          
        <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent2")}</span>
            
             
           </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-160px)"
                }}
                src={After21}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
               
        <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}

</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent5")}</span>
            
            
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-130px)"
                }}
                src={Before22}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
          
        <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent3")}</span>
            
            
           </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-100px)"
                }}
                src={After22}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
           
        <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent4")}</span>
            
                
            
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-130px)"
                }}
                src={grid211}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
          
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.before")}</strong>
             
           </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-100px)"
                }}
                src={grid212}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
           
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.after")}</strong>
                
            
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-130px)"
                }}
                src={grid311}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
          
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.before")}</strong>
             
           </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-100px)"
                }}
                src={grid322}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
           
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.after")}</strong>
                
            
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-130px)"
                }}
                src={beforelast}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
          
        <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent4")}</span>
            
             
           </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                style={{
                    transform:"translateX(-100px)"
                }}
                src={afterlast}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent6")}</span>
            
        </div>
</div>

            </Mobile>
        </div>
    );
}

export default RemodelPurchase;