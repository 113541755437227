import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import LoginPage from './page/LoginPage';
import CustomLayout from './page/CustomLayout';
import TableContent from './content/TableContent';
import WriteContent from './content/WriteContent';
import EditContent from './content/EditContent';
import SignUpPage from './page/SignUpPage';
import UserSettingContent from "./content/UserSettingContent"
import ReviewContent from './content/ReviewContent';
import AdminBrokerSplitContent from './content/AdminBrokerSplitContent';
import TransDocContent from './content/TransDocContent';
import CommunityContent from "./content/CommunityContent"
import EmailSendContent from './content/EmailSendContent';
import PwChangeContent from './content/PwChangeContent';
import InitPage from './page/InitPage';
import TestEditContent from './content/TestEditContent';
import MainPage from './page/MainPage';
import MainLayout from './page/MainLayout';
import HomeStaging from './page/HomeStaging';
import Manage from './page/Manage';
import Flip from './page/Flip';
import Contact from './page/Contact';
import Purchase from './page/Purchase';
import Sale from './page/Sale';
import Commercial from './page/Commercial';
import RemodelRes from './page/RemodelRes';
import RemodelPurchase from './page/RemodelPurchase';
import './translation/i18n'; // i18n 설정 파일 import
import ListProperties from './page/ListProperties';
import PropertyShow from './page/PropertyShow';
//1.라우터를 생성한다.
const router = createBrowserRouter(

  [
  //첫페이지->로그인
  // { 
  //   element: <InitPage/>, path: "/" },
  // 첫페이지->회사소개페이지
  { 
    element: <MainLayout/>,  children:[
   
      {
       
   path: "/",
   element: <MainPage/>
 },
 {
       
  path: "/homestaging",
  element: <HomeStaging/>
},
{
       
  path: "/list-properties",
  element: <ListProperties/>
},
{
       
  path: "/manage",
  element: <Manage/>
},
{
       
  path: "/flip",
  element: <Flip/>
},
{
       
  path: "/contact",
  element: <Contact/>
},
{
       
  path: "/purchase",
  element: <Purchase/>
},
{
       
  path: "/sale",
  element: <Sale/>
},
{
       
  path: "/commercial",
  element: <Commercial/>
},
{
       
  path: "/remodel-res",
  element: <RemodelRes/>
},
{
       
  path: "/remodel-purchase",
  element: <RemodelPurchase/>
},
{
       
  path: "/property-show",
  element: <PropertyShow/>
},
] },
  { 
    element: <LoginPage />, path: "/fileupload/login" },
  { element: <SignUpPage />, path: "/fileupload/signup" },
{
  element:<CustomLayout />,
  //내부 화면만 교체
  children:[
   
     {
      
  path: "/fileupload/table",
  element: <TableContent />,
},
{
  path:"/fileupload/pw-change",
  element:<PwChangeContent/>
},
{ path: "/fileupload/edit/:index", 
  element:<EditContent/>//<TestEditContent/> 
}, 
// 동적 라우팅 추가
{

  path: "/fileupload/write",
  element: <WriteContent/>,
},
//admin용 라우팅
{
  path: "/fileupload/user-setting",
  element: <UserSettingContent/>,
},
{
  path: "/fileupload/review",
  element: <ReviewContent/>,
},
{
  path: "/fileupload/broker-split",
  element: <AdminBrokerSplitContent/>,
},
{
  path: "/fileupload/transaction-doc",
  element: <TransDocContent/>,
},
//게시판,정보공유방
//관리자가 글, 이미지 올리고 , 유저는 다운만 받을 수 있음.
// {
//   path: "community",
//   element: <CommunityContent/>,
// },
//email을 유저들에게 보내는 페이지
{
  path: "email-send",
  element: <EmailSendContent/>,
},
]
}

],

);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <RecoilRoot >
        {/* <Suspense fallback="로딩중"> */}
        {/* <ThemeProvider theme={theme}>
      <CssBaseline /> */}
          <RouterProvider   router={router} />
          {/* </ThemeProvider> */}
        {/* </Suspense> */}
     
    </RecoilRoot>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
