import React, { useEffect, useRef, useState } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import Fl from '../images/flip/fl.jpg'
import beforefl1 from '../images/flip/beforefl1.jpg'
import beforefl2 from '../images/flip/beforefl2.jpg'
import afterfl1 from '../images/flip/afterfl1.jpg'
import afterfl2 from '../images/flip/afterfl2.jpg'
import left1 from '../images/flip/left1.jpg'
import left2 from '../images/flip/left2.jpg'
import right1 from '../images/flip/right1.jpg'
import right2 from '../images/flip/right2.jpg'
import right21 from '../images/flip/right2-1.jpg'
import before31 from '../images/flip/before31.jpg'
import after31 from '../images/flip/after31.jpg'
import beforelast from '../images/flip/left3.jpg'
import afterlast from '../images/flip/right3-1.jpg'
import gridbefore1 from '../images/flip/gridbefore1.jpg'
import gridbefore2 from '../images/flip/gridbefore2.jpg'
import gridafter1 from '../images/flip/gridafter1.jpg'
import gridafter2 from '../images/flip/gridafter2.jpg'
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import { Card, CardContent } from '@mui/material';
import './Flip.css'
import { useTranslation } from 'react-i18next';
function Flip(props) {
    const {t}=useTranslation()
         // ref를 생성하여 purchase-txt 구간을 참조
         const purchaseTxtRef = useRef(null);
         const [fadeIn, setFadeIn] = useState(false);
         useEffect(() => {
                 setTimeout(() => setFadeIn(true), 200); // 약간의 지연 후 애니메이션 시작
             }, []);
         const ref2 = useRef(null);
         // 버튼 클릭 시 스크롤 이벤트 처리
         const handleScrollToSection = () => {
             if (purchaseTxtRef.current) {
                 purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
             }
         };
   

         // 버튼 클릭 시 스크롤 이벤트 처리
         const handleScrollToSection2 = () => {
             if (ref2.current) {
                 ref2.current.scrollIntoView({ behavior: 'smooth' });
             }
         };
    const SampleHouseCardSx={
        width:"335px",
        minWidth:"335px",
        display:"flex",
        flexDirection:"column",
        margin:0,
        backgroundColor:"#F5E9C7"
    }
    const BeforeCardSx={
   borderRadius:"30px",
   height:"300px",
   width:"260px"
    }
    const LeftAlignCardSx={
        borderRadius:"30px",
        height:"420px",
        width:"500px"
         }
         const RightAlignCardSx={
            borderRadius:"30px",
            height:"420px",
            width:"700px"
             }
    const AfterCardSx={
        borderRadius:"30px",
        height:"300px",
        width:"100%"
         }
    const SampleHouseCardContentSx={
        padding:0
    }
    const LeftAlignCardMbSx={
        borderRadius:"30px",
        height:"420px",
        width:"100%"
         }
    return (
        <div>
            <PC>
            <div className={`remodeling-area ${fadeIn ? 'fade-in' : ''}`}>
                {/* 맨위에 이미지랑 글자 */}
<div className="main-banner-txts">
        <div className="banner-txt-top">
       <div className="column"
       style={{
        display:"flex",
        flexDirection:"column"
       }}
       >

       <span>
        <strong
        style={{
            fontSize:"55px"
        }}
        >{t("flipInvest.bannerTop1")}</strong>
       </span>

       </div>
        </div>
        <div className="banner-txt-bottom">
        {t("flipInvest.bannerBottom")}</div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
    </div>
    <div className="remodeling-img">
    <img src={Fl}
    style={{
        width:"800px"
    }}
    />

    </div>
</div>
<div className="staging-txt"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
        whiteSpace:"nowrap"
    }}
    >{t("flipInvest.title")}</strong>
    <div className="staging-txt-bottom">
        <p>{t("flipInvest.content1")}</p>
        {t("flipInvest.content2")}
    </div>
    <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection2}/>
        </div>
</div>
{/* 전후사진 그리드 */}
<div className="remodeling-before-after"
ref={ref2}
style={{
    
    width: "calc(100% - 300px - 300px)",
    marginLeft: "300px",
    marginRight: "300px",
}}
>
    {/* 첫번째줄 사진 */}
    <div className="remodeling-row">

        {/* 전 */}
    <div className="before-area">
        <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={beforefl1}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.before")}</span>
    </div>
    {/* 후 */}
    <div className="after-area">
        <Card
        sx={AfterCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-after-img'
                src={afterfl1}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.after")}</span>
    </div>
    </div>
    {/* 두번째줄 사진 */}
    <div className="remodeling-row">

{/* 전 */}
<div className="before-area">
<Card
sx={BeforeCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-before-img'
        src={beforefl2}/>
    </CardContent>
</Card>
<span>{t("imgGrid.before")}</span>
</div>
{/* 후 */}
<div className="after-area">
<Card
sx={AfterCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-after-img'
        src={afterfl2}/>
    </CardContent>
</Card>
<span>{t("imgGrid.after")}</span>
</div>
</div>

</div>
{/* 왼쪽 정렬 비포 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={left1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent5")}</span>
        </div>
</div>

{/* 오른쪽 정렬 애프터 1 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            ><p>{t("imgGrid.afterContent6")}</p>
                 </span>
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={right1}/>
            </CardContent>
        </Card>
        
</div>
{/* 오른쪽 정렬 애프터 2 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>
                {t("imgGrid.houseFlipping")}</strong>
                
            </p>
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={right2}/>
            </CardContent>
        </Card>
        
</div>
{/* 비포2-1 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={left2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터 2-1 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.afterFlipping")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent7")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={right21}/>
            </CardContent>
        </Card>
        
</div>
{/* 비포3-1 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={before31}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent6")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터 3-1 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.afterFlipping")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent8")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={after31}/>
            </CardContent>
        </Card>
        
</div>
<div className="remodeling-before-after"
style={{
    
    width: "calc(100% - 300px - 300px)",
    marginLeft: "300px",
    marginRight: "300px",
}}
>
    {/* 첫번째줄 사진 */}
    <div className="remodeling-row">

        {/* 전 */}
    <div className="before-area">
        <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={gridbefore1}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.before")}</span>
    </div>
    {/* 후 */}
    <div className="after-area">
        <Card
        sx={AfterCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-after-img'
                src={gridafter1}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.afterFlipping")}</span>
    </div>
    </div>
    {/* 두번째줄 사진 */}
    <div className="remodeling-row">

{/* 전 */}
<div className="before-area">
<Card
sx={BeforeCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-before-img'
        src={gridbefore2}/>
    </CardContent>
</Card>
<span>{t("imgGrid.before")}</span>
</div>
{/* 후 */}
<div className="after-area">
<Card
sx={AfterCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-after-img'
        src={gridafter2}/>
    </CardContent>
</Card>
<span>{t("imgGrid.afterFlipping")}</span>
</div>
</div></div>
{/* 비포3-1 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={beforelast}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent7")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터 3-1 */}
<div className="left-align-area last-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.afterFlipping")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent9")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={afterlast}/>
            </CardContent>
        </Card>
        
</div>
            </PC>
            <Mobile>
            <div className="remodeling-area-mb">
                {/* 맨위에 이미지랑 글자 */}
                <div className="remodeling-img-mb">
    <img src={Fl}
    style={{
        transform:"translateX(-350px)",
        width:"800px"
    }}
    />

    </div>
<div className="main-banner-txts-mb">
        <div className="banner-txt-top-mb">
     
       <span>
        <strong
        style={{
            fontSize:"36px"
        }}
        >{t("flipInvest.bannerTop1")}</strong>
       </span>
    
       </div>
        </div>
        <div className="banner-txt-bottom-mb"
       style={{
        width:"calc(100% - 100px)",

        display:"flex",
        flexDirection:"column"
       }}
        >
{t("flipInvest.bannerBottom")}


        </div>
        <div className="more"
        style={{
            width:"calc(100% - 80px)",
            display:"flex",
            alignItems:"flex-start"
        }}
        >
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
    
</div>
<div className="staging-txt-mb"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
    }}
    >{t("flipInvest.title")}</strong>
    <div className="staging-txt-bottom-mb">
        <p> {t("flipInvest.content1")}</p>{t("flipInvest.content2")}
    </div>
    <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection2}/>
        </div>
</div>
{/* 전후사진 그리드 */}
<div className="photos-grid-mb"
ref={ref2}
>
         {/* 전 */}
        <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={beforefl1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        <span
           style={{
            fontSize:"30px",
            fontWeight:"bold"
        }}
        >{t("imgGrid.before")}</span>

        </div>
    {/* 후 */}
    <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={afterfl1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        <span
           style={{
            fontSize:"30px",
            fontWeight:"bold"
        }}
        >{t("imgGrid.after")}</span>

        </div>
     {/* 전 */}
     <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={beforefl2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        <span
           style={{
            fontSize:"30px",
            fontWeight:"bold"
        }}
        >{t("imgGrid.before")}</span>

        </div>
 {/* 전 */}
 <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                src={afterfl2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        <span
           style={{
            fontSize:"30px",
            fontWeight:"bold"
        }}
        >{t("imgGrid.after")}</span>

        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={left1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent5")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={right1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.afterFlipping")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent6")}</span>
        </div>
        
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={right2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.houseFlipping")}</strong>
                
            </p>
     
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={left2}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={right21}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.afterFlipping")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent7")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={before31}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent6")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={after31}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.afterFlipping")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent8")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={gridbefore1}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
          
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={gridafter1}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.afterFlipping")}</strong>
                
            </p>
          
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={gridbefore2}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
          
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={gridafter2}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.afterFlipping")}</strong>
                
            </p>
          
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={gridbefore1}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent7")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
            style={{
                transform:"translateX(-100px)"
            }}
            src={gridafter1}/>
            
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.afterFlipping")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent9")}</span>
        </div>
</div>

            </Mobile>
        </div>
    );
}

export default Flip;