import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import  UserMenus  from './UserMenus'; // Assuming UserMenus and AdminMenus are components
import  AdminMenus  from './AdminMenus'; // Assuming UserMenus and AdminMenus are components
import { Button, Typography, useTheme } from '@mui/material';
  import { MdDarkMode, MdLightMode } from "react-icons/md";
import ThemeToggleBtn from './ThemeToggleBtn';
import NoticeBoard from './NoticeBoard';
export default function CustomAppBar({toggleColorMode}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const nowUserId=location.state.id
  const nowUserType = location.state.userType;
  const theme=useTheme()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogOut = () => {
    const res = window.confirm("Do you want to log out?");
    if (res) {
      sessionStorage.setItem('userLoggedIn','false')
      navigate("/fileupload/login");
      handleClose();
    }
  };
  const navigatePwChange = () => {
      navigate("/fileupload/pw-change",{
        state: { id: nowUserId, userType: nowUserType, row: null },
    });
      handleClose();
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
// const navigateCommunity=()=>{


//   navigate("/community",{ state: { id: nowUserId, userType: nowUserType, row: null }},)
// }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant='outlined'
        position="static" sx={{ backgroundColor: "transparent", height: '60px' }}> {/* 변경된 높이 */}
        <Toolbar>
        <Typography
        variant='h6'
          sx={{
            width:"200px",
            display:"flex",
            alignItems:"flex-start",
            justifyContent:"center",
            color:theme.palette.text.primary,
            marginBottom:"5px"

          }}
          >BD file management</Typography> 
          <div
          style={{width:"32px"}}
          />
          {nowUserType === "user" ? <UserMenus /> : <AdminMenus />}
          <div style={{flex:1}}/>
      
     
          <div style={{
            width:"1px"
            ,height:"20px",
            marginLeft:"8px",
            marginRight:"16px",
            backgroundColor:theme.palette.grey[500]
          }}/>
          {/* 변경된 높이에 맞게 스타일 조절 */}
         
          <Tooltip title={ localStorage.getItem('nowUserIdInLocal')}>
            <IconButton onClick={handleMenu} sx={{ p: 0 }}>
              <Avatar sx={{ backgroundColor: nowUserType === "admin" ? "purple" : null, width: '32px', height: '32px' }}> {/* 변경된 크기 */}
                {nowUserType === "user" ? "U" : "A"}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem >ID : {location.state.id}</MenuItem>
              <MenuItem onClick={navigatePwChange}>Change Password</MenuItem>
              <MenuItem onClick={handleLogOut}>LOG OUT</MenuItem>
            </Menu>
          </Tooltip>
          <div style={{
            width:"32px"
          }}></div>
          <ThemeToggleBtn toggleColorMode={toggleColorMode}/>
        </Toolbar>
        
      </AppBar>
      <div
      style={{
        display:"flex",
        width:"100%"
      }}
      >
        {/* Company Announcements 게시판 */}
        <NoticeBoard type={0}/>
        {/* Company Instructions 게시판 */}
        <NoticeBoard type={1}/></div>
     

    </Box>
  );
}
