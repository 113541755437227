import React, { useState } from 'react';
import './PropertyShow.css';
import { Mobile, PC } from './ReactiveLayout';

function PropertyShow() {
    const nowProperty = JSON.parse(localStorage.getItem("nowProperty"));
    console.log("현재 매물>>>", nowProperty);

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? nowProperty.images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === nowProperty.images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <>
            <PC>
                <div
                className='property-container'>
                     <div className='content-container'>
                     <div className='title-col'>
                        <h2>{nowProperty.address}</h2>
                       { nowProperty.images.length > 0&&<h3> {currentIndex+1} / {nowProperty.images.length}</h3>}
                        </div>
                    <div className='property-img-container'>
                       
                    
                        {nowProperty.images && nowProperty.images.length > 0 ? (
                            <div className='slider'>
                                <button className='slider-button' onClick={handlePrev}>‹</button>
                                <img src={nowProperty.images[currentIndex]} alt={`Property Image ${currentIndex + 1}`} />
                                <button className='slider-button' onClick={handleNext}>›</button>
                            </div>
                        ) : (
                            <p>No images available</p>
                        )}
                    </div>
                    <div className='property-details'>
                     
                        {/* 추가 세부 정보 */}
                        <div className="info-table">
                        <table >
        <tbody >
          <tr >
            <th>Bath</th>
            <td>{nowProperty.bath}</td>
            <th>Bed</th>
            <td>{nowProperty.bed}</td>
          </tr>
          <tr>
          <th>Garage</th>
            <td>{nowProperty.garage}</td>
            <th>HOA</th>
            <td>{nowProperty.hoa}</td>
          </tr>
          <tr>
            <th>Level</th>
            <td>{nowProperty.level}</td>
            <th>Lot Size</th>
            <td>{nowProperty.lotSize}</td>
          </tr>
          <tr>
            <th>List Price</th>
            <td className='price-td'>{nowProperty.listPrice}</td>
            <th>sqft</th>
            <td>{nowProperty.sqft}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{nowProperty.contactEmail}</td>
            <th>Phone</th>
            <td>{nowProperty.contactPhone}</td>
          </tr>
          <tr>
            <th>Price</th>
            <td>{nowProperty.listPrice}</td>
            <th>Description</th>
            <td>{nowProperty.description}</td>
          </tr>
        </tbody>
      </table>
                        </div>
                      
                    </div>
                </div>
                </div>
               
            </PC>

            <Mobile>
                <div className='content-container-mobile'>
                <div className='title-col'>
                        <h2>{nowProperty.address}</h2>
                       { nowProperty.images.length > 0&&<h3> {currentIndex+1} / {nowProperty.images.length}</h3>}
                        </div>
                    <div className='property-img-container-mobile'>
       
                        {nowProperty.images && nowProperty.images.length > 0 ? (
                            <div className='slider-mobile'>
                                <button className='slider-button-mobile' onClick={handlePrev}>‹</button>
                                <img src={nowProperty.images[currentIndex]} alt={`Property Image ${currentIndex + 1}`} className='property-image-mobile' />
                                <button className='slider-button-mobile' onClick={handleNext}>›</button>
                            </div>
                        ) : (
                            <p>No images available</p>
                        )}
                    </div>
                    <div className='property-details-mobile'>
                    {/* <h3>{nowProperty.address}</h3> */}
                        <p><strong>Bath:</strong> {nowProperty.bath}</p>
                        <p><strong>Bed:</strong> {nowProperty.bed}</p>
                        <p><strong>Garage:</strong> {nowProperty.garage}</p>
                        <p><strong>HOA:</strong> {nowProperty.hoa}</p>
                        <p><strong>Level:</strong> {nowProperty.level}</p>
                        <p><strong>List Price:</strong> {nowProperty.listPrice}</p>
                        <p><strong>Lot Size:</strong> {nowProperty.lotSize}</p>
                        <p><strong>sqft:</strong> {nowProperty.sqft}</p>
                        <p><strong>Email:</strong> {nowProperty.contactEmail}</p>
                        <p><strong>Phone:</strong> {nowProperty.contactPhone}</p>
                        <p><strong>Price:</strong> ${nowProperty.listPrice}</p>
                        <p><strong>Description:</strong> {nowProperty.description}</p>
                        {/* 추가 세부 정보 */}
                    </div>
                </div>
            </Mobile>
        </>
    );
}

export default PropertyShow;
