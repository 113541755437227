import { ArrowDownward, ArrowDownwardSharp, ArrowDropDown } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
function DirectMenuBtn({title,onClick,id}) {
    const navigate=useNavigate()
    return (
        <Button
        id={id}
        sx={{ fontWeight:"bold",
                fontSize:"18px",
              color:"#444444",
              gap:"5px"
              
        }}
        onClick={()=>{
            navigate(id)
        }}
        >
            {title}
        </Button>
    );
}

export default DirectMenuBtn;