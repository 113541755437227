import { TableCell, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

function TransDocRow({index,tableDatas,setTableDatas}) {
    const onChangeDocName=(e)=>{
        const newVal=e.target.value
        
        const newTableDatas = [...tableDatas];
        newTableDatas[index] = { name: newVal, source: tableDatas[index]["source"] };
    setTableDatas(newTableDatas);
       
    }
    const onChangeDocSrc=(e)=>{
        const newVal=e.target.value
        
        const newTableDatas = [...tableDatas];
        newTableDatas[index] = { name: tableDatas[index]["name"], source: newVal };
    setTableDatas(newTableDatas);
       
    }

    // useEffect(() => { 
    //     const newTableDatas = [...tableDatas];
    //         newTableDatas[index] = { name: txtFieldVals[0], source: txtFieldVals[1] };
    //     setTableDatas(newTableDatas);
    // }, [txtFieldVals]);
    return (
        <TableRow> 
            <TableCell  component="th" style={{ width:"2%" }} scope="row" align='center'>
             {index+1}</TableCell>
        <TableCell style={{ width: 160 }} scope="row" align='center'>
      
      {(tableDatas&&tableDatas[index]!=undefined&&tableDatas[index]["name"]!=undefined)&&<TextField 
      placeholder='Write Document name'
      fullWidth size='small' 
      onChange={onChangeDocName}
      value={tableDatas[index]["name"]}/>}
      
    </TableCell>
    <TableCell style={{ width: 160 }} scope="row" align='center'>
  {  (tableDatas&&tableDatas[index]!=undefined&&tableDatas[index]["source"]!=undefined)&&<TextField
     placeholder='Write Source of document'
    fullWidth size='small' 
    onChange={onChangeDocSrc}
    value={tableDatas[index]["source"]}/>}
    </TableCell>
    </TableRow>
    );
}

export default TransDocRow;