import { Button } from '@mui/material';
import React from 'react';
import { mainHomePgMainColor } from '../../../const/const';
import { useTranslation } from 'react-i18next';

function TranslateToggle(props) {
    const { t, i18n } = useTranslation();

    // 언어를 토글하는 함수
    const toggleLanguage = () => {
      const currentLanguage = i18n.language; // 현재 언어 확인
      const newLanguage = currentLanguage === 'en' ? 'ko' : 'en'; // 현재 언어에 따라 변경
      i18n.changeLanguage(newLanguage); // 언어 변경
    };

    return (
        <Button
        variant='outlined'
        sx={{
            width:"100px",
            fontSize: "14px",
            borderRadius: "50px",
            borderColor: mainHomePgMainColor,
            color: mainHomePgMainColor,
            ":hover": {
                borderColor: mainHomePgMainColor,
                color: mainHomePgMainColor,
            }
        }}
        onClick={toggleLanguage} // 언어를 토글하는 함수 호출
        >
        {t(`language.${i18n.language==="en"?"ko":"en"}`)} {/* 현재 언어에 따라 번역된 텍스트 표시 */}
        </Button>
    );
}

export default TranslateToggle;
