import { Box, Button, IconButton, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mdFontSize } from '../const/const';
import { useRecoilState } from 'recoil';
import { tabNameState } from '../atom/atom';
import AdminAppBarTabView from './AdminAppBarTabView';

function AdminMenus(props) {
    const theme=useTheme()
    const navigate=useNavigate()
    const location = useLocation()
    const pathName=location.pathname.split("/")[1]
    const [tabName,setTabName]=useRecoilState(tabNameState)
    const nowUserId = location.state.id
    const nowUserType = location.state.userType
    useEffect(()=>{
        setTabName(pathName)
    },[])

   
   
    return (
          
 <div
 style={{
    display:"flex",
    width:"fit-content",
    gap:"8px"
 }}
 >
   <AdminAppBarTabView/>
    {/* <Button size='small' 

 id='user-setting' variant={tabName==='user-setting'?'outlined':"text"} onClick={handleChange}>
    
    USER SETTING</Button>
  <Button size='small' 
  
  id='review' variant={tabName==='review'?'outlined':"text"} onClick={handleChange}>REVIEW</Button>
  <Button size='small' 
  
  id='broker-split' variant={tabName==='broker-split'?'outlined':"text"} onClick={handleChange}>BROKER SPLIT</Button>
  <Button size='small' 
 
  id='transaction-doc' variant={tabName==='transaction-doc'?'outlined':"text"} onClick={handleChange}>TRANSACTION DOCUMENT</Button>
  <Button size='small' 
 
  id='email-send' variant={tabName==='email-send'?'outlined':"text"} onClick={handleChange}>EMAIL SEND</Button> */}
   </div>


     
    );
}

export default AdminMenus;