import { Button, TableCell, TableRow, Tooltip } from '@mui/material';
import React from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import { reviewColor, submitColor } from '../const/const';
import { FaArrowCircleUp } from 'react-icons/fa';
import { IoIosImages } from "react-icons/io";
import UserContentDialog from './UserContentDialog';
import UserDocDialog from './UserDocDialog';
function UserAccountRow({row,index,transTypeOptions,length}) {
    const rowKey=row.id
    const location=useLocation()
    const navigate=useNavigate()
    const [hover,setHover]=React.useState(false)
  
    const onMouseEnter=()=>{
   setHover(true)
   
     }
     const onMouseLeave=()=>{
      setHover(false)
    }
    function formatToBdNumber(number) {
      return `BD${String(number).padStart(5, '0')}`;
    }
    return (
        <TableRow
            key={index}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            
            sx={{
              backgroundColor:row["finish"] === true?"rgba(255,223,0, 0.16)":hover?"rgba(164, 204, 252, 0.2)":null,
              cursor:"pointer"
            }}
            onClick={()=>{
              // if (row["finish"] === true)return;
              // finish true상태면 텍스트필드들 막고, edit 안된다는 경고문구 띄우기
            const nowUserId=location.state.id
            const userType=location.state.userType
            localStorage.setItem("rowKey",rowKey)
            localStorage.setItem("isSaved","false")
            sessionStorage.setItem("editBdNum",row.bdNum)
              navigate(`/fileupload/edit/${index+1}`,
              {state:
                {id:nowUserId,
                userType:userType,
                row:row,
                rowKey:rowKey,
                
              }})
              // setTimeout(()=>{
              //   navigate(`/fileupload/edit/${index+1}`,
              //   {state:
              //     {id:nowUserId,
              //     userType:userType,
              //     row:row,
              //     test:row["tablePerTransaction"],
              //     rowKey:rowKey,
                  
              //   }})

              // },10000)
            }}
            >
          
                <TableCell component="th" scope="row">
                {length-index}
              </TableCell>
            
              <TableCell component="th" scope="row">
                {/* {row["bdNum"]} */}
                {formatToBdNumber(length-index)}
              </TableCell>
              <TableCell component="th" scope="row">
                {row["closingDetail"]["closingDate"]}
              </TableCell>
              <TableCell component="th" scope="row">
              {row["newTransactionDetail"]["transAddress"]}
              </TableCell>  <TableCell component="th" scope="row">
              {row["newTransactionDetail"]["transCity"]}
              </TableCell>
             
                <TableCell component="th" scope="row">
                {transTypeOptions[row["newTransactionDetail"]["transType"]]}
              </TableCell>
      
              <TableCell component="th" scope="row">
              <Tooltip
            title={row["formState"] === "save"  ?  "Not yet" : "Submitted"}
          ><div
            style={{ display: "flex", gap: "16px", alignItems: "center" }}

          >
              <FaArrowCircleUp size={20} color={row["formState"] === "save" ? "gray" :submitColor } />
            </div></Tooltip>
              </TableCell>
          
              <TableCell component="th" scope="row">
              <Tooltip
            title={row["finish"] === true ? "Finish" : "Progressing"}
          ><div
            style={{ display: "flex", gap: "16px", alignItems: "center" }}

          >
              <FaCircleCheck size={20} color={row["finish"] === true ? reviewColor : "gray"} />
            </div></Tooltip>
              </TableCell>
              {/* 리뷰 완료했을 때만 버튼 나와서 내용 볼 수 있게 */}
              {/* <TableCell component="th" scope="row">
               {row["finish"] === true && 
            <UserContentDialog row={row}/>
               } 

              </TableCell> */}
              {/* 리뷰 완료했을 때만 버튼 나와서 이미지 볼 수 있게 */}
              {/* <TableCell component="th" scope="row">
             {row["finish"] === true && 
             <UserDocDialog  rowId={rowKey}/>
                 } 

              </TableCell> */}
            </TableRow>
    );
}

export default UserAccountRow;