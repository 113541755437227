import React from 'react';
import TxtfieldRow from '../components/TxtfieldRow';
import "../App.css"
import { Card, CardContent } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sellerAgentNameState, sellerBrokerNameState, sellerEmailState, sellerNameState, sellerPhoneState } from '../atom/atom';

export default function SellerAgentInfo({
  isLease,
  disabled,
  brokerName,sellerAgentName,phoneNum,email,sellerName
,setBrokerName,setEmail,setPhone,setAgentName,setName
}) {

   const onChangeBrokerName=(e)=>{
    setBrokerName(e.target.value)
   }
   const onChangeEmail=(e)=>{
    setEmail(e.target.value)
   }
   const onChangePhone=(e)=>{
    setPhone(e.target.value)
   }
   const onChangeAgentName=(e)=>{
setAgentName(e.target.value)
   }
   const onChangeName=(e)=>{
    setName(e.target.value)
   }
   return (
        <Card sx={{width:"100%"}} variant='outlined'>
        <CardContent>
        <div className='ContentBox'>
            <div>{isLease?"Landlord":"Seller"} Agent Information</div>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Broker Name(Firm name)"} value={brokerName} onChange={onChangeBrokerName}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={`${isLease?"Landlord":"Seller"} Agent Name`} value={sellerAgentName} onChange={onChangeAgentName}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Cell Phone Number"} value={phoneNum} onChange={onChangePhone}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={"Email Address"} value={email} onChange={onChangeEmail}/>
      <TxtfieldRow 
      titleWidth='20vw'
      width={"50vw"} containerWidth='50vw'
      readOnly={disabled}
      title={`${isLease?"Landlord":"Seller"} Name`} value={sellerName} onChange={onChangeName}/>
     
      
        </div>
        </CardContent>
        </Card>
    );
}